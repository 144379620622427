import React from 'react';
import styles from '../../../scss/components/Editor.module.scss';

import { IInputFieldProps } from './IInputFieldProps';

import { 
    Label, 
    TextField 
} from '@fluentui/react';

import { 
    labelStyles,
    textBoxStyles
} from '../../../scss/ControlStyles';

export function InputField(props: IInputFieldProps) {

    const onValueChanged = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
        if(!props.update || !props.onChange) return;

        val = val ? val : '';
        var item = props.item;
        props.update(item, val);
        props.onChange(item);
    }

    return (

        <div className={`${styles.compactRow}`}>
            <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                <div className={styles.labelBox}>
                    <Label styles={labelStyles}>{props.label}:</Label>
                </div>
            </div>
            <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                <TextField
                    styles={textBoxStyles}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={onValueChanged}
                />
            </div>
        </div>
    )
}