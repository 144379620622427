import styles from './scss/App.module.scss'
import { Header } from './components/header/Header';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserLogin } from './pages/user/login/login';

import { Home } from './pages/home/Home';
import { ReportCreate } from './pages/reportCreate/ReportCreate';
import { ReportSearch } from './pages/reportSearch/ReportSearch';
import { ReportDetail } from './pages/reportDetail/ReportDetail';
import { UserRegister } from './pages/user/register/register';
import { UserResetPassword } from './pages/user/resetPassword/resetPassword';
import { Account } from './pages/user/account/account';
import { NotFound } from './pages/notFound/NotFound';

import { Users } from './pages/admin/users/Users';
import { Reports } from './pages/admin/reports/Reports';
import { Accounts } from './pages/admin/accounts/Accounts';
import { Products } from './pages/admin/products/Products';
import { UserReview } from './pages/admin/userReview/UserReview';
import { ProductLines } from './pages/admin/productLines/ProductLines';
import { AccountProducts } from './pages/admin/accountProducts/AccountProducts';
import { UserAccounts } from './pages/admin/userAccounts/UserAccounts';
import { ProblemActivities } from './pages/admin/problemActivities/ProblemActivities';
import { ProblemDescriptions } from './pages/admin/problemDescriptions/ProblemDescriptions';
import { ProblemLocations } from './pages/admin/problemLocations/ProblemLocations';
import { SearchResults } from './pages/searchResults/SearchResults';
import { DataLoad } from './pages/admin/dataLoad/dataLoad';
import { NeedleIssues } from './pages/admin/needleIssues/NeedleIssues';


export function App() {
  return (
    <div>
      <div className={`${styles.appContainer} ${styles.noPrint}`} id='HeaderContainer'>
        <Header />
      </div>

      <div className={`${styles.appContainer}`}>
        <Router>
          <Switch>
            <Route path={`/`} exact={true} component={Home} />
            <Route path={`/report/:lineId`} exact={true} component={ReportCreate} />
            <Route path={`/search`} exact={true} component={ReportSearch} />
            <Route path={`/search/:search`} exact={true} component={SearchResults} />
            <Route path={`/summary/:reportId`} exact={true} component={ReportDetail} />
            <Route path={`/login`} exact={true} component={UserLogin} />
            <Route path={`/register`} exact={true} component={UserRegister} />
            <Route path={`/resetpassword`} exact={true} component={UserResetPassword} />
            <Route path={`/account`} exact={true} component={Account} />
            
            <Route path={`/admin`} exact={true} component={Reports} />
            <Route path={`/admin/accounts`} exact={true} component={Accounts} />
            <Route path={`/admin/accountproducts`} exact={true} component={AccountProducts} />
            <Route path={`/admin/problemactivities`} exact={true} component={ProblemActivities} />
            <Route path={`/admin/needleissues`} exact={true} component={NeedleIssues} />
            <Route path={`/admin/problemdescriptions`} exact={true} component={ProblemDescriptions} />
            <Route path={`/admin/problemlocations`} exact={true} component={ProblemLocations} />
            <Route path={`/admin/productlines`} exact={true} component={ProductLines} />
            <Route path={`/admin/products`} exact={true} component={Products} />
            <Route path={`/admin/reports`} exact={true} component={Reports} />
            <Route path={`/admin/reports/:id`} exact={true} component={Reports} />
            <Route path={`/admin/userreview`} exact={true} component={UserReview} />
            <Route path={`/admin/users`} exact={true} component={Users} />
            <Route path={`/admin/userAccounts`} exact={true} component={UserAccounts} />
            <Route path={`/admin/dataLoad`} exact={true} component={DataLoad} />

            <Route component={NotFound} />
          </Switch>
        </Router>

        
      </div>
    </div>
  );
}
