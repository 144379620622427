import { DatePicker } from "@fluentui/react";
import { DateHelper } from "../../../data/helpers/dateHelper";
import { datePickerStyles } from "../../../scss/ControlStyles";
import { IDateSelectProps } from "./IDateSelectProps";

export function DateSelect(props: IDateSelectProps) {

    return (
        <DatePicker
            styles={datePickerStyles}
            textField={{
                styles: {
                    fieldGroup: {
                        height: '48px'
                    },
                    field: {
                        fontSize: '1rem',
                        fontFamily: 'Verdana, sans-serif'
                    }
                }
            }}
            allowTextInput
            value={props.value}
            formatDate={DateHelper.formatDate}
            isMonthPickerVisible={false}
            onSelectDate={props.onSelectDate} />
    )
}