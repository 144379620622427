import styles from '../../../scss/components/Form.module.scss';
import { useFilePicker } from 'use-file-picker';
import { FileContent } from "use-file-picker/dist/interfaces";
import { DefaultButton, Label } from "@fluentui/react";
import { fullWidthButtonStyles, labelStyles } from "../../../scss/ControlStyles";

export interface IFileUploadProps {
    label: string;
    getFiles: (func: () => FileContent[]) => void;
}

export function FileUpload(props: IFileUploadProps) {

    const [openFileSelector, { loading, filesContent, clear }] = useFilePicker({
        readAs: 'ArrayBuffer'
    });

    var browseClick = () => {
        openFileSelector();
    };

    props.getFiles((): FileContent[] => {
        return filesContent;
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    var renderFiles = () => {
        return filesContent.map((file) => {
            return (
                <div className={styles.labelBox}>
                    <Label styles={labelStyles}>{file.name}</Label>
                </div>
            )
        });
    }

    var renderNoFiles = () => {
        return (
            <div className={styles.labelBox}>
                <Label styles={labelStyles}>No Files Chosen</Label>
            </div>
        )
    }

    return (
        <div>
            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg6} ${styles.lgOffset1}`}>
                    <div>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles} htmlFor='custRef'>{props.label}</Label>
                        </div>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`}>
                    <div>
                        {filesContent && filesContent.length ? renderFiles() : renderNoFiles()}
                    </div>
                </div>
            </div>
            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm10} ${styles.lg4}  ${styles.smOffset1} ${styles.lgOffset7}`}>
                    <div className={styles.labelBox}>
                        <DefaultButton
                            text='Browse'
                            onClick={browseClick}
                            styles={fullWidthButtonStyles} />
                    </div>
                </div>
            </div>
        </div>

    );
}
