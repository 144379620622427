import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditFilter } from "./EditFilter";
import { DateSelect } from "../../../form/dateSelect/DateSelect";
import { Label } from "@fluentui/react";
import { labelStyles } from "../../../../scss/ControlStyles";

export class EditProduct extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditFilter {...this.props}>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Product Details</h3>
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Incident Date:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <DateSelect
                            value={this.props.item.incidentDate}
                            onSelectDate={this.onIncidentDateChanged.bind(this)}
                        />
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Lot Number'
                    value={this.props.item.lotNumber}
                    update={(report, val) => report.lotNumber = val} />

                <InputField
                    {...this.props}
                    label='Number of Incidents'
                    value={this.props.item.numIncidents?.toString()}
                    update={(report, val) => report.numIncidents = parseInt(val)} />

                <InputField
                    {...this.props}
                    label='Serial Number'
                    value={this.props.item.serialNumber}
                    update={(report, val) => report.serialNumber = val} />

                <InputField
                    {...this.props}
                    label='Software Version'
                    value={this.props.item.softwareVersion}
                    update={(report, val) => report.softwareVersion = val} />

                <InputField
                    {...this.props}
                    label='Product Description'
                    value={this.props.item.productDescription}
                    update={(report, val) => report.productDescription = val} />

                <InputField
                    {...this.props}
                    label='WB Processed'
                    value={this.props.item.wbProcessed}
                    update={(report, val) => report.wbProcessed = val} />

                <InputField
                    {...this.props}
                    label='Volume Of Plasma Collected'
                    value={this.props.item.volumeOfPlasmaCollected}
                    update={(report, val) => report.volumeOfPlasmaCollected = val} />

                <InputField
                    {...this.props}
                    label='Donor Bleed Number'
                    value={this.props.item.donorBleedNumber}
                    update={(report, val) => report.donorBleedNumber = val} />

                <InputField
                    {...this.props}
                    label='PAS Lot Number'
                    value={this.props.item.pasLotNumber}
                    update={(report, val) => report.pasLotNumber = val} />

                <InputField
                    {...this.props}
                    label='Batch Tag Info Time'
                    value={this.props.item.batchTagInfoTime}
                    update={(report, val) => report.batchTagInfoTime = val} />

                <InputField
                    {...this.props}
                    label='Batch Tag Number'
                    value={this.props.item.batchTagNumber}
                    update={(report, val) => report.batchTagNumber = val} />

                <InputField
                    {...this.props}
                    label='line Number'
                    value={this.props.item.lineNumber}
                    update={(report, val) => report.lineNumber = val} />

                <InputField
                    {...this.props}
                    label='Video Jet Number'
                    value={this.props.item.videoJetNumber}
                    update={(report, val) => report.videoJetNumber = val} />

                <InputField
                    {...this.props}
                    label='Filter Number'
                    value={this.props.item.filterNumber}
                    update={(report, val) => report.filterNumber = val} />

            </EditFilter>
        );
    }
    // private productSelected(option: IDropdownOption) {
    //     var report = this.props.item;
    //     report.productId = option.data.id;
    //     this.props.onChange(report);
    // }

    onIncidentDateChanged(date: Date | null | undefined) {
        if (date) {
            var report = this.props.item;
            report.incidentDate = date;
            this.props.onChange(report);
        }
    }
}
