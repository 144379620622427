export class DateHelper {
  public static formatDate(date?: any): string {
    if (!date) {
      return "";
    }

    var argType = typeof date;

    if (argType === "string" || argType === "number") {
      date = new Date(date);
    }

    //return date.toString();
    return date.toLocaleDateString("en-US");
  }

  public static formatDateTime(date?: any): string {
    if (!date) {
      return "";
    }

    var argType = typeof date;

    if (argType === "string" || argType === "number") {
      date = new Date(date);
    }

    //return date.toString();
    return date.toLocaleString("en-US");
  }
}
