import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class AccountFormValidator {

    private report: IReport;
    private errors: string[];

    constructor(report: IReport, errors: string[]) {
        this.report = report;
        this.errors = errors;
    }

    validateAccount(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (!this.report.accountId || this.report.accountId === 0) { 
            this.errors.push('Account');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
