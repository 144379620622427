import React from "react";
import styles from '../../../scss/components/Form.module.scss';
import { IFormProps } from "../IFormProps";
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { TrippleCheckResponse, TrippleCheck } from "../../inputs/TrippleCheck";
import { TextInput } from "../../inputs/TextInput";
import { GeneralFormValidator } from "../../../data/validation/generalFormValidator";
import { IValidateResult } from "../../../data/validation/validateResult";
import { FileContent } from "use-file-picker/dist/interfaces";
import { AccountForm } from "../accountForm/AccountForm";

interface IState {
    adverseEvent: boolean;
    adverseEventDescription: string;
    completed: TrippleCheckResponse;
    container: string;
    drugAdministered: string;
    drugUsed: string;
    duringDonation: boolean;
    isCytotoxic: TrippleCheckResponse;
    lost: TrippleCheckResponse;
    manufacturer: string;
    softGoodsIssue: TrippleCheckResponse;
    stopped: TrippleCheckResponse;
    clinicalOrPatientMaterial: TrippleCheckResponse;

    adverseEventDescriptionError: boolean;
    completedError: boolean;
    stoppedError: boolean;
    drugUsedError: boolean;
    drugAdministeredError: boolean;
    manufacturerError: boolean;
    containerError: boolean;
    isCytotoxicError: boolean;
    softGoodsIssueError: boolean;
    lostError: boolean;
    clinicalOrPatientMaterialError: boolean;
}

export class GeneralForm extends React.Component<IFormProps, IState> {

    constructor(props: IFormProps) {
        super(props);

        this.state = {
            adverseEvent: false,
            adverseEventDescription: '',
            container: '',
            completed: new TrippleCheckResponse('Yes', 'No', 'N/A'),
            drugAdministered: '',
            drugUsed: '',
            duringDonation: false,
            isCytotoxic: new TrippleCheckResponse('Yes', 'No', 'N/A'),
            lost: new TrippleCheckResponse('Yes', 'No', 'N/A'),
            manufacturer: '',
            softGoodsIssue: new TrippleCheckResponse('Yes', 'No', 'N/A'),
            stopped: new TrippleCheckResponse('Yes', 'No', 'N/A'),
            clinicalOrPatientMaterial: new TrippleCheckResponse('Yes', 'No', 'N/A'),

            adverseEventDescriptionError: false,
            drugUsedError: false,
            drugAdministeredError: false,
            manufacturerError: false,
            containerError: false,
            completedError: false,
            stoppedError: false,
            isCytotoxicError: false,
            softGoodsIssueError: false,
            lostError: false,
            clinicalOrPatientMaterialError: false,
        }
    }

    render() {
        return (
            <AccountForm
                line={this.props.line}
                createReport={this.createReport.bind(this)} >

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                <h3>General Incident Information:</h3>
                            </div>
                        </div>

                        <CenterCheck
                            showCheckIfYes
                            value={this.state.duringDonation}
                            required={this.props.line ? this.props.line.duringDonationRequired : false}
                            show={this.props.line ? this.props.line.showDuringDonation : false}
                            defaultLabel={'Did the issue occur during a donation?'}
                            alternativeLabel={this.props.line ? this.props.line.duringDonationAltText : ''}
                            getStateUpdate={(val: boolean) => { return { duringDonation: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <CenterCheck
                            showCheckIfYes
                            value={this.state.adverseEvent}
                            show={this.props.line ? this.props.line.showAdverseEvent : false}
                            defaultLabel={'Was there an adverse event or injury?'}
                            alternativeLabel={this.props.line ? this.props.line.adverseEventAltText : ''}
                            getStateUpdate={(val: boolean) => { return { adverseEvent: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            required
                            multiline
                            value={this.state.adverseEventDescription}
                            error={this.state.adverseEventDescriptionError}
                            show={this.state.adverseEvent}
                            defaultLabel={'Please describe the event'}
                            getStateUpdate={(val: string) => { return { adverseEventDescription: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            showNotApplicable
                            value={this.state.drugAdministered}
                            error={this.state.drugAdministeredError}
                            show={this.props.line ? this.props.line.showDrugAdministered : false}
                            required={this.props.line ? this.props.line.drugAdministeredRequired : false}
                            defaultLabel={'If applicable, list name of any drug administered'}
                            alternativeLabel={this.props.line ? this.props.line.drugAdministeredAltText : ''}
                            getStateUpdate={(val: string) => { return { drugAdministered: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.completed}
                            error={this.state.completedError}
                            required={this.props.line ? this.props.line.completedRequired : false}
                            show={this.props.line ? this.props.line.showCompleted : false}
                            defaultLabel={'Was the procedure successfully completed?'}
                            alternativeLabel={this.props.line ? this.props.line.completedAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { completed: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.stopped}
                            error={this.state.stoppedError}
                            show={this.props.line ? this.props.line.showStopped : false}
                            required={this.props.line ? this.props.line.stoppedRequired : false}
                            defaultLabel={'Was the infusion stopped before completion?'}
                            alternativeLabel={this.props.line ? this.props.line.stoppedAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { stopped: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            showNotApplicable
                            value={this.state.drugUsed}
                            error={this.state.drugUsedError}
                            show={this.props.line ? this.props.line.showDrugUsed : false}
                            required={this.props.line ? this.props.line.drugUsedRequired : false}
                            defaultLabel={'What drug was used for the infusion?'}
                            alternativeLabel={this.props.line ? this.props.line.drugUsedAltText : ''}
                            getStateUpdate={(val: string) => { return { drugUsed: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.isCytotoxic}
                            error={this.state.isCytotoxicError}
                            show={this.props.line ? this.props.line.showIsCytotoxic : false}
                            required={this.props.line ? this.props.line.isCytotoxicRequired : false}
                            defaultLabel={'Is the drug used cytotoxic?'}
                            alternativeLabel={this.props.line ? this.props.line.isCytotoxicAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { isCytotoxic: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.softGoodsIssue}
                            error={this.state.softGoodsIssueError}
                            show={this.props.line ? this.props.line.showSoftGoods : false}
                            required={this.props.line ? this.props.line.softGoodsRequired : false}
                            defaultLabel={'If no, was the procedure stopped due to a soft goods incident?'}
                            alternativeLabel={this.props.line ? this.props.line.softGoodsAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { softGoodsIssue: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.lost}
                            error={this.state.lostError}
                            show={this.props.line ? this.props.line.showLost : false}
                            required={this.props.line ? this.props.line.lostRequired : false}
                            defaultLabel={'Was the product lost?'}
                            alternativeLabel={this.props.line ? this.props.line.lostAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { lost: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            showNotApplicable
                            value={this.state.manufacturer}
                            error={this.state.manufacturerError}
                            show={this.props.line ? this.props.line.showManufacturer : false}
                            required={this.props.line ? this.props.line.manufacturerRequired : false}
                            defaultLabel={'Manufacturer'}
                            alternativeLabel={this.props.line ? this.props.line.manufacturerAltText : ''}
                            getStateUpdate={(val: string) => { return { manufacturer: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            showNotApplicable
                            value={this.state.container}
                            error={this.state.containerError}
                            show={this.props.line ? this.props.line.showContainer : false}
                            required={this.props.line ? this.props.line.containerRequired : false}
                            defaultLabel={'Container?'}
                            alternativeLabel={this.props.line ? this.props.line.containerAltText : ''}
                            getStateUpdate={(val: string) => { return { container: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TrippleCheck
                            value={this.state.clinicalOrPatientMaterial}
                            error={this.state.clinicalOrPatientMaterialError}
                            show={this.props.line ? this.props.line.showClinicalOrPatientMaterial : false}
                            required={this.props.line ? this.props.line.clinicalOrPatientMaterialRequired : false}
                            defaultLabel={'Did the procedure involve clinical or patient material?'}
                            alternativeLabel={this.props.line ? this.props.line.clinicalOrPatientMaterialAltText : ''}
                            getStateUpdate={(val: TrippleCheckResponse) => { return { clinicalOrPatientMaterial: val } }}
                            updateValue={this.updateState.bind(this)}
                        />
                    </div>
                </div>
            </AccountForm>
        )
    }

    updateState(update: any) {
        this.setState(update);
    }

    private createReport(report: IReport, isValid: boolean, errors: string[], files?: FileContent[]) {

        report.drugUsed = this.state.drugUsed;
        report.container = this.state.container;
        report.adverseEvent = this.state.adverseEvent;
        report.manufacturer = this.state.manufacturer;
        report.duringDonation = this.state.duringDonation;
        report.drugAdministered = this.state.drugAdministered; 

        if (this.state.adverseEvent) {
            report.adverseEventDescription = this.state.adverseEventDescription;
        }

        report.lost = this.getTrippleCheckResponseValue(this.state.lost);
        report.stopped = this.getTrippleCheckResponseValue(this.state.stopped);
        report.completed = this.getTrippleCheckResponseValue(this.state.completed);
        report.isCytotoxic = this.getTrippleCheckResponseValue(this.state.isCytotoxic);
        report.softGoodsIssue = this.getTrippleCheckResponseValue(this.state.softGoodsIssue);
        report.clinicalOrPatientMaterial = this.getTrippleCheckResponseValue(this.state.clinicalOrPatientMaterial);

        isValid = this.validate(report, errors) && isValid;
        this.props.createReport(report, isValid, errors, files);
    }

    getTrippleCheckResponseValue(trippleCheck: TrippleCheckResponse) {
        var val = trippleCheck.values[trippleCheck.selectedValue];
        return val;
    }

    validate(report: IReport, errors: string[]): boolean {
        var validator = new GeneralFormValidator(report, errors, this.props.line);

        var validateAdverseEventDescriptionResult = validator.validateAdverseEventDescription((err) => {return {adverseEventDescriptionError: err}});
        var validateDrugUsedResult = validator.validateDrugUsed((err) => {return {drugUsedError: err}});
        var validateDrugAdministeredResult = validator.validateDrugAdministered((err) => {return {drugAdministeredError: err}});
        var validateManufacturerResult = validator.validateManufacturer((err) => {return {manufacturerError: err}});
        var validateContainerResult = validator.validateContainer((err) => {return {containerError: err}});
        var validateCompletedResult = validator.validateCompleted((err) => {return {completedError: err}});
        var validateStoppedResult = validator.validateStopped((err) => {return {stoppedError: err}});
        var validateIsCytotoxicResult = validator.validateCytotoxic((err) => {return {isCytotoxicError: err}});
        var validateSoftGoodsResult = validator.validateSoftGoodsIssue((err) => {return {softGoodsIssueError: err}});
        var validateLostResult = validator.validateLost((err) => {return {lostError: err}});
        var validateClinicalOrPatientResult = validator.validateClinicalOrPatientMaterial((err) => {return {clinicalOrPatientMaterialError: err}});

        var isValid = this.setValidation(validateAdverseEventDescriptionResult);
        isValid = this.setValidation(validateDrugUsedResult) && isValid;
        isValid = this.setValidation(validateDrugAdministeredResult) && isValid;
        isValid = this.setValidation(validateManufacturerResult) && isValid;
        isValid = this.setValidation(validateContainerResult) && isValid;
        isValid = this.setValidation(validateCompletedResult) && isValid;
        isValid = this.setValidation(validateStoppedResult) && isValid;
        isValid = this.setValidation(validateIsCytotoxicResult) && isValid;
        isValid = this.setValidation(validateSoftGoodsResult) && isValid;
        isValid = this.setValidation(validateLostResult) && isValid;
        isValid = this.setValidation(validateClinicalOrPatientResult) && isValid;

        return isValid;
    }

    setValidation(validateResult: IValidateResult) {
        this.setState(validateResult.state);
        return validateResult.isValid;
    }
}