import styles from '../../scss/components/Copyright.module.scss';

import { DefaultButton } from "@fluentui/react";
import { adminButtonStyles } from "../../scss/ControlStyles";
import { IExporterProps } from "./IExporterProps";

export function Exporter(props: IExporterProps) {

    var onExport = () => {
        var apiUri = process.env.REACT_APP_API_URI || 'http://localhost:8080';
        var scvUri = `${apiUri}/admin/export/${props.type}`;
        window.open(scvUri);
    }

    return (
        <div className={`${styles.paddedRow}`}>
            <div className={`${styles.col} ${styles.sm4} ${styles.lg2} ${styles.smOffset4} ${styles.lgOffset5}`}>
                <DefaultButton
                    text='Export'
                    styles={adminButtonStyles}
                    onClick={onExport} />
            </div>
        </div>
    )
}