import React from 'react';
import styles from '../../../scss/components/AdminNav.module.scss';

import { Link } from '@fluentui/react';

export class AdminNav extends React.Component {
    render() {
        return (
            <div className={`${styles.Row}`}>
                <div className={`${styles.adminNav} ${styles.col} ${styles.sm12} ${styles.xl2}`}>
                    <div className={`${styles.Row}`}>
                        <h3>
                            Site Admin
                        </h3>

                        <div>
                            <Link href={"/admin/reports"} key={1}>
                                Reports
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/users"} key={2}>
                                Users
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/accounts"} key={3}>
                                Accounts
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/productlines"} key={4}>
                                Product Lines
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/products"} key={5}>
                                Products
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/problemactivities"} key={6}>
                                Problem Detected
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/problemdescriptions"} key={7}>
                                Problem Descriptions
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/problemlocations"} key={8}>
                                Problem Locations
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/needleissues"} key={13}>
                                Needle Issues
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/accountproducts"} key={9}>
                                Account Products
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/useraccounts"} key={10}>
                                User Accounts
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/userreview"} key={11}>
                                User Review
                            </Link>
                        </div>
                        <div>
                            <Link href={"/admin/dataload"} key={12}>
                                Data Loads
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.xl10}`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}