import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IProduct } from "./models/Product";

export class ProductData {

    public static async create(item: IProduct): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/products', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IProduct[]>> {
        var result = await DataLayer.get<IProduct[]>('/admin/products');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };

        var result = await DataLayer.post('/admin/products/filter', options);
        return result;
    }

    public static async update(item: IProduct): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/products', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/products/${id}`);
        return result;
    }
}
