import React from 'react';

import { AuthData } from '../../data/AuthData';
import { IUser, User } from '../../data/models/User';
import { AuthenticationStatusEnum } from '../../data/enums/AuthenticationStatusEnum';

import { 
  AuthContext, 
  AuthContextState 
} from './AuthContext';

interface AuthState {
  user: IUser;
  onAuthenticated: () => void;
  authenticationStatus: AuthenticationStatusEnum;
}

export class AuthProvider extends React.Component<{}, AuthState> {

  constructor(props: {}) {
    super(props);

    this.state = {
      user: new User(),
      onAuthenticated: () => { },
      authenticationStatus: AuthenticationStatusEnum.Unknown,
    }
  }

  componentDidMount() {
    this.authenticate();
  }

  private async onAuthenticated(func: () => void): Promise<void> {
    this.setState({onAuthenticated: func});
  }

  private async authenticate(): Promise<void> {

    var meResponse = await AuthData.me();
    var status = AuthenticationStatusEnum.LoggedOut;
    var user: IUser = new User();

    if (meResponse && meResponse.status === 200) {
      status = AuthenticationStatusEnum.LoggedIn;
      user = meResponse.data;
    }

    this.setState({
      authenticationStatus: status,
      user: user,
    });

    this.state.onAuthenticated();
  }

  render() {
    var contextState = {
      user: this.state.user,
      authenticate: this.authenticate.bind(this),
      onAuthenticated: this.onAuthenticated.bind(this),
      authenticationStatus: this.state.authenticationStatus,
    } as AuthContextState;

    return (
      <AuthContext.Provider value={contextState}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
