import { AxiosResponse } from "axios";
import { DataLayer } from "./DataLayer";
import { IUser } from "./models/User";

export class AuthData {
    public static async me(): Promise<AxiosResponse<IUser>> {
        try {
            var result = await DataLayer.get<IUser>('/auth/me');
            return result;
        }
        catch (err) {
            throw err;
        }
    }

    public static async login(username: string, password: string): Promise<AxiosResponse<void>> {
        var data = {
            username: username,
            password: password,
        };

        var result = await DataLayer.post('/auth/login', data);
        return result;
    }

    public static async logout(): Promise<AxiosResponse<void>> {
        var result = DataLayer.post('/auth/logout');
        return result;
    }

    public static async changePassword(password: string, newPassword: string): Promise<AxiosResponse<void>> {
        var data = {
            password: password,
            newPassword: newPassword,
        };

        var result = await DataLayer.post('/auth/changepassword', data);
        return result;
    }
}
