import { AxiosResponse } from "axios";
import { FileContent } from "use-file-picker/dist/interfaces";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";

export class FileData {

    public static async upload(id: number, files: FileContent[]): Promise<void> {
        for (var file of files) {
            var data = new FormData();
            var buffer: ArrayBuffer = file.content as any
            var blob = new Blob([new Uint8Array(buffer)]);

            data.append("image", blob, file.name);

            await DataLayer.post(`/uploads/upload/${id}`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                }
            });
        }
    }

    public static async dataLoad(files: FileContent[]): Promise<void> {
        for (var file of files) {
            var data = new FormData();
            var buffer: ArrayBuffer = file.content as any
            var blob = new Blob([new Uint8Array(buffer)]);

            data.append("image", blob, file.name);

            await DataLayer.post(`/uploads/dataload`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                }
            });
        }
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post('/uploads/dataload/filter', options);
        return result;
    }

    public static async list(reportId: number): Promise<AxiosResponse<string[]>> {
        var result = await DataLayer.get<string[]>(`/uploads/list/${reportId}`);
        return result;
    }
}
