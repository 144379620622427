import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class ProductDetailsFormValidator {

    private report: IReport;
    private errors: string[];
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine) {
        this.line = line;
        this.errors = errors;
        this.report = report;
    }

    validateIncidentDate(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (!this.report.incidentDate) {
            this.errors.push('Please enter an incident date');
            result = false;
        }

        else if (this.report.incidentDate > new Date()) {
            this.errors.push('Incident date must be in the past');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateProduct(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (!this.report.productId ||
            this.report.productId === 0) {

            this.errors.push(this.line?.productCodeAltText ? this.line?.productCodeAltText : 'Product Code');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateLotNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.report.lotNumber.length === 0) {

            this.errors.push('Lot Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateConfirmLotNumber(state: (err: boolean) => any, value: string): IValidateResult {
        var result: boolean = true;

        if (this.report.lotNumber.toLowerCase() !== value.toLowerCase()) {

            this.errors.push('Re-enter Lot Number - value must match Lot Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateRbcLabelId(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showRbcLabelId &&
            this.line?.rbcLabelIdRequired &&
            this.report.rbcLabelId.length === 0) {

            this.errors.push(this.line?.rbcLabelIdAltText ? this.line?.rbcLabelIdAltText : 'Red Cell Container Label Identifier (Lower right corner – A or B)');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateNumberOfIncidents(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.report.numIncidents < 1) {

            this.errors.push('Number of Incidents');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateSerialNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showSerialNumber &&
            this.line?.serialNumberRequired &&
            this.report.serialNumber.length === 0) {

            this.errors.push(this.line?.serialNumberAltText ? this.line?.serialNumberAltText : 'Instrument Serial Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateSoftwareVersion(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showSoftwareVersion &&
            this.line?.softwareVersionRequired &&
            this.report.softwareVersion.length === 0) {

            this.errors.push(this.line?.softwareVersionAltText ? this.line?.softwareVersionAltText : 'Software Version');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateProductDescription(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showProductDescription &&
            this.line?.productDescriptionRequired &&
            this.report.productDescription.length === 0) {

            this.errors.push(this.line?.productDescriptionAltText ? this.line?.productDescriptionAltText : 'Product Collected (ml)');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateWbProcessed(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showWbProcessed &&
            this.line?.wbProcessedRequired &&
            this.report.wbProcessed.length === 0) {

            this.errors.push(this.line?.wbProcessedAltText ? this.line?.wbProcessedAltText : 'WB Processed (ml)');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateVolPlasma(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showVolPlasma &&
            this.line?.volPlasmaRequired &&
            this.report.volumeOfPlasmaCollected.length === 0) {

            this.errors.push(this.line?.volPlasmaAltText ? this.line?.volPlasmaAltText : 'Volume of Plasma Collected (ml)');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateDonorBleedNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showDonorBleedNumber &&
            this.line?.donorBleedNumberRequired &&
            this.report.donorBleedNumber.length === 0) {

            this.errors.push(this.line?.donorBleedNumberAltText ? this.line?.donorBleedNumberAltText : 'Donor Bleed Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validatePasLotNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showPasLotNumber &&
            this.line?.pasLotNumberRequired &&
            this.report.pasLotNumber.length === 0) {

            this.errors.push(this.line?.pasLotNumberAltText ? this.line?.pasLotNumberAltText : 'PAS Lot Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateBatchTagInfoTime(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showBatchTagTime &&
            this.line?.batchTagTimeRequired &&
            this.report.batchTagInfoTime.length === 0) {

            this.errors.push(this.line?.batchTagTimeAltText ? this.line?.batchTagTimeAltText + '(hh:mm:ss)' : 'Batch Tag Time (hh:mm:ss)');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateBatchTagNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showBatchTagNumber &&
            this.line?.batchTagNumberRequired &&
            this.report.batchTagNumber.length === 0) {

            this.errors.push(this.line?.batchTagNumberAltText ? this.line?.batchTagNumberAltText : 'Batch Tag Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateLineNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showLineNumber &&
            this.line?.lineNumberRequired &&
            this.report.lineNumber.length === 0) {

            this.errors.push(this.line?.lineNumberAltText ? this.line?.lineNumberAltText : 'Line Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateVideoJetNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showVideoJetNumber &&
            this.line?.videoJetNumberRequired &&
            this.report.videoJetNumber.length === 0) {

            this.errors.push(this.line?.videoJetNumberAltText ? this.line?.videoJetNumberAltText : 'Video Jet Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateFilterNumber(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterNumber &&
            this.line?.filterNumberRequired &&
            this.report.filterNumber.length === 0) {

            this.errors.push(this.line?.filterNumberAltText ? this.line?.filterNumberAltText : 'Filter Number');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
