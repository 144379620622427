import React from "react";
import styles from '../../../scss/Table.module.scss';

import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { AxiosResponse } from "axios";
import { Icon, IDropdownOption } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { LineData } from "../../../data/LineData";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { IProductLine } from "../../../data/models/ProductLine";
import { IAdminPageState } from "../IAdminPageState";
import { IProblemLocation, ProblemLocation } from "../../../data/models/ProblemLocation";
import { ProblemLocationData } from "../../../data/ProblemLocationData";
import { ProblemLocationRow } from "../../../components/admin/rows/problemLocationRow/ProblemLocationRow";
import { EditProblemLocation } from "../../../components/admin/editors/editProblemLocation/EditProblemLocation";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Exporter } from "../../../components/exporter/Exporter";

interface IState extends IAdminPageState<IProblemLocation> {
    lines: IDropdownOption[];
}

export class ProblemLocations extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            lines: [],
            editId: 0,
            showEdit: false,
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            columns: [
                { title: 'Product Line', column: 'ProductLine.name' },
                { title: 'Code', column: 'code' },
                { title: 'Description', column: 'description' },
                { title: 'X (%)', column: 'xOrigin' },
                { title: 'Y (%)', column: 'yOrigin' },
                { title: 'Width (%)', column: 'xLength' },
                { title: 'Height (%)', column: 'yLength' },
                { title: 'Enabled', column: 'enabled' },
            ],
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm10}`}>
                                <h1>
                                    Problem Locations
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.right}`}>
                                <span className={`${styles.link} ${styles.createNew}`} onClick={this.createItem.bind(this)}>
                                    <Icon iconName="CircleAddition" />
                                </span>
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderItems(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <Exporter type='locations' />
                    </div>

                    <EditProblemLocation
                        id={this.state.editId}
                        items={this.state.items}
                        lines={this.state.lines}
                        show={this.state.showEdit}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadItems.bind(this, this.state.pageInfo)} />

                </AdminNav>
            </Authenticator>
        );
    }

    loadData() {
        this.loadLines().then(() => {
            this.loadItems(this.state.pageInfo);
        });
    }

    async loadLines(): Promise<void> {
        var response = await LineData.retrieve();

        if (response.status === 200) {
            this.setState({
                lines: this.createLineOptions(response.data),
            });
        }
        else {
            console.log('error getting product lines');
            console.log(response.data);
        }

    }

    createLineOptions(lines: IProductLine[]): IDropdownOption[] {
        return lines.map((line) => {
            var id: number = line.id ? line.id : 0;

            return {
                key: id,
                index: id,
                data: line,
                text: line.name,
            };
        });
    }

    loadItems(pageInfo: IPageInfo): void {
        ProblemLocationData.retrievePage(pageInfo, this.state.columns)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });
                }
                else {
                    console.log('error getting products');
                    console.log(response.data);
                }
            });
    }

    renderItems(items: IProblemLocation[]) {
        return items.map((item, i) => {
            return (
                <ProblemLocationRow
                    key={i}
                    index={i}
                    item={item}
                    lines={this.state.lines}
                    onEdit={this.openEdit.bind(this)} />
            );
        })
    }

    createItem() {
        var newItem = new ProblemLocation();
        this.openEdit(newItem);
    }

    openEdit(item: IProblemLocation) {
        this.setState({
            editId: item.id ? item.id : 0,
            showEdit: true,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadItems(info);
    }
}