import styles from '../../../../scss/Table.module.scss';

import { IRowProps } from '../IRowProps';
import { Icon } from '@fluentui/react/lib/Icon';
import { IUserAccount } from '../../../../data/models/UserAccount';

export function UserAccountRow(props: IRowProps<IUserAccount>) {

    var deleteClicked = () => {
        props.onEdit(props.item);
    }

    return (
        <div className={`${styles.tableRow}`} key={props.index}>
            <div className={`${styles.tableCell}`}>
                {props.item.user?.username}
            </div>
            <div className={`${styles.tableCell}`}>
                {`${props.item.user?.firstname} ${props.item.user?.lastname}`}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.account?.account}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.account?.name}
            </div>
            <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                <span className={`${styles.link}`} onClick={deleteClicked}>
                    <Icon iconName="Delete" className={styles.red} />
                </span>
            </div>
        </div>
    );
}
