import React from "react";
import styles from "../../../../scss/components/Editor.module.scss";
import { adminTextBoxStyles } from "../../../../scss/ControlStyles";
import { IEditorProps } from "./IEditorProps";
import { TextField } from "@fluentui/react";

export class EditReturnDetails extends React.Component<IEditorProps, {}> {
  containerAltTextChanged: any;
  render() {
    return (
      <>
        {this.props.children}

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm11} ${styles.smOffset1} ${styles.lg9} ${styles.lgOffset3}`}
          >
            <div className={styles.labelBox}>
              <h3>Optional Product Return Details Fields</h3>
            </div>
          </div>
        </div>

        <div className={`${styles.compactRow}`}>
          <div
            className={`${styles.col} ${styles.sm2} ${styles.lg1} ${styles.lgOffset2}`}
          >
            <div className={styles.labelBox}>Notes</div>
          </div>
          <div className={`${styles.col} ${styles.sm10} ${styles.lg9}`}>
            <TextField
              styles={adminTextBoxStyles}
              multiline
              value={this.props.item.returnDetailsNotes}
              onChange={this.returnDetailsNotesChanged.bind(this)}
            />
          </div>
        </div>
      </>
    );
  }

  private returnDetailsNotesChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.props.item;
    item.returnDetailsNotes = val ? val : "";
    this.props.onChange(item);
  }
}
