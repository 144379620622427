import React from "react";
import styles from '../../../scss/components/Form.module.scss';
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { TextInput } from "../../inputs/TextInput";
import { CorrespondenceFormValidator } from "../../../data/validation/correspondenceFormValidator";
import { FileContent } from "use-file-picker/dist/interfaces";
import { ProductDetailsForm } from "../productDetailsForm/ProductDetailsForm";
import { IProductDetailFormProps } from "../productDetailsForm/ProductDetailsFormProps";

interface IState {
    noResponse: boolean;
    recipientEmail: string;
    recipientEmailError: boolean;
}

export class CorrespondenceForm extends React.Component<IProductDetailFormProps, IState> {

    constructor(props: IProductDetailFormProps) {
        super(props);

        this.state = {
            noResponse: false,
            recipientEmail: '',
            recipientEmailError: false,
        }
    }

    render() {
        if (!this.props.line?.showCorrespondence || this.props.account?.data?.hideCorrespondence) {
            return (
                <ProductDetailsForm
                    line={this.props.line}
                    account={this.props.account}
                    products={this.props.products}
                    createReport={this.createReport.bind(this)} >

                    {this.props.children}
                </ProductDetailsForm>
            );
        }

        return (
            <ProductDetailsForm
                line={this.props.line}
                account={this.props.account}
                products={this.props.products}
                createReport={this.createReport.bind(this)} >

                {this.props.children}

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                <h3>Correspondence Information:</h3>
                            </div>
                        </div>

                        <CenterCheck
                            value={this.state.noResponse}
                            show={true}
                            defaultLabel={'Check box if you do NOT require a response letter'}
                            getStateUpdate={(val: boolean) => { return { noResponse: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <TextInput
                            required
                            showNotApplicable
                            value={this.state.recipientEmail}
                            error={this.state.recipientEmailError}
                            show={!this.state.noResponse}
                            defaultLabel={'E-mail address of letter recipient'}
                            getStateUpdate={(val: string) => { return { recipientEmail: val } }}
                            updateValue={this.updateState.bind(this)}
                        />

                        <div hidden={this.state.noResponse} className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg11} ${styles.lgOffset1}`}>
                                <span className={`${styles.aside}`}>
                                    If different from person submitting the report (if the same, check N/A):
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ProductDetailsForm>
        )
    }

    updateState(update: any) {
        this.setState(update);
    }

    private createReport(report: IReport, isValid: boolean, errors: string[], files?: FileContent[]) {
        report.recipientEmail = this.state.recipientEmail;
        report.noResponse = this.state.noResponse && this.props.line ? this.props.line.showCorrespondence : false;

        isValid = this.validate(report, errors) && isValid;
        this.props.createReport(report, isValid, errors, files);
    }

    validate(report: IReport, errors: string[]): boolean {
        var validator = new CorrespondenceFormValidator(report, errors, this.props.line, this.props.account?.data);
        var validateResult = validator.validateRecipientEmail((err) => { return { recipientEmailError: err } });

        this.setState(validateResult.state);
        return validateResult.isValid;
    }
}