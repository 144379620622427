import React from "react";
import styles from '../../../scss/Table.module.scss';

import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { IProductLine, ProductLine } from "../../../data/models/ProductLine";
import { LineData } from "../../../data/LineData";
import { AxiosResponse } from "axios";
import { EditProductLine } from "../../../components/admin/editors/editProductLine/EditProductLine";
import { Icon } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { ProductLineRow } from "../../../components/admin/rows/productLineRow/ProductLineRow";
import { IAdminPageState } from "../IAdminPageState";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { Exporter } from "../../../components/exporter/Exporter";

export class ProductLines extends React.Component<{}, IAdminPageState<IProductLine>> {

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            editId: 0,
            showEdit: false,
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            columns: [
                { title: 'Order', column: 'displayOrder' },
                { title: 'Product Line', column: 'name' },
                { title: 'Coordinator', column: 'coordinator' },
                { title: 'Report Header', column: 'reportHeader' },
                { title: 'Enabled', column: 'enabled' },
            ],
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm10}`}>
                                <h1>
                                    Product Lines
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.right}`}>
                                <span className={`${styles.link} ${styles.createNew}`} onClick={this.createLine.bind(this)}>
                                    <Icon iconName="CircleAddition" />
                                </span>
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderRows(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <Exporter type='lines' />
                    </div>

                    <EditProductLine
                        id={this.state.editId}
                        items={this.state.items}
                        show={this.state.showEdit}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadLines.bind(this, this.state.pageInfo)} />

                </AdminNav>
            </Authenticator>
        );
    }

    loadData() {
        this.loadLines(this.state.pageInfo);
    }

    loadLines(pageInfo: IPageInfo) {
        LineData.retrievePage(pageInfo, this.state.columns)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });
                }
                else {
                    console.log('error getting product lines');
                    console.log(response.data);
                }
            });
    }

    renderRows(lines: IProductLine[]) {
        return lines.map((line, i) => {
            return (
                <ProductLineRow
                    key={i}
                    index={i}
                    item={line}
                    onEdit={this.openEdit.bind(this)} />
            );
        })
    }

    createLine() {
        var newLine = new ProductLine();
        this.openEdit(newLine);
    }

    openEdit(item: IProductLine) {
        var id = item.id ? item.id : 0;

        this.setState({
            editId: id,
            showEdit: true,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadLines(info);
    }
}