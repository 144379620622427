import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';
import { IOptionalFieldData } from "../IOptionalFieldProps";
import { OptionalField } from "../OptionalField";
import { EditCorrespondence } from "./EditCorrespondence";
import { IEditorProps } from "./IEditorProps";

export class EditGeneralInformation extends React.Component<IEditorProps, {}> {

    render() {
        return (
            <EditCorrespondence
                item={this.props.item}
                onChange={this.props.onChange} >

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm11} ${styles.smOffset1} ${styles.lg9} ${styles.lgOffset3}`}>
                        <div className={styles.labelBox}>
                            <h3>Optional General Incident Fields</h3>
                        </div>
                    </div>
                </div>

                <div className={`${styles.compactRow}`} >
                    <div className={`${styles.col} ${styles.sm2} ${styles.lg1} ${styles.lgOffset1}`}>
                        <div className={styles.compactLabelBox}>
                            Enable
                        </div>
                    </div>

                    <div className={`${styles.col} ${styles.sm2} ${styles.lg1}`}>
                        <div className={styles.compactLabelBox}>
                            Require
                        </div>
                    </div>

                    <div className={`${styles.col} ${styles.sm11} ${styles.lg9}`}>
                        <div className={styles.compactLabelBox}>
                            Alternative text (optional)
                        </div>
                    </div>
                </div>

                <OptionalField
                    show={this.props.item.showDuringDonation}
                    altText={this.props.item.duringDonationAltText}
                    required={this.props.item.duringDonationRequired}
                    defaultText={'Did the issue occur during a donation?'}
                    onUpdate={this.updateDuringDonation.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showAdverseEvent}
                    altText={this.props.item.adverseEventAltText}
                    required={this.props.item.adverseEventRequired}
                    defaultText={'Was there an adverse event or injury?'}
                    onUpdate={this.updateAdverseEvent.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showCompleted}
                    altText={this.props.item.completedAltText}
                    required={this.props.item.completedRequired}
                    defaultText={'Was the procedure successfully completed?'}
                    onUpdate={this.updateCompleted.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showStopped}
                    altText={this.props.item.stoppedAltText}
                    required={this.props.item.stoppedRequired}
                    defaultText={'Was the infusion stopped before completion?'}
                    onUpdate={this.updateStopped.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showSoftGoods}
                    altText={this.props.item.softGoodsAltText}
                    required={this.props.item.softGoodsRequired}
                    defaultText={'If no, was the procedure stopped due to a soft goods incident?'}
                    onUpdate={this.updateSoftGoods.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showLost}
                    altText={this.props.item.lostAltText}
                    required={this.props.item.lostRequired}
                    defaultText={'Was the product lost?'}
                    onUpdate={this.updateLost.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showDrugAdministered}
                    altText={this.props.item.drugAdministeredAltText}
                    required={this.props.item.drugAdministeredRequired}
                    defaultText={'If applicable, list the name of any drug administered'}
                    onUpdate={this.updateDrugAdministered.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showDrugUsed}
                    altText={this.props.item.drugUsedAltText}
                    required={this.props.item.drugUsedRequired}
                    defaultText={'What drug was used for the infusion?'}
                    onUpdate={this.updateDrugUsed.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showIsCytotoxic}
                    altText={this.props.item.isCytotoxicAltText}
                    required={this.props.item.isCytotoxicRequired}
                    defaultText={'Is the drug used cytotoxic?'}
                    onUpdate={this.updateIsCytotoxic.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showManufacturer}
                    altText={this.props.item.manufacturerAltText}
                    required={this.props.item.manufacturerRequired}
                    defaultText={'Manufacturer'}
                    onUpdate={this.updateManufacturer.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showContainer}
                    altText={this.props.item.containerAltText}
                    required={this.props.item.containerRequired}
                    defaultText={'Container'}
                    onUpdate={this.updateContainer.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showClinicalOrPatientMaterial}
                    altText={this.props.item.clinicalOrPatientMaterialAltText}
                    required={this.props.item.clinicalOrPatientMaterialRequired}
                    defaultText={'Did the procedure involve clinical or patient material?'}
                    onUpdate={this.updateClinicalOrPatientMaterial.bind(this)}
                />

            </EditCorrespondence>
        );
    }

    private updateDuringDonation(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showDuringDonation = data.show;
        item.duringDonationRequired = data.required && data.show;
        item.duringDonationAltText = data.altText;
        this.props.onChange(item);
    }

    private updateAdverseEvent(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showAdverseEvent = data.show;
        item.adverseEventRequired = data.required && data.show;
        item.adverseEventAltText = data.altText;
        this.props.onChange(item);
    }

    private updateCompleted(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showCompleted = data.show;
        item.completedRequired = data.required && data.show;
        item.completedAltText = data.altText;
        this.props.onChange(item);
    }

    private updateStopped(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showStopped = data.show;
        item.stoppedRequired = data.required && data.show;
        item.stoppedAltText = data.altText;
        this.props.onChange(item);
    }

    private updateSoftGoods(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showSoftGoods = data.show;
        item.softGoodsRequired = data.required && data.show;
        item.softGoodsAltText = data.altText;
        this.props.onChange(item);
    }

    private updateLost(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showLost = data.show;
        item.lostRequired = data.required && data.show;
        item.lostAltText = data.altText;
        this.props.onChange(item);
    }

    private updateDrugAdministered(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showDrugAdministered = data.show;
        item.drugAdministeredRequired = data.required && data.show;
        item.drugAdministeredAltText = data.altText;
        this.props.onChange(item);
    }

    private updateDrugUsed(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showDrugUsed = data.show;
        item.drugUsedRequired = data.required && data.show;
        item.drugUsedAltText = data.altText;
        this.props.onChange(item);
    }

    private updateIsCytotoxic(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showIsCytotoxic = data.show;
        item.isCytotoxicRequired = data.required && data.show;
        item.isCytotoxicAltText = data.altText;
        this.props.onChange(item);
    }

    private updateManufacturer(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showManufacturer = data.show;
        item.manufacturerRequired = data.required && data.show;
        item.manufacturerAltText = data.altText;
        this.props.onChange(item);
    }

    private updateContainer(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showContainer = data.show;
        item.containerRequired = data.required && data.show;
        item.containerAltText = data.altText;
        this.props.onChange(item);
    }
    private updateClinicalOrPatientMaterial(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showClinicalOrPatientMaterial = data.show;
        item.clinicalOrPatientMaterialRequired = data.required && data.show;
        item.clinicalOrPatientMaterialAltText = data.altText;
        this.props.onChange(item);
    }
}
