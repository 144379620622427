import React from 'react';
import styles from '../../scss/Base.module.scss';

import { QueryModalProps } from './QueryModalProps';
import { DefaultButton, Modal, TextField } from '@fluentui/react';
import { fullWidthButtonStyles, queryModalStyles, textBoxStyles } from '../../scss/ControlStyles';

interface IState {
    val: string;
}

export class QueryModal extends React.Component<QueryModalProps, IState> {

    constructor(props: QueryModalProps) {
        super(props);

        this.state = {
            val: '',
        }
    }

    render() {
        return (
            <Modal
                styles={queryModalStyles}
                isOpen={this.props.show}
                onDismiss={this.props.onClose}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h3>{this.props.title}</h3>
                        <p>{this.props.message}</p>
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.props.showTextbox} >
                    <div className={`${styles.col} ${styles.sm12}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.val}
                            onChange={this.valChanged.bind(this)}
                            hidden={!this.props.showTextbox}
                        />
                    </div>
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${this.props.showCancelButton ? styles.sm6 : styles.sm12}`}>
                        <DefaultButton
                            text='OK'
                            styles={fullWidthButtonStyles}
                            onClick={this.okClicked.bind(this)} />
                    </div>

                    <div className={`${styles.col} ${styles.sm6}`} hidden={!this.props.showCancelButton} >
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.cancelClicked.bind(this, true)} />
                    </div>
                </div>
            </Modal >
        );
    }

    private valChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = val ? val : '';
        this.setState({ val: val });
    }

    private okClicked() {
        this.props.onOkClicked(this.state.val);
        this.setState({ val: '' });
    }

    private cancelClicked() {
        this.props.onClose();
        this.setState({ val: '' });
    }
}
