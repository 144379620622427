import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditReturn } from "./EditReturn";
import { InputCheck } from "../InputCheck";
import { IDropdownOption, Label } from "@fluentui/react";
import { labelStyles } from "../../../../scss/ControlStyles";
import { Selector } from "../../../selector/selector";
import { IProductLine } from "../../../../data/models/ProductLine";
import { IProblemActivity } from "../../../../data/models/ProblemActivity";
import { IProblemDescription } from "../../../../data/models/ProblemDescription";
import { IProblemLocation } from "../../../../data/models/ProblemLocation";

export class EditProblem extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditReturn {...this.props}>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Problem Details</h3>
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Problem Detected:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            options={this.getActivities(this.props.productLine)}
                            selectedOption={{ key: this.props.item.problemActivityId, text: '' }}
                            onSelected={this.activitySelected.bind(this)} />
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Problem Detected Specifics'
                    value={this.props.item.problemActivitySpecifics}
                    update={(report, val) => report.problemActivitySpecifics = val} />


                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Problem Type:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            options={this.getDescriptions(this.props.productLine)}
                            selectedOption={{ key: this.props.item.problemDescriptionId, text: '' }}
                            onSelected={this.descriptionSelected.bind(this)} />
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Problem Type Specifics'
                    value={this.props.item.problemDescriptionSpecifics}
                    update={(report, val) => report.problemDescriptionSpecifics = val} />

                <InputCheck
                    {...this.props}
                    label='Hole Tear Cut Cause Leak'
                    value={this.props.item.holeTearCutCauseLeak}
                    update={(report, val) => report.holeTearCutCauseLeak = val} />

                <InputCheck
                    {...this.props}
                    label='Leaking While Adding Pressure'
                    value={this.props.item.leakingWhileAddingPressure}
                    update={(report, val) => report.leakingWhileAddingPressure = val} />

                <InputField
                    {...this.props}
                    label='Leak Location'
                    value={this.props.item.leakLocation}
                    update={(report, val) => report.leakLocation = val} />

                <InputField
                    {...this.props}
                    label='Leak Type'
                    value={this.props.item.leakType}
                    update={(report, val) => report.leakType = val} />

                <InputCheck
                    {...this.props}
                    label='Any Alarms'
                    value={this.props.item.anyAlarms}
                    update={(report, val) => report.anyAlarms = val} />

                <InputField
                    {...this.props}
                    label='Alarm Codes'
                    value={this.props.item.alarmCodes}
                    update={(report, val) => report.alarmCodes = val} />

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Problem Location:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            options={this.getLocations(this.props.productLine)}
                            selectedOption={{ key: this.props.item.problemLocationId || 0, text: '' }}
                            onSelected={this.locationSelected.bind(this)} />
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Additional Incident Description'
                    value={this.props.item.additionalIncidentDescription}
                    update={(report, val) => report.additionalIncidentDescription = val} />

            </EditReturn>
        );
    }

    private getActivities(productLine?: IProductLine): IDropdownOption[] {
        if (!productLine) return [];

        return productLine.problemActivities.map((activity: IProblemActivity) => {
            return {
                data: activity,
                key: activity.id ? activity.id : 0,
                index: activity.id,
                text: activity.description
            };
        });
    }

    private activitySelected(option: IDropdownOption) {
        var report = this.props.item;
        report.problemActivityId = option.data.id;
        this.props.onChange(report);
    }

    private getDescriptions(productLine?: IProductLine): IDropdownOption[] {
        if (!productLine) return [];
        return productLine.problemDescriptions.map((description: IProblemDescription) => {
            var id = description.id ? description.id : 0;

            return {
                data: description,
                key: id,
                index: description.id,
                text: description.description
            };
        });
    }

    private descriptionSelected(option: IDropdownOption) {
        var report = this.props.item;
        report.problemDescriptionId = option.data.id;
        this.props.onChange(report);
    }

    private getLocations(productLine?: IProductLine): IDropdownOption[] {
        if (!productLine?.problemLocations) return [];
        
        return productLine.problemLocations.map((location: IProblemLocation) => {
            var id = location.id ? location.id : 0;

            return {
                data: location,
                key: id,
                index: location.id,
                text: location.description
            };
        });
    }

    private locationSelected(option: IDropdownOption) {
        var report = this.props.item;
        report.problemLocationId = option.data.id;
        this.props.onChange(report);
    }
}
