import React from 'react';
import styles from '../../../../scss/components/Editor.module.scss';

import { DefaultButton, IDropdownOption, Label, Modal } from '@fluentui/react';
import { fullWidthButtonStyles, labelStyles, modalStyles } from '../../../../scss/ControlStyles';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditProps } from '../IEditProps';
import { IEditState } from '../IEditState';
import { IReport, Report } from '../../../../data/models/Report';
import { ReportData } from '../../../../data/ReportData';
import { DateHelper } from '../../../../data/helpers/dateHelper';
import { EditAccount } from './EditAccount';
import { InputField } from '../InputField';
import { AccountData } from '../../../../data/AccountData';
import { IProductLine } from '../../../../data/models/ProductLine';
import { LineData } from '../../../../data/LineData';
import { ProblemLocationData } from '../../../../data/ProblemLocationData';
import { Selector } from '../../../selector/selector';
import { ReportStatusEnum } from '../../../../data/enums/ReportStatusEnum';
import { error } from 'console';

interface IState extends IEditState<IReport> {
    accounts: IDropdownOption[];
    productLine?: IProductLine;
    statuses: IDropdownOption[];
    products: IDropdownOption[];
    error: boolean;
}

export class EditReport extends React.Component<IEditProps<IReport>, IState> {

    constructor(props: IEditProps<IReport>) {
        super(props);

        var item = this.cloneItem();

        var statuses = [
            this.getStatusOption(ReportStatusEnum.SUBMITTED, 1),
            this.getStatusOption(ReportStatusEnum.INPROGRESS, 2),
            this.getStatusOption(ReportStatusEnum.GCMW, 3),
            this.getStatusOption(ReportStatusEnum.CLOSED, 4),
        ]

        this.state = {
            error: false,
            item: item,
            accounts: [],
            statuses: statuses,
            show: this.props.show,
            queryModalProps: new QueryModalProps(),
            products: [],
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();
            this.setState({
                accounts: [],
                item: newItem,
                show: this.props.show
            });

            this.loadAccounts(newItem.creatorId);

            if (newItem.problemActivity) {
                this.loadLine(newItem.problemActivity.productLineId);
            }
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>Edit Report</h2>
                    </div>
                </div>

                <InputField
                    disabled
                    label='Submission #'
                    item={this.state.item}
                    onChange={this.itemChanged}
                    value={this.state.item.id?.toString()} />

                <InputField
                    // disabled
                    label='Notification #'
                    item={this.state.item}
                    onChange={this.itemChanged.bind(this)}
                    value={this.state.item.notification}
                    update={(report, val) => {report.notification = val}} />

                <InputField
                    disabled
                    label='Created By'
                    item={this.state.item}
                    onChange={this.itemChanged}
                    value={this.state.item.creator} />

                <InputField
                    disabled
                    label='Has Attachments'
                    item={this.state.item}
                    onChange={this.itemChanged}
                    value={this.state.item.hasAttachments ? 'Yes' : 'No'} />

                <InputField
                    disabled
                    label='Created At'
                    item={this.state.item}
                    onChange={this.itemChanged}
                    value={DateHelper.formatDate(this.state.item.createdAt)} />

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Status:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            options={this.state.statuses}
                            selectedOption={{ key: this.state.item.status, text: '' }}
                            onSelected={this.statusSelected.bind(this)} />
                    </div>
                </div>

                <EditAccount
                    item={this.state.item}
                    accounts={this.state.accounts}
                    productLine={this.state.productLine}
                    products={this.state.products}
                    onChange={this.itemChanged.bind(this)} 
                    />

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.saveItem.bind(this)}
                            disabled={this.state.error}
                            />
                        {this.state.error ? <div style={{color: "red"}}>Disabled because no Product is chosen.</div> : null}
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

                <QueryModal {...this.state.queryModalProps} />

            </Modal >
        );
    }

    private cloneItem(): IReport {
        var clone = new Report();

        if (this.props.items && this.props.items.length > 0) {
            var item = this.props.items.filter((item) => item.id === this.props.id);

            if (item.length > 0) {
                Object.assign(clone, item[0]);
            }
        }

        if (clone.incidentDate) clone.incidentDate = new Date(clone.incidentDate);

        return clone;
    }

    private getStatusOption(status: ReportStatusEnum, index: number): IDropdownOption {
        return {
            key: status,
            text: status,
            data: status,
            index: index,
        }
    }

    private loadAccounts(userId: number) {
        AccountData.getAccountOptions(userId)
            .then((response) => {
                this.setState({ accounts: response });
            });
    }

    private async loadLine(lineId: number) {
        var response = await LineData.retrieveById(lineId);

        if (response.status === 200) {
            this.setState({ productLine: response.data });
            this.loadLocations(lineId);
        }
        else {
            console.log(response.data);
        }
    }

    private async loadLocations(lineId: number) {
        var response = await ProblemLocationData.retrieveById(lineId);

        if (response.status === 200) {
            var line = this.state.productLine;
            if (!line) return;

            line.problemLocations = response.data;
            this.setState({ productLine: line });
        }
        else {
            console.log(response.data);
        }
    }

    private statusSelected(option: IDropdownOption) {
        var report = this.state.item;
        report.status = option.data;
        console.log(report.status);
        this.itemChanged(report);
    }

    private itemChanged(item: IReport) {
        this.setState({ item: item });
    }

    // private closeQueryModal() {
    //     var queryModalProps = new QueryModalProps();
    //     this.setState({ queryModalProps: queryModalProps });
    // }

    private saveItem() {
        if (this.state.item.productId < 0) {
            alert("Please pick a product")
            return;
        }
        ReportData.update(this.state.item)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
