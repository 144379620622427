import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IUserAccount } from "./models/UserAccount";

export class UserAccountData {

    public static async create(item: IUserAccount): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/useraccounts', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IUserAccount[]>> {
        var result = await DataLayer.get<IUserAccount[]>('/admin/useraccounts');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post('/admin/useraccounts/filter', options);
        return result;
    }

    public static async delete(userId: number, accountId: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/useraccounts/${userId}/${accountId}`);
        return result;
    }
}
