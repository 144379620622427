import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class NeedleFormValidator {

    private report: IReport;
    private errors: string[];
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine) {
        this.line = line;
        this.errors = errors;
        this.report = report;
    }

    validateNeedleDropdownOption(state: (err: boolean) => any): IValidateResult {
        let result = true;

        if (this.line?.showNeedleIssueDetails &&
            !this.report.needleNotRelated &&
            !this.report.needleDropdownOption) {

            this.errors.push('Did any needle related issues occur?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
