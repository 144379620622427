import React from "react";
import styles from '../../scss/pages/ReportSearch.module.scss';

import { Authenticator } from "../../components/authenticator/Authenticator";
import { ReportData } from "../../data/ReportData";
import { HeaderRow } from "../../components/admin/rows/headerRow/HeaderRow";
import { IReportSummary } from "../../data/models/ReportSummary";
import { IPageInfo } from "../../components/admin/paginator/IPageProps";
import { IColumnData } from "../admin/IAdminPageState";
import { Copyright } from "../../components/copyRight/CopyRight";
import { DefaultButton } from "@fluentui/react";
import { fullWidthButtonStyles } from "../../scss/ControlStyles";
import { DateHelper } from "../../data/helpers/dateHelper";

export interface IState {
    pageInfo: IPageInfo;
    searchString: string;
    reports: IReportSummary[];
    columns: IColumnData[];
    noResults: boolean;
}

export class SearchResults extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        const { search } = (this.props as any).match.params;

        this.state = {
            noResults: false,
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: -1,
                sortDir: 'ASC',
            },
            searchString: search,
            reports: [],
            columns: [
                { title: 'Submission #', column: 'id' },
                { title: 'Account #', column: 'accountId' },
                { title: 'Account Name', column: 'accountId' },
                { title: 'QA Contact', column: 'qaContact' },
                { title: 'Product Line', column: 'productLineId' },
                { title: 'Incident Date', column: 'incidentDate' },
                { title: 'Product Code', column: 'productId' },
                { title: 'Product Description', column: 'productId' },
                { title: 'Lot Number', column: 'lotNumber' },
                { title: 'Problem Detected', column: 'problemActivityId' },
                { title: 'Problem Type', column: 'problemTypeId' },
                { title: 'Status', column: 'status' },
                { title: 'Attach', column: 'hasAttachments' },
            ],
        };
    }


    render() {
        return (
            <Authenticator redirectIfLoggedOut={true} onAuthenticated={this.loadReports.bind(this)}>
                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm10} ${styles.smOffset1}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <h1>Search Results</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12}`}>
                        <div className={`${styles.table}`}>
                            <HeaderRow
                                hideEdit
                                columns={this.state.columns}
                                pageInfo={this.state.pageInfo}
                                onPageChanged={this.onPageChanged.bind(this)} />


                            {this.state.reports.map((rpt) => {
                                return (
                                    <a className={`${styles.tableRow}`} key={rpt.reportId} href={`/summary/${rpt.reportId}`}>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.reportId}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.account}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.accountName}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.qaContact}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.lineName}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {DateHelper.formatDate(rpt.incidentDate)}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.productCode}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.productDescription}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.lotNumber}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.problemActivity}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.problemType}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.status}
                                        </div>
                                        <div className={`${styles.tableCell}`}>
                                            {rpt.hasAttachments ? 'Yes' : 'No'}
                                        </div>
                                    </a>
                                )
                            })}
                        </div>

                        {this.state.noResults ? (
                            <p className={styles.center}>
                                There is no data, please redefine your search criteria.
                            </p>
                        ) : ''}
                    </div>
                </div>

                <div className={`${styles.paddedRow}`}>

                    <div className={`${styles.col} ${styles.sm6} ${styles.lg9}`}>
                        <span>To start a new search, <a href='/search'>click here</a></span>
                    </div>
                    <div className={`${styles.col} ${styles.sm6} ${styles.lg3}`}>
                        <DefaultButton
                            text='Export to Excel'
                            styles={fullWidthButtonStyles}
                            onClick={this.export.bind(this)} />
                    </div>
                </div>

                <Copyright cssName={'register'} />

            </Authenticator >
        );
    }

    private loadReports() {
        ReportData.search(this.state.searchString)
            .then((result) => {
                this.setState({
                    reports: result.data,
                    noResults: result.data.length === 0
                });
            });
    }

    private onPageChanged(info: IPageInfo) {
        // ToDo: Lets put pagination in here
    }

    private export() {
        var apiUri = process.env.REACT_APP_API_URI || 'http://localhost:8080';
        var scvUri = `${apiUri}/reportexport/${this.state.searchString}`;
        window.open(scvUri);
    }
}