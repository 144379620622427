import styles from '../../scss/pages/ReportDetail.module.scss';
import { IReportDetailFieldProps } from "./IReportDetailFieldProps";

export function ReportDetailField(props: IReportDetailFieldProps) {
    var getLabel = () => {
        var label: JSX.Element;

        if (props.altText && props.altText.length > 0) {
            label = <span dangerouslySetInnerHTML={{ __html: props.altText }}></span>;
        }
        else {
            label = <span dangerouslySetInnerHTML={{ __html: props.text }}></span>;
        }

        return label;
    }

    if (props.show) {
        return (
            <div className={`${styles.detailsRow}`}>
                <div className={`${styles.detailsCell}`}>
                    {getLabel()}
                </div>
                <div className={`${styles.detailsCell}`}>
                    {props.value}
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}