import { createContext } from "react";
import { AuthenticationStatusEnum } from "../../data/enums/AuthenticationStatusEnum";
import { UserRoleEnum } from "../../data/enums/UserRoleEnum";
import { IUser, User } from "../../data/models/User";

export class AuthContextState {
    public user: IUser;
    public authenticate: () => void;
    public onAuthenticated: (func: () => void) => void
    public authenticationStatus: AuthenticationStatusEnum;

    constructor() {
        this.user = new User();
        this.user.role = UserRoleEnum.User;
        this.authenticate = () => { };
        this.onAuthenticated = (func: () => void) => { };
        this.authenticationStatus = AuthenticationStatusEnum.Unknown;
    }
}

export const AuthContext = createContext<AuthContextState>(new AuthContextState());