import { ProductLine } from "./ProductLine";

export interface INeedleDropdownOption {
    id?: number
    description: string;
    displayOrder: number;
    enabled: boolean;
    productLineId: number;

    productLine?: ProductLine;
}

export class NeedleDropdownOption implements INeedleDropdownOption {
    public description: string = '';
    public displayOrder: number = 0;
    public enabled: boolean = false;
    public productLineId: number = 0;
}
