export enum LocationTypeIdEnum {
    Unknown = 0,
    Known = 1,
    Packaging = 2,
    Tubing = 3
}
export enum LocationTypeDescriptionEnum {
    Unknown = "Unknown",
    Known = "Known",
    Packaging = "Packaging",
    Tubing = "Tubing"
}
