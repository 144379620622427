import React from "react";
import styles from '../../scss/components/ReportSummary.module.scss';
import { IReport } from "../../data/models/Report";
import { ReportData } from "../../data/ReportData";
import { IReportSummaryProps } from "./IReportSummaryProps";

interface IState {
    accountId?: number;
    reports: IReport[];
    summary: Map<string, number>;
}

export class ReportSummary extends React.Component<IReportSummaryProps, IState> {

    constructor(props: IReportSummaryProps) {
        super(props);

        this.state = {
            reports: [],
            summary: new Map<string, number>(),
        }
    }

    componentDidUpdate() {
        if (this.state.accountId !== this.props.account?.index) {
            this.setState({ accountId: this.props.account?.index });
            this.loadReports();
        }
    }

    render() {
        var keys = Array.from(this.state.summary.keys());

        return (
            <div className={keys.length > 0 ? styles.summary : ''}>
                {keys.map((key) => {
                    return (
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm5} ${styles.smOffset1}`}>
                                {key}
                            </div>
                            <div className={`${styles.col} ${styles.sm4}`}>
                                {this.state.summary.get(key)}
                            </div>
                        </div>

                    )
                })}
            </div>
        )
    }

    loadReports() {
        var account = this.props.account;
        if (!account || !account.index) return;

        ReportData.retrieveByAccount(account.index)
            .then((result) => {
                this.createSummary(result.data);
            });
    }

    createSummary(reports: IReport[]) {
        var summary = new Map<string, number>();
        for (var report of reports) {
            var val = summary.get(report.status) || 0;
            summary.set(report.status, ++val);
        }
        this.setState({ summary: summary });
    }
}