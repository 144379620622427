import styles from '../../scss/components/Copyright.module.scss'

export interface ICopyrightProps {
    cssName: string;
}

export function Copyright(props: ICopyrightProps) {
    return (
        <div className={`${styles.copyRow} ${props.cssName}`}>
            <div className={`${styles.copyCol} ${styles.center}`}>
                Fresenius Kabi AG &copy;
            </div>
        </div>
    );
}
