import React from 'react';
import styles from '../../../scss/components/Editor.module.scss';

import { IInputCheckProps } from './IInputCheckProps';

import {
    Checkbox,
    Label,
} from '@fluentui/react';

import {
    checkboxStyles,
    labelStyles
} from '../../../scss/ControlStyles';

export function InputCheck(props: IInputCheckProps) {

    const checkboxClicked = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        var val = checked ? true : false;
        var item = props.item;
        props.update(item, val);
        props.onChange(item);
    }


    return (

        <div className={`${styles.compactRow}`}>
            <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                <div className={styles.labelBox}>
                    <Label styles={labelStyles}>{props.label}:</Label>
                </div>
            </div>
            <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                <div className={styles.labelBox}>
                    <Checkbox
                        styles={checkboxStyles}
                        checked={props.value}
                        disabled={props.disabled}
                        onChange={checkboxClicked}
                    />
                </div>
            </div>
        </div>
    )
}