import React, { FormEvent } from 'react';
import styles from '../../../scss/pages/Login.module.scss';

import { Copyright } from '../../../components/copyRight/CopyRight';
import { Authenticator } from '../../../components/authenticator/Authenticator';
import { AuthData } from '../../../data/AuthData';
import { AxiosResponse } from 'axios';

import {
    buttonStyles,
    fullWidthButtonStyles,
    labelStyles,
    textBoxStyles,
} from '../../../scss/ControlStyles';

import {
    DefaultButton,
    Label,
    TextField
} from '@fluentui/react';
import { AuthContext } from '../../../components/authProvider/AuthContext';
import { UserData } from '../../../data/UserData';

interface IState {
    password: string;
    newPassword: string;
    confirmPassword: string;
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    passwordError: string;
    accountChanged: boolean;
    passwordChanged: boolean;
}

export class Account extends React.Component<{}, IState> {
    static contextType = AuthContext;

    constructor(props: {}) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            confirmPassword: '',
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            passwordError: '',
            accountChanged: false,
            passwordChanged: false,
        };
    }

    setInitialValues() {
        this.setState({
            firstname: this.context.user.firstname,
            lastname: this.context.user.lastname,
            phone: this.context.user.phone,
            email: this.context.user.email,
        })
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut={true} onAuthenticated={this.setInitialValues.bind(this)} >
                <div className={`${styles.login}`}>
                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <h1>
                                Account: {`${this.context.user.firstname} ${this.context.user.lastname}`}
                            </h1>
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>
                                <strong>Account Details:</strong>
                                <br />
                                Update the fields below and click Save to update your account details.
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.row}`} hidden={!this.state.accountChanged}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>Your account details have been successfully changed. The changes will take effect when you next log in.</p>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles}>First Name:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                            <TextField
                                styles={textBoxStyles}
                                value={this.state.firstname}
                                onChange={this.firstnameChanged.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles}>Last Name:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                            <TextField
                                styles={textBoxStyles}
                                value={this.state.lastname}
                                onChange={this.lastnameChanged.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles}>Phone Number:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                            <TextField
                                styles={textBoxStyles}
                                value={this.state.phone}
                                onChange={this.phoneChanged.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles}>Email Address:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                            <TextField
                                styles={textBoxStyles}
                                value={this.state.email}
                                onChange={this.emailChanged.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl8} ${styles.xlOffset3}`}>
                            <DefaultButton
                                text="Save"
                                styles={fullWidthButtonStyles}
                                onClick={this.save.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.row}`}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>
                                <strong>Change Password:</strong>
                                <br />
                                To reset your password, provide your current password, provide
                                a new password and then confirm your new password.
                            </p>
                        </div>
                    </div>

                    <div className={`${styles.row}`} hidden={!this.state.passwordChanged}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>Your password has been successfully changed.</p>
                        </div>
                    </div>

                    <div className={`${styles.row}`} hidden={!this.state.passwordChanged}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <DefaultButton
                                text="Home"
                                styles={buttonStyles}
                                onClick={() => window.location.href = '/'}
                            />
                        </div>
                    </div>

                    <form onSubmit={this.onChangePassword.bind(this)} hidden={this.state.passwordChanged}>
                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles} htmlFor='txt_password'>Password:</Label>
                                </div>
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                                <TextField
                                    type='password'
                                    id='txt_password'
                                    revealPasswordAriaLabel="Show password"
                                    styles={textBoxStyles}
                                    value={this.state.password}
                                    onChange={this.passwordChanged.bind(this)}
                                    errorMessage={this.state.passwordError}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles} htmlFor='txt_newpassword'>New Password:</Label>
                                </div>
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                                <TextField
                                    type='password'
                                    id='txt_newpassword'
                                    revealPasswordAriaLabel="Show password"
                                    styles={textBoxStyles}
                                    value={this.state.newPassword}
                                    onChange={this.newPasswordChanged.bind(this)}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles} htmlFor='txt_confirmpassword'>Confirm New Password:</Label>
                                </div>
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl8}`}>
                                <TextField
                                    type="password"
                                    id='txt_confirmpassword'
                                    revealPasswordAriaLabel="Show password"
                                    styles={textBoxStyles}
                                    value={this.state.confirmPassword}
                                    onChange={this.confirmPasswordChanged.bind(this)}
                                    validateOnLoad={false}
                                    validateOnFocusIn={false}
                                    validateOnFocusOut={true}
                                    onGetErrorMessage={this.validateConfirmPassword.bind(this)}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl8} ${styles.xlOffset3}`}>
                                <DefaultButton
                                    type="submit"
                                    text="Change Password"
                                    styles={fullWidthButtonStyles}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl8} ${styles.xlOffset3}`}>
                                <DefaultButton
                                    text="Cancel"
                                    styles={fullWidthButtonStyles}
                                    onClick={() => window.location.href = '/'}
                                />
                            </div>
                        </div>
                    </form>

                    <Copyright cssName={'change'} />
                </div>
            </Authenticator>
        );
    }

    private firstnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ firstname: val });
    }

    private lastnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ lastname: val });
    }

    private phoneChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ phone: val });
    }

    private emailChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ email: val });
    }

    private passwordChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ password: val });
    }

    private newPasswordChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ newPassword: val });
    }

    private confirmPasswordChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ confirmPassword: val });
    }

    private save() {
        UserData.update(
            this.state.firstname,
            this.state.lastname,
            this.state.email,
            this.state.phone,
        ).then((response) => {
            this.setState({ accountChanged: true });
        })
    }

    private validateConfirmPassword() {
        var isValid = this.state.newPassword === this.state.confirmPassword;
        return isValid ? '' : 'New Password and Confirm New Password must match';
    }

    private onChangePassword(event?: FormEvent) {
        event?.preventDefault();
        if (!this.validateForm()) return;

        AuthData.changePassword(this.state.password, this.state.newPassword)
            .then((response: AxiosResponse<any>) => {
                this.handleChangeResponse(response);
            });
    }

    private validateForm() {
        return this.state.newPassword === this.state.confirmPassword
            && this.state.newPassword.length > 0
            && this.state.password.length > 0;
    }

    private handleChangeResponse(response: AxiosResponse<any>) {
        if (response.status === 200) {
            this.setState({ passwordChanged: true });
        }
        if (response.status === 403) {
            this.setState({ passwordError: response.data });
        }
    }
}