import React from "react";
import styles from '../../scss/components/Form.module.scss';

import { ITextInputProps } from "./IFormFunctionProps";

import {
    Checkbox,
    Label,
    TextField
} from "@fluentui/react";

import {
    checkboxStyles,
    labelStyles,
    textBoxStyles
} from '../../scss/ControlStyles';

interface IState {
    naClicked: boolean;
    showError: boolean;
}

export class TextInput extends React.Component<ITextInputProps, IState> {

    constructor(props: ITextInputProps) {
        super(props);

        this.state = {
            naClicked: false,
            showError: false,
        };
    }

    render() {
        if (this.props.show) {

            return (
                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                        <div className={`${styles.labelBox} ${this.props.error ? styles.red : ''}`}>
                            <Label styles={labelStyles} >
                                {this.getLabel()}{this.showColon() ? ':' : ''}
                            </Label>
                        </div>
                    </div>
                    <div hidden={!this.props.showNotApplicable} className={`${styles.col} ${styles.sm1}`}>
                        <div className={styles.labelBox}>
                            <Checkbox
                                checked={this.state.naClicked}
                                styles={checkboxStyles}
                                onChange={this.naClicked.bind(this)}
                                label={'N/A'} />
                        </div>
                    </div>
                    <div className={`${styles.col} ${this.props.showNotApplicable ? styles.sm10 : styles.sm12} ${this.props.showNotApplicable ? styles.lg5 : styles.lg6}`}>
                        <TextField
                            onPaste={this.onPaste.bind(this)}
                            value={this.props.value}
                            styles={textBoxStyles}
                            onChange={this.valueChanged.bind(this)}
                            multiline={this.props.multiline}
                            maxLength={this.props.charLimit}
                            errorMessage={this.state.showError ? this.props.charLimitMessage : ''}
                            
                        />
                    </div>
                </div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    getLabel() {
        var label: JSX.Element;

        if (this.props.alternativeLabel && this.props.alternativeLabel.length > 0) {
            label = <span dangerouslySetInnerHTML={{ __html: this.props.alternativeLabel }}></span>;
        }
        else {
            label = <span dangerouslySetInnerHTML={{ __html: this.props.defaultLabel }}></span>;
        }

        if (this.props.required) {
            label = (
                <span>
                    <span className={styles.red}>*</span> {label}
                </span>
            )
        }

        return label;
    }

    showColon() {
        var label = this.props.defaultLabel;

        if (this.props.alternativeLabel && this.props.alternativeLabel.length > 0) {
            label = this.props.alternativeLabel;
        }

        return !label.match(/.*[\?:]$/);
    }

    valueChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        
        if (this.props.charLimit && val.length >= this.props.charLimit) {
            val = val.substring(0, this.props.charLimit);
            this.setState({ showError: true });
        } else if (this.props.charLimit && val.length < this.props.charLimit) {
            this.setState({ showError: false })
        }

        var stateUpdate = this.props.getStateUpdate(val);
        this.props.updateValue(stateUpdate);

        if (this.state.naClicked) {
            this.setState({ naClicked: false });
        }

        
    }

    naClicked(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        var val = checked ? true : false;
        this.setState({ naClicked: val });

        if (!val) return;

        var stateUpdate = this.props.getStateUpdate('N/A');
        this.props.updateValue(stateUpdate);
    }

    onPaste(e: any) {
        if(this.props.noPaste && e) {
            e.preventDefault();
        }
    }
}