import React from "react";
import styles from '../../../scss/Table.module.scss';

import { Authenticator } from "../../../components/authenticator/Authenticator";
import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { AxiosResponse } from "axios";
import { DefaultButton, Icon, Modal } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { IColumnData } from "../IAdminPageState";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { FileContent } from "use-file-picker/dist/interfaces";
import { adminButtonStyles, fullWidthButtonStyles, modalStyles } from "../../../scss/ControlStyles";
import { FileData } from "../../../data/FileData";
import { IDataLoad } from "../../../data/models/DataLoad";
import { DateHelper } from "../../../data/helpers/dateHelper";
import { DataFileUpload } from "./dataFileUpload";

export interface IState {
    items: IDataLoad[];
    apiUri: string;
    showModal: boolean;
    pageInfo: IPageInfo;
    columns: IColumnData[];
    showDataLoad?: IDataLoad;
}

export class DataLoad extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        var apiUri = process.env.REACT_APP_API_URI || 'http://localhost:8080';

        this.state = {
            items: [],
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            apiUri: apiUri,
            showModal: false,
            columns: [
                { title: 'Date', column: 'createdAt' },
                { title: 'File', column: 'filename' },
                { title: 'User', column: 'creator' },
                { title: 'Status', column: 'status' },
            ],
        };
    }

    getFiles = (): FileContent[] | undefined => { return undefined };

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <h1>
                                    Data Uploads
                                </h1>
                            </div>
                        </div>

                        <div className={`${styles.row}`}>

                            <div className={`${styles.col} ${styles.sm12}`}>
                                <DataFileUpload
                                    label='Select files to upload:'
                                    getFiles={(fileUpload) => { this.getFiles = fileUpload }} />
                            </div>
                        </div>


                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg2} ${styles.lgOffset8}`}>
                                <DefaultButton
                                    text='Upload'
                                    onClick={this.uploadClicked.bind(this)}
                                    styles={adminButtonStyles} />
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.state.items.map((dl, i) => {
                                        return (
                                            <div className={`${styles.tableRow}`} key={i}>
                                                <div className={`${styles.tableCell}`}>
                                                    {DateHelper.formatDate(dl.createdAt)}
                                                </div>
                                                <div className={`${styles.tableCell}`}>
                                                    <a href={`${this.state.apiUri}/uploads/dataload/${dl.blobname}/${dl.filename}`} target='_blank'>{dl.filename}</a>
                                                </div>
                                                <div className={`${styles.tableCell}`}>
                                                    {dl.creator}
                                                </div>
                                                <div className={`${styles.tableCell}`}>
                                                    {dl.status}
                                                </div>

                                                <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                                                    <span className={`${styles.link}`} onClick={this.showClicked.bind(this, dl)}>
                                                        <Icon iconName="RedEye" />
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />
                    </div>

                    <Modal
                        styles={modalStyles}
                        isOpen={this.state.showModal}
                        onDismiss={() => this.setState({ showModal: false })} >

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Created Date:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {DateHelper.formatDate(this.state.showDataLoad?.createdAt)}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Created By:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.creator}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                File Name:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.filename}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Status:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.status}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Products Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.products.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Problems Detected Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.detected.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Problem Types Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.types.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Problem Locations Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.locations.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Accounts Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.accounts.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Users Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.users.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                User Accounts Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.useraccounts.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Account Products Added:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.accountproducts.toString()}
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgRight}`}>
                                Errors:
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                {this.state.showDataLoad?.errors.trim().split('\r\n').map((e) => {
                                    return(
                                        <>{e}<br /></>
                                    )
                                })}
                            </div>
                        </div>

                        <div className={`${styles.row} ${styles.center}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <DefaultButton
                                    text='CLOSE'
                                    styles={fullWidthButtonStyles}
                                    onClick={() => this.setState({ showModal: false })} />
                            </div>
                        </div>
                    </Modal >

                </AdminNav>
            </Authenticator>
        );
    }

    loadData() {
        this.loadLoads(this.state.pageInfo);
    }

    loadLoads(pageInfo: IPageInfo) {
        FileData.retrievePage(pageInfo, this.state.columns)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });
                }
                else {
                    console.log('error getting data loads');
                    console.log(response.data);
                }
            });
    }

    showClicked(dl: IDataLoad) {
        this.setState({
            showModal: true,
            showDataLoad: dl,
        })
    }

    uploadClicked() {
        var files = this.getFiles();
        if (!files) return;

        console.log(files);
        this.uploadFiles(files);
    }

    uploadFiles(files: FileContent[]) {
        FileData.dataLoad(files)
            .then(() => this.loadData());
    }


    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadLoads(info);
    }
}