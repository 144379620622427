import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IAccountProduct } from "./models/AccountProduct";

export class AccountProductData {

    public static async create(item: IAccountProduct): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/accountproducts', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IAccountProduct[]>> {
        var result = await DataLayer.get<IAccountProduct[]>('/admin/accountproducts');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post('/admin/accountproducts/filter', options);
        return result;
    }

    public static async delete(accountId: number, productId: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/accountproducts/${accountId}/${productId}`);
        return result;
    }
}
