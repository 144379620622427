import React from "react";
import styles from '../../../scss/Table.module.scss';
import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { IReport } from "../../../data/models/Report";
import { ReportData } from "../../../data/ReportData";
import { AxiosResponse } from "axios";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { ReportRow } from "../../../components/admin/rows/reportRow/ReportRow";
import { EditReport } from "../../../components/admin/editors/editReport/EditReport";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { DefaultButton, TextField } from "@fluentui/react";
import { IReportPageState } from "./IReportPageState";
import { adminButtonStyles } from "../../../scss/ControlStyles";
import { Exporter } from "../../../components/exporter/Exporter";

export class Reports extends React.Component<{}, IReportPageState> {

    constructor(props: {}) {
        super(props);

        var reportId: number | undefined;

        const { id } = (this.props as any).match.params;
        if (id) {
            var idStr = id.replace(/\D/g, '');
            reportId = parseInt(idStr);
        }

        this.state = {
            items: [],
            editId: -1,
            reportId: reportId,
            showEdit: false,
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'DESC',
            },
            columns: [
                { title: 'Id', column: 'id' },
                { title: 'Account', column: 'Account.name' },
                { title: 'User', column: 'creator' },
                { title: 'Created', column: 'createdAt' },
                { title: 'Product Line', column: 'ProblemActivity.ProductLine.name' },
                { title: 'Product Description', column: 'Product.description' },
                { title: 'Incident Date', column: 'incidentDate' },
                { title: 'Status', column: 'status' },
                { title: 'Attach', column: 'hasAttachments' },
            ],
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.md6} ${styles.lg8}`}>
                                <h1>
                                    Reports
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm7}  ${styles.md4}  ${styles.lg3}`}>
                                <DefaultButton 
                                    styles={adminButtonStyles}
                                    text={'Find Report:'} 
                                    onClick={() => window.location.href=`/admin/reports/${this.state.reportId}`} />
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.lg1}`}>
                                <TextField
                                    value={this.state.reportId ? this.state.reportId.toString() : ''} 
                                    onChange={this.idChanged.bind(this)} />
                                    
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderRows(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />
                        
                        <Exporter type='reports' />
                    </div>

                    <EditReport
                        id={this.state.editId}
                        items={this.state.items}
                        show={this.state.showEdit}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadReports.bind(this, this.state.pageInfo)} />

                </AdminNav>

            </Authenticator>
        );
    }

    loadData() {
        this.loadReports(this.state.pageInfo, this.state.reportId);
    }

    loadReports(pageInfo: IPageInfo, reportId?: number) {
        ReportData.retrievePage(pageInfo, this.state.columns, reportId)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    // console.log(response.data.rows);

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });
                }
                else {
                    console.log('error getting reports');
                    console.log(response.data);
                }
            });
    }

    renderRows(lines: IReport[]) {
        return lines.map((line, i) => {
            return (
                <ReportRow
                    key={i}
                    index={i}
                    item={line}
                    onEdit={this.openEdit.bind(this)} />
            );
        })
    }

    openEdit(item: IReport) {
        this.setState({
            editId: item.id !== undefined && item.id > -1 ? item.id : -1,
            showEdit: true,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadReports(info);
    }

    private idChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = val ? val : '';
        val = val.replace(/\D/g, '');
        var id = parseInt(val);
        this.setState({reportId: id});
    }
}