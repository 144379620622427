import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditProduct } from "./EditProduct";
import { InputCheck } from "../InputCheck";

export class EditCorrespondence extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditProduct {...this.props}>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Correspondence Details</h3>
                    </div>
                </div>

                <InputCheck
                    {...this.props}
                    label='No Response'
                    value={this.props.item.noResponse} 
                    update={(report, val) => report.noResponse = val} />

                <InputField
                    {...this.props}
                    label='Recipient Email'
                    value={this.props.item.recipientEmail} 
                    update={(report, val) => report.recipientEmail = val} />

            </EditProduct>
        );
    }
}
