import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, Label, Modal } from '@fluentui/react';
import { fullWidthButtonStyles, labelStyles, modalStyles } from '../../../../scss/ControlStyles';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditState } from '../IEditState';
import { AccountProduct, IAccountProduct } from '../../../../data/models/AccountProduct';
import { AccountProductData } from '../../../../data/AccountProductData';
import { IEditAccountProductProps } from './IEditAccountProductProps';
import { Account, IAccount } from '../../../../data/models/Account';
import { IProduct, Product } from '../../../../data/models/Product';
import { SortHelper } from '../../../../data/helpers/sortHelper';

export class EditAccountProduct extends React.Component<IEditAccountProductProps, IEditState<IAccountProduct>> {

    constructor(props: IEditAccountProductProps) {
        super(props);

        var item = this.cloneItem();

        this.state = {
            item: item,
            show: this.props.show,
            queryModalProps: new QueryModalProps(),
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();
            this.setState({
                item: newItem,
                show: this.props.show
            });
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>New Account Product Association</h2>
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.accountId}
                            options={this.getAccountOptions(this.props.accounts)}
                            onChange={this.accountChanged.bind(this)}
                            allowFreeform
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.accountId}
                            options={this.getAccountNameOptions(this.props.accounts)}
                            onChange={this.accountChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Product Code:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.productId}
                            options={this.getProductCodeOptions(this.props.products)}
                            onChange={this.productChanged.bind(this)}
                            allowFreeform
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Product Description:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.productId}
                            options={this.getProductDescriptionOptions(this.props.products)}
                            onChange={this.productChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.createItem.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

            </Modal >
        );
    }

    private cloneItem(): IAccountProduct {
        var item = new AccountProduct();

        if (this.props.accounts && this.props.accounts.length === 1) {
            var account = this.props.accounts[0];
            item.accountId = account.id ? account.id : 0;
            item.account = account;
        }

        return item;
    }

    private getAccountOptions(accounts: IAccount[]): IComboBoxOption[] {
        return accounts.sort((a1 ,a2) => SortHelper.sortAccount(a1, a2)).map((a) => {
            var id = a.id ? a.id : 0;
            return { key: id, text: `${a.account} - ${a.name}`, data: a };
        });
    }

    private getAccountNameOptions(accounts: IAccount[]): IComboBoxOption[] {
        return accounts.map((a) => {
            var id = a.id ? a.id : 0;
            return { key: id, text: a.name, data: a };
        });
    }

    private getProductCodeOptions(products: IProduct[]): IComboBoxOption[] {
        return products.sort((p1, p2) => SortHelper.sortProduct(p1, p2)).map((p) => {
            return { key: p.id ? p.id : 0, text: p.code, data: p };
        });
    }

    private getProductDescriptionOptions(products: IProduct[]): IComboBoxOption[] {
        return products.map((p) => {
            return { key: p.id ? p.id : 0, text: p.description, data: p };
        });
    }

    private accountChanged(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        var account = option?.data;
        if (!account) account = new Account();
        var item = this.state.item;

        item.accountId = account.id;
        item.account = account;

        this.setState({ item: item });
    }

    private productChanged(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        var product = option?.data;
        if (!product) product = new Product();
        var item = this.state.item;

        item.productId = product.id;
        item.product = product;

        this.setState({ item: item });
    }

    private createItem() {
        AccountProductData.create(this.state.item)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
