import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';
import { IOptionalFieldData } from "../IOptionalFieldProps";
import { OptionalField } from "../OptionalField";
import { EditProblemDetails } from "./EditProblemDetails";
import { IEditorProps } from "./IEditorProps";

export class EditProductDetails extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditProblemDetails
                item={this.props.item}
                onChange={this.props.onChange} >


                {this.props.children}

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm11} ${styles.smOffset1} ${styles.lg9} ${styles.lgOffset3}`}>
                        <div className={styles.labelBox}>
                            <h3>Optional Product Details Fields</h3>
                        </div>
                    </div>
                </div>

                <div className={`${styles.compactRow}`} >
                    <div className={`${styles.col} ${styles.sm2} ${styles.lg1} ${styles.lgOffset1}`}>
                        <div className={styles.compactLabelBox}>
                            Enable
                        </div>
                    </div>

                    <div className={`${styles.col} ${styles.sm2} ${styles.lg1}`}>
                        <div className={styles.compactLabelBox}>
                            Require
                        </div>
                    </div>

                    <div className={`${styles.col} ${styles.sm11} ${styles.lg9}`}>
                        <div className={styles.compactLabelBox}>
                            Alternative text (optional)
                        </div>
                    </div>
                </div >

                <OptionalField
                    show={this.props.item.showProductCode}
                    altText={this.props.item.productCodeAltText}
                    required={this.props.item.productCodeRequired}
                    defaultText={'Product Code'}
                    onUpdate={this.updateProductCode.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showRbcLabelId}
                    altText={this.props.item.rbcLabelIdAltText}
                    required={this.props.item.rbcLabelIdRequired}
                    defaultText={'Red Cell Container Label Identifier (Lower right corner – A or B)'}
                    onUpdate={this.updateRbcLabelId.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showSerialNumber}
                    altText={this.props.item.serialNumberAltText}
                    required={this.props.item.serialNumberRequired}
                    defaultText={'Instrument serial number'}
                    onUpdate={this.updateSerialNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showSoftwareVersion}
                    altText={this.props.item.softwareVersionAltText}
                    required={this.props.item.softwareVersionRequired}
                    defaultText={'Software version'}
                    onUpdate={this.updateSoftwareVersion.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showProductDescription}
                    altText={this.props.item.softwareVersionAltText}
                    required={this.props.item.softwareVersionRequired}
                    defaultText={'Product Collected (ml)'}
                    onUpdate={this.updateProductDescription.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showWbProcessed}
                    altText={this.props.item.wbProcessedAltText}
                    required={this.props.item.wbProcessedRequired}
                    defaultText={'WB Processed (ml)'}
                    onUpdate={this.updateWbProcessed.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showVolPlasma}
                    altText={this.props.item.volPlasmaAltText}
                    required={this.props.item.volPlasmaRequired}
                    defaultText={'Volume of plasma collected (ml)'}
                    onUpdate={this.updateVolPlasma.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showDonorBleedNumber}
                    altText={this.props.item.donorBleedNumberAltText}
                    required={this.props.item.donorBleedNumberRequired}
                    defaultText={'Donor Bleed Number'}
                    onUpdate={this.updateDonorBleedNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showPasLotNumber}
                    altText={this.props.item.pasLotNumberAltText}
                    required={this.props.item.pasLotNumberRequired}
                    defaultText={'PAS Lot Number'}
                    onUpdate={this.updatePasLotNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showBatchTagTime}
                    altText={this.props.item.batchTagTimeAltText}
                    required={this.props.item.batchTagTimeRequired}
                    defaultText={'Batch Tag Time (hh:mm:ss)'}
                    onUpdate={this.updateBatchTagTime.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showBatchTagNumber}
                    altText={this.props.item.batchTagNumberAltText}
                    required={this.props.item.batchTagNumberRequired}
                    defaultText={'Batch Tag Number'}
                    onUpdate={this.updateBatchTagNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showLineNumber}
                    altText={this.props.item.lineNumberAltText}
                    required={this.props.item.lineNumberRequired}
                    defaultText={'Line Number'}
                    onUpdate={this.updateLineNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showVideoJetNumber}
                    altText={this.props.item.videoJetNumberAltText}
                    required={this.props.item.videoJetNumberRequired}
                    defaultText={'Video Jet Number'}
                    onUpdate={this.updateVideoJetNumber.bind(this)}
                />

                <OptionalField
                    show={this.props.item.showFilterNumber}
                    altText={this.props.item.filterNumberAltText}
                    required={this.props.item.filterNumberRequired}
                    defaultText={'Filter Number'}
                    onUpdate={this.updateFilterNumber.bind(this)}
                />
            </EditProblemDetails>
        );
    }

    private updateProductCode(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showProductCode = data.show;
        item.productCodeRequired = data.required && data.show;
        item.productCodeAltText = data.altText;
        this.props.onChange(item);
    }

    private updateRbcLabelId(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showRbcLabelId = data.show;
        item.rbcLabelIdRequired = data.required && data.show;
        item.rbcLabelIdAltText = data.altText;
        this.props.onChange(item);
    }

    private updateSerialNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showSerialNumber = data.show;
        item.serialNumberRequired = data.required && data.show;
        item.serialNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updateSoftwareVersion(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showSoftwareVersion = data.show;
        item.softwareVersionRequired = data.required && data.show;
        item.softwareVersionAltText = data.altText;
        this.props.onChange(item);
    }

    private updateProductDescription(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showProductDescription = data.show;
        item.productDescriptionRequired = data.required && data.show;
        item.productDescriptionAltText = data.altText;
        this.props.onChange(item);
    }

    private updateWbProcessed(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showWbProcessed = data.show;
        item.wbProcessedRequired = data.required && data.show;
        item.wbProcessedAltText = data.altText;
        this.props.onChange(item);
    }

    private updateVolPlasma(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showVolPlasma = data.show;
        item.volPlasmaRequired = data.required && data.show;
        item.volPlasmaAltText = data.altText;
        this.props.onChange(item);
    }

    private updateDonorBleedNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showDonorBleedNumber = data.show;
        item.donorBleedNumberRequired = data.required && data.show;
        item.donorBleedNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updatePasLotNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showPasLotNumber = data.show;
        item.pasLotNumberRequired = data.required && data.show;
        item.pasLotNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updateBatchTagTime(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showBatchTagTime = data.show;
        item.batchTagTimeRequired = data.required && data.show;
        item.batchTagTimeAltText = data.altText;
        this.props.onChange(item);
    }

    private updateBatchTagNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showBatchTagNumber = data.show;
        item.batchTagNumberRequired = data.required && data.show;
        item.batchTagNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updateLineNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showLineNumber = data.show;
        item.lineNumberRequired = data.required && data.show;
        item.lineNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updateVideoJetNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showVideoJetNumber = data.show;
        item.videoJetNumberRequired = data.required && data.show;
        item.videoJetNumberAltText = data.altText;
        this.props.onChange(item);
    }

    private updateFilterNumber(data: IOptionalFieldData) {
        var item = this.props.item;
        item.showFilterNumber = data.show;
        item.filterNumberRequired = data.required && data.show;
        item.filterNumberAltText = data.altText;
        this.props.onChange(item);
    }
}
