import React from "react";
import styles from '../../../scss/components/Form.module.scss';

import { IFormProps } from "../IFormProps";
import { IUser } from "../../../data/models/User";
import { IReport } from "../../../data/models/Report";
import { IAccount } from "../../../data/models/Account";
import { AuthContext } from "../../authProvider/AuthContext";

import {
    labelStyles,
    textBoxStyles
} from "../../../scss/ControlStyles";

import {
    IDropdownOption,
    Label,
    TextField
} from "@fluentui/react";
import { AccountFormValidator } from "../../../data/validation/accountFormValidator";
import { AccountData } from "../../../data/AccountData";
import { Selector } from "../../selector/selector";
import { FileContent } from "use-file-picker/dist/interfaces";
import { CorrespondenceForm } from "../correspondenceForm/CorrespondenceForm";
import { SortHelper } from "../../../data/helpers/sortHelper";

interface IState {
    custRef: string;
    qaContact: string;
    accountError: boolean;
    account?: IDropdownOption;
    accountOptions: IDropdownOption[];
    productOptions: IDropdownOption[];
}

export class AccountForm extends React.Component<IFormProps, IState> {
    static contextType = AuthContext;

    constructor(props: IFormProps) {
        super(props);
        this.state = {
            custRef: '',
            qaContact: '',
            accountError: false,
            account: undefined,
            accountOptions: [],
            productOptions: [],
        }
    }

    componentDidMount() {
        var user: IUser = this.context.user;
        var userId = user.id ? user.id : 0;

        AccountData.getAccountOptions(userId)
            .then((options) => this.onAccountOptionsReturned(options));
    }

    onAccountOptionsReturned(options: IDropdownOption[]) {
        this.setState({ accountOptions: options.sort((a1, a2) => SortHelper.sortAccount(a1.data, a2.data)) });

        if (options.length === 1) {
            this.setAccount(options[0]);
        }
    }

    render() {
        return (
            <CorrespondenceForm
                line={this.props.line}
                createReport={this.createReport.bind(this)}
                products={this.state.productOptions}
                account={this.state.account} >

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                <h3>Account Information:</h3>
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                <div className={`${styles.labelBox} ${this.state.accountError ? styles.red : ''}`}>
                                    <Label styles={labelStyles} htmlFor='account'><span className={styles.red}>*</span> Account:</Label>
                                </div>
                            </div>

                            <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                <Selector
                                    placeholder={'Select An Account'}
                                    selectedOption={this.state.account}
                                    options={this.state.accountOptions}
                                    onSelected={this.setAccount.bind(this)} />
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                <div>
                                    <div className={styles.labelBox}>

                                        <Label styles={labelStyles} htmlFor='custRef'>Customer Reference Number:</Label>
                                    </div>
                                    <div>
                                        <span className={`${styles.aside}`}>
                                            If desired, provide your internal
                                            identifier number within this field
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                <TextField
                                    id='custRef'
                                    styles={textBoxStyles}
                                    value={this.state.custRef}
                                    onChange={this.custRefChanged.bind(this)} />
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                <div>
                                    <div className={styles.labelBox}>

                                        <Label styles={labelStyles} htmlFor='qaContact'>QA Contact:</Label>
                                    </div>
                                    <div>
                                        <span className={`${styles.aside}`}>
                                            Please provide the name of the person who has knowledge of the report and is able to provide additional information if requested.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                <TextField
                                    id='qaContact'
                                    styles={textBoxStyles}
                                    value={this.state.qaContact}
                                    onChange={this.qaContactChanged.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.children}
            </CorrespondenceForm>
        )
    }

    custRefChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        this.setState({ custRef: val ? val : '' });
    }

    qaContactChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        const regex = /[^A-Za-z\d\s-]*/g;
        this.setState({ qaContact: val ? val.replace(regex, '') : '' });
    }

    setAccount(account: IDropdownOption) {
        this.setState({ account: account });
        this.loadProductOptions(account.data);
    }

    loadProductOptions(account: IAccount): void {
        var products: IDropdownOption[] = [];

        if (account.products) {
            for (var p of account.products) {
                var id = p.id ? p.id : 0;
                products.push({
                    data: p,
                    key: id,
                    index: id,
                    text: `${p.code} - ${p.description}`,
                });
            }
        }

        this.setState({ productOptions: products });
    }

    private createReport(report: IReport, isValid: boolean, errors: string[], files?: FileContent[]) {
        var account: IAccount = this.state.account?.data;

        if (account?.id) {
            report.accountId = account?.id;
        }

        report.custRef = this.state.custRef;
        report.qaContact = this.state.qaContact;

        isValid = this.validate(report, errors) && isValid;
        this.props.createReport(report, isValid, errors, files);
    }

    validate(report: IReport, errors: string[]): boolean {
        var validator = new AccountFormValidator(report, errors);
        var validateResult = validator.validateAccount((msg) => { return { accountError: msg } });

        this.setState(validateResult.state);
        return validateResult.isValid;
    }
}
