import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { Checkbox, DefaultButton, Label, Modal, TextField } from '@fluentui/react';
import { checkboxStyles, fullWidthButtonStyles, labelStyles, modalStyles, textBoxStyles } from '../../../../scss/ControlStyles';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IAccount, Account } from '../../../../data/models/Account';
import { IEditState } from '../IEditState';
import { AccountData } from '../../../../data/AccountData';
import { AccountProduct, IAccountProduct } from '../../../../data/models/AccountProduct';
import { EditAccountAccountProduct } from './EditAccountAccountProduct';
import { IEditAccountProps } from './IEditAccountProps';

interface IState extends IEditState<IAccount> {
    showAccountProduct: boolean;
    newAccountProduct: IAccountProduct;
}

export class EditAccount extends React.Component<IEditAccountProps, IState> {

    constructor(props: IEditAccountProps) {
        super(props);

        var item = this.cloneItem();

        this.state = {
            item: item,
            show: this.props.show,
            showAccountProduct: false,
            newAccountProduct: new AccountProduct(),
            queryModalProps: new QueryModalProps(),
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();
            this.setState({
                item: newItem,
                show: this.props.show
            });
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>{!this.state.item.id ? 'New' : 'Edit'} Account</h2>
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.state.item.id}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>ID:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            defaultValue={this.state.item.id?.toString()}
                            disabled
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.account}
                            onChange={this.accountChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.name}
                            onChange={this.nameChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Description:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.description}
                            onChange={this.descriptionChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Address:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.address}
                            onChange={this.addressChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>City:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.city}
                            onChange={this.cityChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>State:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.state}
                            onChange={this.stateChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Zip:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.zip}
                            onChange={this.zipChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Country:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.country}
                            onChange={this.countryChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account Type:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.accountType}
                            onChange={this.accountTypeChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Company Code:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.companyCode}
                            onChange={this.companyCodeChanged.bind(this)}
                        />
                    </div>
                </div>

                

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Hide Correspondence:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <div className={styles.labelBox}>
                            <Checkbox
                                styles={checkboxStyles}
                                checked={this.state.item.hideCorrespondence}
                                onChange={this.hideCorrespondenceChanged.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div hidden={!this.state.item.id}>
                    <EditAccountAccountProduct
                        id={this.props.id}
                        accounts={this.props.items}
                        products={this.props.products}
                        refresh={this.props.refresh} />
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg4}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.saveItem.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`} hidden={!this.state.item.id}>
                        <DefaultButton
                            text='DELETE'
                            styles={fullWidthButtonStyles}
                            onClick={this.showConfirmDeleteAccount.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg4}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

                <QueryModal {...this.state.queryModalProps} />
            </Modal >
        );
    }

    private cloneItem(): IAccount {
        var clone = new Account();

        if (this.props.items && this.props.id > 0 && this.props.items.length > 0) {
            var item = this.props.items.filter((item) => item.id === this.props.id);
            Object.assign(clone, item[0]);
        }

        return clone;
    }

    private accountChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.account = val ? val : '';
        this.setState({ item: item });
    }

    private nameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.name = val ? val : '';
        this.setState({ item: item });
    }

    private descriptionChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.description = val ? val : '';
        this.setState({ item: item });
    }

    private addressChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.address = val ? val : '';
        this.setState({ item: item });
    }

    private cityChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.city = val ? val : '';
        this.setState({ item: item });
    }

    private stateChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.state = val ? val : '';
        this.setState({ item: item });
    }

    private zipChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.zip = val ? val : '';
        this.setState({ item: item });
    }

    private countryChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.country = val ? val : '';
        this.setState({ item: item });
    }

    private accountTypeChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.accountType = val ? val : '';
        this.setState({ item: item });
    }

    private companyCodeChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.companyCode = val ? val : '';
        this.setState({ item: item });
    }

    private hideCorrespondenceChanged(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        var item = this.state.item;
        item.hideCorrespondence = checked ? true : false;
        this.setState({ item: item });
    }

    showConfirmDeleteAccount() {
        if(!this.state.item.id) return;
        var id = this.state.item.id;

        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete this Account?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.deleteItem(id);

        this.setState({ queryModalProps: queryModalProps });
    }

    private deleteItem(id: number) {
        this.closeQueryModal();

        AccountData.delete(id)
            .then(() => this.close());
    }

    closeQueryModal() {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });
    }

    private saveItem() {
        if (this.state.item.id) this.updateItem();
        else this.createItem();
    }

    private createItem() {
        AccountData.create(this.state.item)
            .then(() => this.close());
    }

    private updateItem() {
        AccountData.update(this.state.item)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
