import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditGeneral } from "./EditGeneral";
import { IDropdownOption, Label } from "@fluentui/react";
import { labelStyles } from "../../../../scss/ControlStyles";
import { Selector } from "../../../selector/selector";
import { IUser } from "../../../../data/models/User";
import { AccountData } from "../../../../data/AccountData";
import { SortHelper } from "../../../../data/helpers/sortHelper";
import { IAccount } from "../../../../data/models/Account";
import { AuthContext } from "../../../authProvider/AuthContext";
import { IReport } from "../../../../data/models/Report";

interface IState {
    accountError: boolean;
    account?: IDropdownOption;
    accountOptions: IDropdownOption[];
    productOptions: IDropdownOption[];
    product?: IDropdownOption,
}
export class EditAccount extends React.Component<IEditorProps, IState> {
    static contextType = AuthContext;
    constructor(props: IEditorProps) {
        super(props);
        this.state = {
            accountError: false,
            account: undefined,
            accountOptions: [],
            productOptions: [],
            product: undefined,
        }
    }

    componentDidMount() {
        var user: IUser = this.context.user;
        var userId = user.id ? user.id : 0;

        AccountData.getAccountOptions(userId)
            .then((options) => this.onAccountOptionsReturned(options))
            .then(() => {
                let product = this.state.productOptions.filter((p) => p.key === this.props.item.productId);
                if (product.length > 0) {
                    this.setState({product: product[0]});
                }
                else {
                    this.setState({product: undefined});
                }
            });
    }
    onAccountOptionsReturned(options: IDropdownOption[]) {
        this.setState({ accountOptions: options.sort((a1, a2) => SortHelper.sortAccount(a1.data, a2.data)) });

        if (options.length === 1) {
            this.setAccount(options[0]);
        } else if (options.length > 1) {
            let option = options.filter((p) => p.data.id === this.props.item.accountId);
            this.setAccount(option[0]);
        }
    }
    render() {
        return (
            <EditGeneral {...this.props} >
                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Account Details</h3>
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            // placeholder={"Select an Account to select a new Product"}
                            selectedOption={this.state.account}
                            options={this.state.accountOptions}
                            onSelected={this.resetAccount.bind(this)}
                            />
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Product</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Selector
                            options={this.state.productOptions}
                            selectedOption={this.state.product}
                            onSelected={this.setProduct.bind(this)}
                            />
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Customer Reference'
                    value={this.props.item.custRef}
                    update={(report, val) => report.custRef = val} />

                <InputField
                    {...this.props}
                    label='QA Contact'
                    value={this.props.item.qaContact}
                    update={(report, val) => report.qaContact = val} />

            </EditGeneral>
        );
    }
    resetAccount(account: IDropdownOption) {
        this.setAccount(account, true);
    }
    setAccount(account: IDropdownOption, reset: boolean=false) {
        this.setState({ account: account });
        this.loadProductOptions(account.data);
        let newReport: IReport = this.props.item;
        newReport.accountId = account?.data.id;
        if (reset) newReport.productId = -1;
        this.props.onChange(newReport);
    }
    loadProductOptions(account: IAccount): void {
        let products: IDropdownOption[] = [];

        if (account.products) {
            for (let p of account.products) {
                if (p.enabled) {
                    let id = p.id ? p.id : 0;
                    products.push({
                        data: p,
                        key: id,
                        index: id,
                        text: `${p.code} - ${p.description}`,
                    });
                }
            }
        }
        this.setState({ productOptions: products });
    }
    setProduct(product: IDropdownOption) {
        this.setState({product: product});
        let newReport: IReport = this.props.item;
        newReport.productId = product?.data.id;
        this.props.onChange(newReport);
    }
}
