import React from "react";
import styles from '../../scss/Base.module.scss';

import { Authenticator } from "../../components/authenticator/Authenticator";
import { IProductLine } from "../../data/models/ProductLine";
import { LineData } from "../../data/LineData";
import { GeneralForm } from "../../components/form/generalForm/GeneralForm";
import { AuthContext } from "../../components/authProvider/AuthContext";
import { IReport } from "../../data/models/Report";
import { ReportData } from "../../data/ReportData";
import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { ProblemLocationData } from "../../data/ProblemLocationData";
import { IProblemLocation } from "../../data/models/ProblemLocation";
import { FileContent } from "use-file-picker/dist/interfaces";
import { FileData } from "../../data/FileData";

interface IState {
    lineId: number;
    productLine?: IProductLine;

    saving: boolean;
    errors: string[];
    hideDialog: boolean;
    dialogProps?: IDialogContentProps;
}

export class ReportCreate extends React.Component<{}, IState> {
    static contextType = AuthContext;

    constructor(props: {}) {
        super(props);

        const { lineId } = (this.props as any).match.params;

        this.state = {
            errors: [],
            saving: false,
            lineId: lineId,
            productLine: undefined,
            hideDialog: true,
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut={true} onAuthenticated={this.loadData.bind(this)}>
                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm10} ${styles.smOffset1}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <h1>{this.state.productLine?.reportHeader} Performance Report</h1>
                                <p>Use the form below to submit a product performance report:</p>
                            </div>
                        </div>

                        <GeneralForm
                            line={this.state.productLine}
                            createReport={this.createReport.bind(this)} />

                    </div>
                </div>

                <Dialog
                    hidden={this.state.hideDialog}
                    dialogContentProps={this.state.dialogProps} >
                    {this.state.errors.map((error, i) => {
                        return (
                            <p key={i}>
                                {error}
                            </p>
                        )
                    })}
                    <DialogFooter>
                        <div className={`${styles.center}`} >
                            <DefaultButton onClick={this.closeDialog.bind(this)} text="OK" />
                        </div>
                    </DialogFooter>
                </Dialog>
            </Authenticator>
        );
    }

    private loadData() {
        this.loadLine()
            .then(() => this.loadLocations());
    }

    private async loadLine() {
        var response = await LineData.retrieveById(this.state.lineId);

        if (response.status === 200) {
            this.setState({ productLine: response.data });
        }
        else {
            console.log(response.data);
            console.log("loadline error");
            console.log(response);
        }
    }

    private async loadLocations() {
        var response = await ProblemLocationData.retrieveById(this.state.lineId);

        if (response.status === 200) {
            this.setLocations(response.data.filter(x => x.enabled === true));
        }
        else {
            console.log(response.data);
        }
    }

    private setLocations(locations: IProblemLocation[]) {
        var line = this.state.productLine;
        if (!line) return;

        line.problemLocations = locations;
        this.setState({ productLine: line });
    }

    private createReport(report: IReport, isValid: boolean, errors: string[], files?: FileContent[]) {

        if (this.state.saving) return;
        this.setState({saving: true});

        if (isValid) {
            this.sanitizeReport(report)
            ReportData.create(report)
                .then((response) => this.handleResponse(response, files));
        }
        else {
            this.showErrors(errors);
        }
    }
    private sanitizeReport(report: IReport): void {
        for (let [key, value] of Object.entries(report)) {
            if (typeof(value) === 'string') {
                // @ts-ignore
                report[key] = this.sanitizeStr(value as string)
            }
        }
    }
    private sanitizeStr(input: string): string {
        const regex = /[\t\n]/ig
        return input.replace(regex, "").trim();
    }
    private handleResponse(response: AxiosResponse<any>, files?: FileContent[]) {
        var success = (response.status === 200);

        if (success) {
            this.saveSuccess(response.data.id, files);
        }
        else {
            this.saveFailure(response.data);
        }
    }

    saveSuccess(id: number, files?: FileContent[]) {
        if(files && files.length > 0) {
            this.uploadFiles(id, files);
        }
        else {
            this.goToReport(id);
        }
    }

    uploadFiles(id: number, files: FileContent[]) {
        var dialogProps = {
            type: DialogType.normal,
            title: 'Saving',
            subText: 'The complaint pictures are currently uploading. You will be redirected to the confirmation page once the upload is complete.',
        };

        this.setState({
            errors: [],
            hideDialog: false,
            dialogProps: dialogProps,
        });

        FileData.upload(id, files)
            .then(() => this.goToReport(id));
    }

    saveFailure(response: AxiosResponse<any>) {
        this.setState({
            errors: [],
            saving: false,
            hideDialog: false,
            dialogProps: {
                type: DialogType.normal,
                title: 'An error occurred while creating your report',
                subText: response.data,
            },
        });
    }

    goToReport(id: number) {
        window.location.href = `/summary/${id}?survey`;
    }

    private showErrors(errors: string[]) {
        var dialogProps = {
            type: DialogType.normal,
            title: 'Unable to submit report',
            subText: 'The following required fields do not have a valid answer',
        };

        this.setState({
            errors: errors,
            saving: false,
            hideDialog: false,
            dialogProps: dialogProps,
        });
    }

    private closeDialog() {
        this.setState({
            hideDialog: true
        });
    }
}
