import React from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../authProvider/AuthContext";
import { IAuthenticatorProps } from "./IAuthenticatorProps";
import { AuthenticationStatusEnum } from "../../data/enums/AuthenticationStatusEnum";
import { UserRoleEnum } from "../../data/enums/UserRoleEnum";

export class Authenticator extends React.Component<IAuthenticatorProps, {}> {
    static contextType = AuthContext;

    componentDidMount() {
        if (this.props.onAuthenticated) {
            this.context.onAuthenticated(this.props.onAuthenticated);
        }
    }

    public render() {

        var authStatus = this.context.authenticationStatus;
        var role: UserRoleEnum = this.context.user.role;

        if (authStatus === AuthenticationStatusEnum.LoggedIn && this.props.redirectIfLoggedIn) {
            return <Redirect to='/' />
        }

        if (authStatus === AuthenticationStatusEnum.LoggedOut && this.props.redirectIfLoggedOut) {
            return <Redirect to='/login' />
        }

        if (authStatus === AuthenticationStatusEnum.Unknown) {
            return <></>
        }

        if (this.props.adminOnly && role !== UserRoleEnum.Administrator) {
            return <Redirect to='/' />
        }

        else return this.props.children;
    }
}