import React from "react";
import styles from "../../../scss/components/Form.module.scss";
import {
  dropdownStyles,
  editFormAddIconStyles,
  labelStyles,
  textBoxStyles,
} from "../../../scss/ControlStyles";
import {
  Dropdown,
  IDropdownOption,
  Label,
  MaskedTextField,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { IProductDetailFormProps } from "./ProductDetailsFormProps";
import { IReport } from "../../../data/models/Report";
import { TextInput } from "../../inputs/TextInput";
import { ProductDetailsFormValidator } from "../../../data/validation/productDetailsFormValidator";
import { IValidateResult } from "../../../data/validation/validateResult";
import { DateSelect } from "../dateSelect/DateSelect";
import { IProduct } from "../../../data/models/Product";
import { FileContent } from "use-file-picker/dist/interfaces";
import { Icon } from "@fluentui/react/lib/Icon";
import { FilterForm } from "../filterForm/FilterForm";
import { SortHelper } from "../../../data/helpers/sortHelper";

interface IState {
  incidentDate?: Date;
  product?: IDropdownOption;
  lotNumber: string;
  rbcLabelId: string;
  confirmLotNumber: string;
  numIncidents: string;
  serialNumber: string;
  softwareVersion: string;
  productDescription: string;
  wbProcessed: string;
  volumeOfPlasmaCollected: string;
  donorBleedNumber: string;
  pasLotNumber: string;
  batchTagInfoTime: string;
  batchTagNumber: string;
  lineNumber: string;
  videoJetNumber: string;
  filterNumber: string;

  dateError: boolean;
  productError: boolean;
  lotNumberError: boolean;
  rbcLabelIdError: boolean;
  confirmLotNumberError: boolean;
  filterNumberError: boolean;
  numberOfIncidentsError: boolean;
  serialNumberError: boolean;
  softwareVersionError: boolean;
  productDescriptionError: boolean;
  wbProcessedError: boolean;
  volPlasmaError: boolean;
  donorBleedNumberError: boolean;
  pasLotNumberError: boolean;
  batchTagInfoTimeError: boolean;
  batchTagNumberError: boolean;
  lineNumberError: boolean;
  videoJetNumberError: boolean;
}

export class ProductDetailsForm extends React.Component<
  IProductDetailFormProps,
  IState
> {
  constructor(props: IProductDetailFormProps) {
    super(props);

    this.state = {
      incidentDate: undefined,
      lotNumber: "",
      rbcLabelId: "",
      confirmLotNumber: "",
      numIncidents: "1",
      serialNumber: "",
      softwareVersion: "",
      productDescription: "",
      wbProcessed: "",
      volumeOfPlasmaCollected: "",
      donorBleedNumber: "",
      pasLotNumber: "",
      batchTagInfoTime: "",
      batchTagNumber: "",
      lineNumber: "",
      videoJetNumber: "",
      filterNumber: "",

      dateError: false,
      productError: false,
      lotNumberError: false,
      rbcLabelIdError: false,
      confirmLotNumberError: false,
      filterNumberError: false,
      serialNumberError: false,
      numberOfIncidentsError: false,
      softwareVersionError: false,
      productDescriptionError: false,
      wbProcessedError: false,
      volPlasmaError: false,
      donorBleedNumberError: false,
      pasLotNumberError: false,
      batchTagInfoTimeError: false,
      batchTagNumberError: false,
      lineNumberError: false,
      videoJetNumberError: false,
    };
  }

  render() {
    var batchTagTimeLabel = <span>Batch Tag Time (hh:mm:ss)</span>;
    var batchTagTimeRequired = false;

    if (this.props.line) {
      batchTagTimeRequired = this.props.line.batchTagTimeRequired === true;

      if (
        this.props.line.batchTagTimeAltText &&
        this.props.line.batchTagTimeAltText.length > 0
      ) {
        batchTagTimeLabel = (
          <span>{this.props.line.batchTagTimeAltText} (hh:mm:ss)</span>
        );
      }
    }

    if (batchTagTimeRequired)
      batchTagTimeLabel = (
        <span>
          <span className={styles.red}>*</span> {batchTagTimeLabel}
        </span>
      );

    return (
      <FilterForm
        line={this.props.line}
        createReport={this.createReport.bind(this)}
        product={this.state.product?.data}
        account={this.props.account}
      >
        {this.props.children}

        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <h3>Product Details:</h3>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div
                  className={`${styles.labelBox} ${
                    this.state.dateError ? styles.red : ""
                  }`}
                >
                  <Label styles={labelStyles} htmlFor="date">
                    <span className={styles.red}>*</span> Incident Date:
                  </Label>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                <DateSelect
                  value={this.state.incidentDate}
                  onSelectDate={this.incidentDateChanged.bind(this)}
                />
              </div>
            </div>
            {/* Product Code */}
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div>
                  <div
                    className={`${styles.labelBox} ${
                      this.state.productError ? styles.red : ""
                    }`}
                  >
                    <Label styles={labelStyles} htmlFor="product">
                      <span className={styles.red}>*</span>{" "}
                      {this.getProductCodeText()}:
                    </Label>
                  </div>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                {this.renderProductField(this.props.products)}
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg6} ${styles.lgOffset5}`}
              >
                <TooltipHost
                  className={`${styles.table}`}
                  content="If you need a product code added to your account, please click here to email mdcomplaintsupport@fresenius-kabi.com and provide your account number and the product code you need added."
                >
                  <a
                    href="mailto:mdcomplaintsupport@fresenius-kabi.com"
                    className={`${styles.table}`}
                  >
                    <Icon iconName="Info" styles={editFormAddIconStyles} />
                    &nbsp;
                    <span className={`${styles.cell}`}>Add a Product Code</span>
                  </a>
                </TooltipHost>
              </div>
            </div>

            <TextInput
              show
              required
              value={this.state.lotNumber}
              error={this.state.lotNumberError}
              defaultLabel={"Lot Number"}
              getStateUpdate={(val: string) => {
                return { lotNumber: val.replace(/[\W_]/ig, "") };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              show
              required
              noPaste={true}
              value={this.state.confirmLotNumber}
              error={this.state.confirmLotNumberError}
              defaultLabel={"Re-enter Lot Number"}
              getStateUpdate={(val: string) => {
                return { confirmLotNumber: val.replace(/[\W_]/ig, "") };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.rbcLabelId}
              error={this.state.rbcLabelIdError}
              show={this.props.line ? this.props.line.showRbcLabelId : false}
              required={
                this.props.line ? this.props.line.rbcLabelIdRequired : false
              }
              defaultLabel={"Red Cell Container Label Identifier (Lower right corner – A or B)"}
              alternativeLabel={
                this.props.line ? this.props.line.rbcLabelIdAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { rbcLabelId: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              show
              required
              error={this.state.numberOfIncidentsError}
              value={this.state.numIncidents}
              defaultLabel={"Number of Incidents"}
              getStateUpdate={(val: string) => {
                return { numIncidents: val.match(/\d+/g) };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.serialNumber}
              error={this.state.serialNumberError}
              show={this.props.line ? this.props.line.showSerialNumber : false}
              required={
                this.props.line ? this.props.line.serialNumberRequired : false
              }
              defaultLabel={"Instrument Serial Number"}
              alternativeLabel={
                this.props.line ? this.props.line.serialNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { serialNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.softwareVersion}
              error={this.state.softwareVersionError}
              show={
                this.props.line ? this.props.line.showSoftwareVersion : false
              }
              required={
                this.props.line
                  ? this.props.line.softwareVersionRequired
                  : false
              }
              defaultLabel={"Software Version"}
              alternativeLabel={
                this.props.line ? this.props.line.softwareVersionAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { softwareVersion: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.productDescription}
              error={this.state.productDescriptionError}
              show={
                this.props.line ? this.props.line.showProductDescription : false
              }
              required={
                this.props.line
                  ? this.props.line.productDescriptionRequired
                  : false
              }
              defaultLabel={"Product Collected (ml)"}
              alternativeLabel={
                this.props.line ? this.props.line.productDescriptionAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { productDescription: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.wbProcessed}
              error={this.state.wbProcessedError}
              show={this.props.line ? this.props.line.showWbProcessed : false}
              required={
                this.props.line ? this.props.line.wbProcessedRequired : false
              }
              defaultLabel={"WB Processed (ml)"}
              alternativeLabel={
                this.props.line ? this.props.line.wbProcessedAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { wbProcessed: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.volumeOfPlasmaCollected}
              error={this.state.volPlasmaError}
              show={this.props.line ? this.props.line.showVolPlasma : false}
              required={
                this.props.line ? this.props.line.volPlasmaRequired : false
              }
              defaultLabel={"Volume of Plasma Collected (ml)"}
              alternativeLabel={
                this.props.line ? this.props.line.volPlasmaAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { volumeOfPlasmaCollected: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.donorBleedNumber}
              error={this.state.donorBleedNumberError}
              show={
                this.props.line ? this.props.line.showDonorBleedNumber : false
              }
              required={
                this.props.line
                  ? this.props.line.donorBleedNumberRequired
                  : false
              }
              defaultLabel={"Donor Bleed Number"}
              alternativeLabel={
                this.props.line ? this.props.line.donorBleedNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { donorBleedNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.pasLotNumber}
              error={this.state.pasLotNumberError}
              show={this.props.line ? this.props.line.showPasLotNumber : false}
              required={
                this.props.line ? this.props.line.pasLotNumberRequired : false
              }
              defaultLabel={"PAS Lot Number"}
              alternativeLabel={
                this.props.line ? this.props.line.pasLotNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { pasLotNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <div
              className={`${styles.row}`}
              hidden={!this.props.line?.showBatchTagTime}
            >
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div
                  className={`${styles.labelBox} ${
                    this.state.batchTagInfoTimeError ? styles.red : ""
                  }`}
                >
                  <Label styles={labelStyles}>{batchTagTimeLabel}:</Label>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                <MaskedTextField
                  value={this.state.batchTagInfoTime}
                  mask={"99:99:99"}
                  styles={textBoxStyles}
                  onChange={this.batchTagTimeChanged.bind(this)}
                />
              </div>
            </div>

            <TextInput
              value={this.state.lineNumber}
              error={this.state.lineNumberError}
              show={this.props.line ? this.props.line.showLineNumber : false}
              required={
                this.props.line ? this.props.line.lineNumberRequired : false
              }
              defaultLabel={"Line Number"}
              alternativeLabel={
                this.props.line ? this.props.line.lineNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { lineNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.batchTagNumber}
              error={this.state.batchTagNumberError}
              show={
                this.props.line ? this.props.line.showBatchTagNumber : false
              }
              required={
                this.props.line ? this.props.line.batchTagNumberRequired : false
              }
              defaultLabel={"Batch Tag Number"}
              alternativeLabel={
                this.props.line ? this.props.line.batchTagNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { batchTagNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.videoJetNumber}
              error={this.state.videoJetNumberError}
              show={
                this.props.line ? this.props.line.showVideoJetNumber : false
              }
              required={
                this.props.line ? this.props.line.videoJetNumberRequired : false
              }
              defaultLabel={"Video Jet Number"}
              alternativeLabel={
                this.props.line ? this.props.line.videoJetNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { videoJetNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.filterNumber}
              error={this.state.filterNumberError}
              show={this.props.line ? this.props.line.showFilterNumber : false}
              required={
                this.props.line ? this.props.line.filterNumberRequired : false
              }
              defaultLabel={"Filter Number"}
              alternativeLabel={
                this.props.line ? this.props.line.filterNumberAltText : ""
              }
              getStateUpdate={(val: string) => {
                return { filterNumber: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <div
              className={`${styles.row}`}
              hidden={!this.props.line?.showFilterNumber}
            >
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <span className={`${styles.aside}`}>
                  Number may be found on the filter skirt below inlet (branded)
                  side of filter.
                </span>
              </div>
            </div>
          </div>
        </div>
      </FilterForm>
    );
  }

  renderProductField(products: IDropdownOption[]) {
    var enabledProducts = [];

    for (var p of products) {
      if (p.data.enabled) enabledProducts.push(p);
    }

    if (enabledProducts && enabledProducts.length === 1) {
      return this.renderSingleProduct(products);
    } else if (enabledProducts && enabledProducts.length > 1) {
      return this.renderMultipleProducts(products);
    } else {
      return this.renderNoProductsFound();
    }
  }

  renderSingleProduct(products: IDropdownOption[]) {
    if (!this.state.product || this.state.product.index !== products[0].index) {
      this.setState({ product: products[0] });
    }

    return (
      <TextField
        id="product"
        styles={textBoxStyles}
        value={products[0].text}
        disabled
      />
    );
  }

  renderMultipleProducts(products: IDropdownOption[]) {
    return (
      <Dropdown
        id="product"
        styles={dropdownStyles}
        options={products.sort((p1, p2) => SortHelper.sortProduct(p1.data, p2.data))}
        selectedKey={this.state.product?.key}
        onChange={this.productChanged.bind(this)}
      />
    );
  }

  renderNoProductsFound() {
    return (
      <TextField
        id="product"
        styles={textBoxStyles}
        value={"No available products. Please select a suitable account"}
        disabled
      />
    );
  }

  getProductCodeText() {
    if (
      this.props.line &&
      this.props.line.productCodeAltText &&
      this.props.line.productCodeAltText.length > 0
    ) {
      return this.props.line.productCodeAltText;
    }

    return "Product Code";
  }

  incidentDateChanged(date: Date | null | undefined) {
    if (date) this.setState({ incidentDate: date });
  }

  productChanged(
    ev: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ) {
    if (!option || !option.index) return;
    this.setState({ product: option });
  }

  batchTagTimeChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    if (!val) val = "";
    this.setState({ batchTagInfoTime: val });
  }

  updateState(update: any) {
    this.setState(update);
  }

  private createReport(
    report: IReport,
    isValid: boolean,
    errors: string[],
    files?: FileContent[]
  ) {
    report.incidentDate = this.state.incidentDate;
    report.lotNumber = this.state.lotNumber;
    report.rbcLabelId = this.state.rbcLabelId;
    report.serialNumber = this.state.serialNumber;
    report.softwareVersion = this.state.softwareVersion;
    report.productDescription = this.state.productDescription;
    report.wbProcessed = this.state.wbProcessed;
    report.volumeOfPlasmaCollected = this.state.volumeOfPlasmaCollected;
    report.donorBleedNumber = this.state.donorBleedNumber;
    report.pasLotNumber = this.state.pasLotNumber;
    report.batchTagInfoTime = this.state.batchTagInfoTime;
    report.batchTagNumber = this.state.batchTagNumber;
    report.lineNumber = this.state.lineNumber;
    report.videoJetNumber = this.state.videoJetNumber;
    report.filterNumber = this.state.filterNumber;
    report.numIncidents = this.state.numIncidents
      ? parseInt(this.state.numIncidents)
      : 0;

    var product: IProduct = this.state.product?.data;

    if (product && product.id) {
      report.productId = product.id;
    }

    isValid = this.validate(report, errors) && isValid;
    this.props.createReport(report, isValid, errors, files);
  }

  validate(report: IReport, errors: string[]): boolean {
    var validator = new ProductDetailsFormValidator(
      report,
      errors,
      this.props.line
    );

    var validateDateResult = validator.validateIncidentDate((err) => {
      return { dateError: err };
    });
    var validateProductResult = validator.validateProduct((err) => {
      return { productError: err };
    });
    var validateLotNumberResult = validator.validateLotNumber((err) => {
      return { lotNumberError: err };
    });
    var validateConfirmLotNumberResult = validator.validateConfirmLotNumber(
      (err) => {
        return { confirmLotNumberError: err };
      },
      this.state.confirmLotNumber
    );
    var validateRbcLabelIdResult = validator.validateRbcLabelId((err) => {
      return { serialNumberError: err };
    });
    var validateSerialNumberResult = validator.validateSerialNumber((err) => {
      return { serialNumberError: err };
    });
    var validateSerialNumberResult = validator.validateNumberOfIncidents(
      (err) => {
        return { numberOfIncidentsError: err };
      }
    );
    var validateSoftwareVersionResult = validator.validateSoftwareVersion(
      (err) => {
        return { softwareVersionError: err };
      }
    );
    var validateProductDescriptionResult = validator.validateProductDescription(
      (err) => {
        return { productDescriptionError: err };
      }
    );
    var validateWbProcessedResult = validator.validateWbProcessed((err) => {
      return { wbProcessedError: err };
    });
    var validateVolPlasmaResult = validator.validateVolPlasma((err) => {
      return { volPlasmaError: err };
    });
    var validateDonorBleedNumberResult = validator.validateDonorBleedNumber(
      (err) => {
        return { donorBleedNumberError: err };
      }
    );
    var validatePasLotNumberResult = validator.validatePasLotNumber((err) => {
      return { pasLotNumberError: err };
    });
    var validateBatchTagInfoTimeResult = validator.validateBatchTagInfoTime(
      (err) => {
        return { batchTagInfoTimeError: err };
      }
    );
    var validateBatchTagNumberResult = validator.validateBatchTagNumber(
      (err) => {
        return { batchTagNumberError: err };
      }
    );
    var validateLineNumberResult = validator.validateLineNumber((err) => {
      return { lineNumberError: err };
    });
    var validateVideoJetNumberResult = validator.validateVideoJetNumber(
      (err) => {
        return { videoJetNumberError: err };
      }
    );
    var validateFilterNumberResult = validator.validateFilterNumber((err) => {
      return { filterNumberError: err };
    });

    var isValid = this.setValidation(validateDateResult);
    isValid = this.setValidation(validateProductResult) && isValid;
    isValid = this.setValidation(validateLotNumberResult) && isValid;
    isValid = this.setValidation(validateRbcLabelIdResult) && isValid;
    isValid = this.setValidation(validateConfirmLotNumberResult) && isValid;
    isValid = this.setValidation(validateSerialNumberResult) && isValid;
    isValid = this.setValidation(validateSoftwareVersionResult) && isValid;
    isValid = this.setValidation(validateProductDescriptionResult) && isValid;
    isValid = this.setValidation(validateWbProcessedResult) && isValid;
    isValid = this.setValidation(validateVolPlasmaResult) && isValid;
    isValid = this.setValidation(validateDonorBleedNumberResult) && isValid;
    isValid = this.setValidation(validatePasLotNumberResult) && isValid;
    isValid = this.setValidation(validateBatchTagInfoTimeResult) && isValid;
    isValid = this.setValidation(validateBatchTagNumberResult) && isValid;
    isValid = this.setValidation(validateLineNumberResult) && isValid;
    isValid = this.setValidation(validateVideoJetNumberResult) && isValid;
    isValid = this.setValidation(validateFilterNumberResult) && isValid;

    report.incidentDate = this.state.incidentDate;
    report.lotNumber = this.state.lotNumber?.toUpperCase();
    report.rbcLabelId = this.state.rbcLabelId;
    report.serialNumber = this.state.serialNumber;
    report.softwareVersion = this.state.softwareVersion;
    report.productDescription = this.state.productDescription;
    report.wbProcessed = this.state.wbProcessed;
    report.volumeOfPlasmaCollected = this.state.volumeOfPlasmaCollected;
    report.donorBleedNumber = this.state.donorBleedNumber;
    report.pasLotNumber = this.state.pasLotNumber;
    report.batchTagInfoTime = this.state.batchTagInfoTime;
    report.batchTagNumber = this.state.batchTagNumber;
    report.lineNumber = this.state.lineNumber;
    report.videoJetNumber = this.state.videoJetNumber;
    report.filterNumber = this.state.filterNumber;
    report.numIncidents = this.state.numIncidents
      ? parseInt(this.state.numIncidents)
      : 0;
    return isValid;
  }

  setValidation(validateResult: IValidateResult) {
    this.setState(validateResult.state);
    return validateResult.isValid;
  }
}
