import React from "react";
import styles from '../../../scss/Table.module.scss';

import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { IAccount, Account } from "../../../data/models/Account";
import { AxiosResponse } from "axios";
import { Icon } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { AccountData } from "../../../data/AccountData";
import { AccountRow } from "../../../components/admin/rows/accountRow/AccountRow";
import { EditAccount } from "../../../components/admin/editors/editAccount/EditAccount";
import { IProduct } from "../../../data/models/Product";
import { ProductData } from "../../../data/ProductData";
import { IAdminPageState } from "../IAdminPageState";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { Exporter } from "../../../components/exporter/Exporter";

interface IState extends IAdminPageState<IAccount> {
    products: IProduct[];
}

export class Accounts extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            products: [],
            editId: 0,
            showEdit: false,
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            columns: [
                { title: 'Account', column: 'account' },
                { title: 'Name', column: 'name' },
                { title: 'Address', column: 'address' },
                { title: 'City', column: 'city' },
                { title: 'State', column: 'state' },
                { title: 'Zip', column: 'zip' },
                { title: 'Country', column: 'code' },
                { title: 'Account Type', column: 'accountType' },
                { title: 'Company', column: 'companyCode' },
            ],
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm10}`}>
                                <h1>
                                    Accounts
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.right}`}>
                                <span className={`${styles.link} ${styles.createNew}`} onClick={this.createItem.bind(this)}>
                                    <Icon iconName="CircleAddition" />
                                </span>
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderItems(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <Exporter type='accounts' />
                    </div>

                    <EditAccount
                        id={this.state.editId}
                        items={this.state.items}
                        show={this.state.showEdit}
                        products={this.state.products}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadItems.bind(this, this.state.pageInfo)} />

                </AdminNav>
            </Authenticator>
        );
    }

    private loadData() {
        this.loadItems(this.state.pageInfo);
    }

    loadItems(pageInfo: IPageInfo) {
        AccountData.retrievePage(pageInfo, this.state.columns)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });

                    this.loadProducts();
                }
                else {
                    console.log('error getting accounts');
                    console.log(response.data);
                }
            });
    }

    loadProducts() {
        ProductData.retrieve()
            .then((response: AxiosResponse<IProduct[]>) => {
                if (response.status === 200) {
                    this.setState({
                        products: response.data,
                    });
                }
                else {
                    console.log('error getting products');
                    console.log(response.data);
                }
            });
    }

    renderItems(items: IAccount[]) {
        return items.map((item, i) => {
            return (
                <AccountRow
                    key={i}
                    index={i}
                    item={item}
                    onEdit={this.openEdit.bind(this)} />
            );
        })
    }

    createItem() {
        var newItem = new Account();
        this.openEdit(newItem);
    }

    openEdit(item: IAccount) {
        this.setState({
            showEdit: true,
            editId: item.id ? item.id : 0,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadItems(info);
    }
}