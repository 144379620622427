import React, { FormEvent } from "react";
import styles from "../../../scss/pages/Login.module.scss";

import { AuthData } from "../../../data/AuthData";
import { Copyright } from "../../../components/copyRight/CopyRight";
import { AuthContext } from "../../../components/authProvider/AuthContext";
import { Authenticator } from "../../../components/authenticator/Authenticator";

import {
    blueLinkStyles,
    labelStyles,
    fullWidthButtonStyles,
    textBoxStyles,
} from "../../../scss/ControlStyles";

import { DefaultButton, Label, Link, TextField } from "@fluentui/react";
import { AxiosResponse } from "axios";

interface IState {
    username: string;
    password: string;
    showError: boolean;
    fieldValidation: Map<string, boolean>;
}

export class UserLogin extends React.Component<{}, IState> {
    static contextType = AuthContext;

    constructor(props: {}) {
        super(props);

        var fieldValidation = new Map<string, boolean>([
            ["username", false],
            ["password", false],
        ]);

        this.state = {
            username: "",
            password: "",
            showError: false,
            fieldValidation: fieldValidation,
        };
    }

    render() {
        var reportGuideLink = process.env.REACT_APP_SUBMIT_REPORT_GUIDE_LINK;
        var trainingGuideLink = process.env.REACT_APP_SITE_TRAINING_GUIDE_LINK;

        var hideReportGuide = !reportGuideLink;
        var hideTrainingGuide = !trainingGuideLink;

        return (
            <Authenticator redirectIfLoggedIn={true}>
                <div className={`${styles.login}`}>
                    <div style={{ display: "grid", placeContent: "center" }}>
                        <p style={{
                            fontWeight: "inherit",
                            display: "inline-block",
                            textAlign: "center",
                        }}>
                            If you have any questions, please contact Product Complaint and Support at <a style={{
                                fontWeight: "inherit",
                                display: "inline-block",
                                textAlign: "inherit",
                            }} href="mailto:mdcomplaintsupport@fresenius-kabi.com">mdcomplaintsupport@fresenius-kabi.com</a>.
                        </p>
                    </div>
                    <form id={"login_form"} onSubmit={this.onLogin.bind(this)}>
                        <div className={`${styles.paddedRow}`}>
                            <div
                                className={`${styles.col} ${styles.sm12} ${styles.xl2} ${styles.xlRight}`}
                            >
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles}>Username:</Label>
                                </div>
                            </div>
                            <div
                                className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.loginUsername}`}
                            >
                                <TextField
                                    id={"txt_username"}
                                    styles={textBoxStyles}
                                    validateOnLoad={false}
                                    validateOnFocusIn={false}
                                    validateOnFocusOut={true}
                                    value={this.state.username}
                                    onChange={this.usernameChanged.bind(this)}
                                    onGetErrorMessage={this.validateField.bind(
                                        this,
                                        this.state.username,
                                        "username"
                                    )}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div
                                className={`${styles.col} ${styles.sm12} ${styles.xl2} ${styles.xlRight}`}
                            >
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles} htmlFor="txt_password">
                                        Password:
                                    </Label>
                                </div>
                            </div>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl9}`}>
                                <TextField
                                    type="password"
                                    revealPasswordAriaLabel="Show password"
                                    styles={textBoxStyles}
                                    validateOnLoad={false}
                                    validateOnFocusIn={false}
                                    validateOnFocusOut={true}
                                    value={this.state.password}
                                    onChange={this.passwordChanged.bind(this)}
                                    onGetErrorMessage={this.validateField.bind(
                                        this,
                                        this.state.password,
                                        "password"
                                    )}
                                />
                            </div>
                        </div>

                        <div
                            className={`${styles.paddedRow}`}
                            hidden={!this.state.showError}
                        >
                            <div
                                className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2} ${styles.red}`}
                            >
                                Login Failed
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div
                                className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}
                            >
                                <DefaultButton
                                    type="submit"
                                    text="Log In"
                                    styles={fullWidthButtonStyles}
                                />
                            </div>
                        </div>
                    </form>

                    <div className={`${styles.paddedRow}`}>
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}
                        >
                            <Link href={"/resetpassword"} styles={blueLinkStyles}>
                                Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}
                        >
                            <Link href={"/register"} styles={blueLinkStyles}>
                                Request membership to Product Performance
                            </Link>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2} ${styles.whiteLine}`}
                        ></div>
                    </div>

                    <div
                        className={`${styles.paddedRow}`}
                        hidden={hideReportGuide && hideTrainingGuide}
                    >
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2} ${styles.center}`}
                        >
                            Online Site Training Resources
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`} hidden={hideReportGuide}>
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}
                        >
                            <Link
                                href={reportGuideLink}
                                styles={blueLinkStyles}
                                target={"_blank"}
                            >
                                Printable Quick Guide: How to Submit an Online Performance
                                Report
                            </Link>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`} hidden={hideTrainingGuide}>
                        <div
                            className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}
                        >
                            <Link
                                href={trainingGuideLink}
                                styles={blueLinkStyles}
                                target={"_blank"}
                            >
                                Printable Online Product Performance Reporting Site Training
                                Guide
                            </Link>
                        </div>
                    </div>

                    <Copyright cssName={"login"} />
                </div>
            </Authenticator>
        );
    }

    private usernameChanged(
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val: string | undefined
    ) {
        if (!val) val = "";
        this.setState({ username: val });
    }

    private passwordChanged(
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        val: string | undefined
    ) {
        if (!val) val = "";
        this.setState({ password: val });
    }

    private onLogin(event?: FormEvent) {
        event?.preventDefault();

        var username = this.state.username;
        var password = this.state.password;

        if (event && username.length === 0) {
            // Hackery because browsers insist on autofilling the form!
            var target: any = event.target;
            username = target[0].value;
            password = target[1].value;
        }

        //if (!this.validateForm()) return;

        AuthData.login(username, password).then((response: AxiosResponse<any>) => {
            if (response.status === 200) {
                var authContext = this.context;
                authContext.authenticate();
            } else {
                this.setState({ showError: true });
            }
        });
    }

    private validateForm(): boolean {
        var valid = true;
        for (var val of Array.from(this.state.fieldValidation.values())) {
            valid = valid && val;
        }
        return valid;
    }

    private validateField(val: string, fieldname: string): string {
        var isValid = !!val.length;
        this.updateValidation(fieldname, isValid);
        return isValid ? "" : `Please provide a valid ${fieldname}`;
    }

    private updateValidation(fieldname: string, isValid: boolean) {
        var validation = this.state.fieldValidation;
        validation.set(fieldname, isValid);
        this.setState({ fieldValidation: validation });
    }
}
