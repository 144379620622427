import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IProblemDescription } from "./models/ProblemDescription";

export class ProblemDescriptionData {

    public static async create(item: IProblemDescription): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/problemdescriptions', item);
        return result;
    }

    public static async retrieve(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };

        var result = await DataLayer.post('/admin/problemdescriptions/filter', options);
        return result;
    }

    public static async update(item: IProblemDescription): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/problemdescriptions', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/problemdescriptions/${id}`);
        return result;
    }
}
