import React from "react";
import styles from "../../../../scss/components/Editor.module.scss";

import { IEditorProps } from "./IEditorProps";
import { labelStyles } from "../../../../scss/ControlStyles";
import { IDropdownOption, Label } from "@fluentui/react";
import { Selector } from "../../../selector/selector";
import { IProductLine } from "../../../../data/models/ProductLine";
import { INeedleDropdownOption } from "../../../../data/models/NeedleDropdownOption";
import { EditProblem } from "./EditProblem";
import { IReport } from "../../../../data/models/Report";
import { InputCheck } from "../InputCheck";

export class EditNeedle extends React.Component<IEditorProps, {}> {
  render() {
    return (
      <EditProblem {...this.props}>
        {this.props.children}

        <div className={`${styles.compactRow}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <h3>Needle Issue Details</h3>
          </div>
        </div>
        <InputCheck
          {...this.props}
          label="Not Needle Related"
          value={this.props.item.needleNotRelated}
          update={(report: IReport, val: boolean) =>
            (report.needleNotRelated = val)
          }
        />

        <div className={`${styles.compactRow}`} hidden={this.props.item.needleNotRelated} >
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Needle Issue:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <Selector
              options={this.getNeedleIssues(this.props.productLine)}
              selectedOption={{ key: this.props.item.needleIssueId ?? -1, text: "" }}
              onSelected={this.needleIssueSelected.bind(this)}
            />
          </div>
        </div>
      </EditProblem>
    );
  }
  private getNeedleIssues(productLine?: IProductLine): IDropdownOption[] {
    if (!productLine) return [];
    return productLine.needleDropdownOptions.map(
      (issue: INeedleDropdownOption) => {
        return {
          data: issue,
          key: issue.id !== undefined && issue.id > -1 ? issue.id : -1,
          index: issue.id,
          text: issue.description,
        };
      }
    );
  }

  private needleIssueSelected(option: IDropdownOption) {
    let report = this.props.item;
    let optionData: INeedleDropdownOption = option.data;
    report.needleIssueId =
      optionData.id !== undefined && optionData.id > -1 ? optionData.id : -1;
    report.needleDropdownOption = optionData;
    this.props.onChange(report);
  }
}
