import React from "react";
import styles from '../../scss/components/Form.module.scss';

import { Checkbox, Label } from "@fluentui/react";
import { IFormFunctionProps } from "./IFormFunctionProps";
import { checkboxStyles, labelStyles } from '../../scss/ControlStyles';

export class TrippleCheckResponse {
    public values: string[];
    public selectedValue: number;

    constructor(value1: string, value2: string, value3: string) {
        this.values = [value1, value2, value3];
        this.selectedValue = -1;
    }
}

export function TrippleCheck(props: IFormFunctionProps<TrippleCheckResponse>) {

    const getLabel = () => {
        var label: JSX.Element;

        if (props.alternativeLabel && props.alternativeLabel.length > 0) {
            label = <span>{props.alternativeLabel}</span>;
        }
        else {
            label = <span>{props.defaultLabel}</span>;
        }

        if (props.required) {
            label = (
                <span>
                    <span className={styles.red}>*</span> {label}
                </span>
            )
        }

        return label;
    }

    const showColon = () => {
        var label = props.defaultLabel;

        if (props.alternativeLabel && props.alternativeLabel.length > 0) {
            label = props.alternativeLabel;
        }

        return !label.match(/.*[\?:]$/);
    }

    const firstClicked = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        clickHandler(checked, 0);
    }

    const secondClicked = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        clickHandler(checked, 1);
    }

    const thirdClicked = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        clickHandler(checked, 2);
    }

    const clickHandler = (checked: boolean | undefined, num: number) => {
        var val = props.value;
        val.selectedValue = checked ? num : -1;
        var stateUpdate = props.getStateUpdate(val);
        props.updateValue(stateUpdate);
    }

    if (props.show) {
        return (
            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg5} ${styles.lgOffset1}`}>
                    <div>
                        <div className={`${styles.labelBox} ${props.error ? styles.red : ''}`}>
                            <Label styles={labelStyles} >
                                {getLabel()}{showColon() ? ':' : ''}
                            </Label>
                        </div>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm4} ${styles.lg2}`}>
                    <div className={styles.labelBox}>
                        <Checkbox
                            styles={checkboxStyles}
                            label={props.value.values[0]}
                            checked={props.value.selectedValue === 0}
                            onChange={firstClicked}  />
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm4} ${styles.lg2}`}>
                    <div className={styles.labelBox}>
                        <Checkbox
                            styles={checkboxStyles}
                            label={props.value.values[1]}
                            checked={props.value.selectedValue === 1}
                            onChange={secondClicked}  />
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm4} ${styles.lg1}`}>
                    <div className={styles.labelBox}>
                        <Checkbox
                            styles={checkboxStyles}
                            label={props.value.values[2]}
                            checked={props.value.selectedValue === 2}
                            onChange={thirdClicked}  />
                    </div>
                </div>
            </div>
            
        )
    }
    else {
        return (
            <></>
        )
    }
}