import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { Icon, Label, TextField } from '@fluentui/react';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { AccountProductData } from '../../../../data/AccountProductData';
import { IEditAccountAccountProductProps } from './IEditAccountAccountProductProps';
import { AccountProduct, IAccountProduct } from '../../../../data/models/AccountProduct';
import { EditAccountProduct } from '../editAccountProduct/EditAccountProduct';
import { Account, IAccount } from '../../../../data/models/Account';

import {
    editFormDeleteIconStyles,
    editFormAddIconStyles,
    labelStyles,
    textBoxStyles
} from '../../../../scss/ControlStyles';

interface IState {
    showEditAccountProduct: boolean;
    queryModalProps: QueryModalProps;
    newAccountProduct: IAccountProduct;
}

export class EditAccountAccountProduct extends React.Component<IEditAccountAccountProductProps, IState> {

    constructor(props: IEditAccountAccountProductProps) {
        super(props);

        this.state = {
            showEditAccountProduct: false,
            newAccountProduct: new AccountProduct(),
            queryModalProps: new QueryModalProps(),
        }
    }

    render() {
        var item = this.getItem();

        return (
            <div className={`${styles.row}`} hidden={item.id === 0}>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                    <div className={styles.labelBox}>
                        <Label styles={labelStyles}>Products:</Label>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg8} ${styles.right}`}>
                    {item.products.map((p) => {
                        return (
                            <div className={`${styles.unpaddedRow}`} key={p.id}>
                                <div className={`${styles.col} ${styles.sm12} ${styles.lg1} ${styles.lgRight}`}>
                                    <span
                                        onClick={this.showConfirmDelete.bind(this, p.id ? p.id : 0)}
                                        className={`${styles.link} ${styles.labelBox}`}>
                                        <Icon
                                            styles={editFormDeleteIconStyles}
                                            iconName="Delete"
                                            className={styles.red} />
                                    </span>
                                </div>
                                <div className={`${styles.col} ${styles.sm12} ${styles.lg11}`}>
                                    <TextField
                                        styles={textBoxStyles}
                                        defaultValue={`${p.code} - ${p.description}`}
                                        disabled
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg1} ${styles.right}`}>
                    <span className={`${styles.link} ${styles.labelBox}`} onClick={this.showEditAccountProduct.bind(this)}>
                        <Icon
                            styles={editFormAddIconStyles}
                            iconName="CircleAddition" />
                    </span>
                </div>

                <QueryModal {...this.state.queryModalProps} />

                <EditAccountProduct
                    show={this.state.showEditAccountProduct}
                    accounts={[this.getItem()]}
                    products={this.props.products}
                    close={this.closeEditAccountProduct.bind(this)}
                    refresh={this.props.refresh} />

            </div>
        );
    }

    getItem(): IAccount {
        var items = this.props.accounts.filter((a) => a.id === this.props.id);

        if (items.length > 0) {
            return items[0];
        }
        else {
            return new Account();
        }
    }

    showConfirmDelete(id: number) {
        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete this Account Product?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.deleteAccountProduct(id);

        this.setState({ queryModalProps: queryModalProps });
    }

    closeQueryModal() {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });
    }

    private deleteAccountProduct(id: number) {
        var item = this.getItem();
        var accountId = item.id ? item.id : 0;

        AccountProductData.delete(accountId, id)
            .then(() => {
                this.closeQueryModal();
                this.props.refresh();
            });
    }

    private showEditAccountProduct() {
        var item = this.getItem();
        var accountId = item.id ? item.id : 0;

        var newAccountProduct = new AccountProduct();
        newAccountProduct.accountId = accountId;
        newAccountProduct.account = item;

        this.setState({
            showEditAccountProduct: true,
            newAccountProduct: newAccountProduct,
        });
    }

    closeEditAccountProduct() {
        this.setState({
            showEditAccountProduct: false
        });
    }
}
