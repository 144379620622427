import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';
import { checkboxStyles } from "../../../../scss/ControlStyles";
import { EditProductDetails } from "./EditProductDetails";
import { IEditorProps } from "./IEditorProps";
import { Checkbox } from "@fluentui/react";

export class EditCorrespondence extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditProductDetails
                item={this.props.item}
                onChange={this.props.onChange} >

                {this.props.children}

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm11} ${styles.smOffset1} ${styles.lg9} ${styles.lgOffset3}`}>
                        <div className={styles.labelBox}>
                            <h3>Optional Correspondence Fields</h3>
                        </div>
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm1} ${styles.lg1} ${styles.lgOffset2}`}>
                        <Checkbox
                            styles={checkboxStyles}
                            checked={this.props.item.showCorrespondence}
                            onChange={this.showCorrespondenceChanged.bind(this)}
                        />
                    </div>
                    <div className={`${styles.col} ${styles.sm11} ${styles.lg9}`}>
                        <span>Show Correspondence Section</span>
                    </div>
                </div>
            </EditProductDetails>
        );
    }

    private showCorrespondenceChanged(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        var item = this.props.item;
        item.showCorrespondence = checked ? true : false;
        this.props.onChange(item);
    }
}
