import React from "react";
import styles from "../../../scss/components/Form.module.scss";
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { TrippleCheckResponse, TrippleCheck } from "../../inputs/TrippleCheck";
import { TextInput } from "../../inputs/TextInput";
import { IFilterFormProps } from "./IFilterFormProps";
import { FilterFormValidator } from "../../../data/validation/filterFormValidator";
import { IValidateResult } from "../../../data/validation/validateResult";
import { FileContent } from "use-file-picker/dist/interfaces";
import { NeedleForm } from "../needleForm/needleForm";

interface IState {
  filterNotRelated: boolean;
  filterTemperature: TrippleCheckResponse;
  filterCentrifuge: string;
  filtrationTime: string;
  bloodAmount: string;
  filterFlatFilled: TrippleCheckResponse;
  wbcValue: string;

  filterTemperatureError: boolean;
  filterCentrifugeError: boolean;
  filtrationTimeError: boolean;
  bloodAmountError: boolean;
  filterFlatFilledError: boolean;
  wbcValueError: boolean;
}

export class FilterForm extends React.Component<IFilterFormProps, IState> {
  constructor(props: IFilterFormProps) {
    super(props);

    this.state = {
      filterNotRelated: false,
      filterTemperature: new TrippleCheckResponse(
        "Room Temperature",
        "Cold",
        "N/A"
      ),
      filterCentrifuge: "",
      filtrationTime: "",
      bloodAmount: "",
      filterFlatFilled: new TrippleCheckResponse("Flat", "Filled", "N/A"),
      wbcValue: "",

      filterTemperatureError: false,
      filterCentrifugeError: false,
      filtrationTimeError: false,
      bloodAmountError: false,
      filterFlatFilledError: false,
      wbcValueError: false,
    };
  }
  render() {
    if (!this.props.line?.showFilterIssueDetails) {
      return (
        <NeedleForm
          line={this.props.line}
          product={this.props.product}
          account={this.props.account}
          createReport={this.createReport.bind(this)}
        >
          {this.props.children}
        </NeedleForm>
      );
    }

    return (
      <NeedleForm
        line={this.props.line}
        product={this.props.product}
        account={this.props.account}
        createReport={this.createReport.bind(this)}
      >
        {this.props.children}

        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <h3>Filter Issue Details:</h3>
              </div>
            </div>

            <CenterCheck
              show={true}
              value={this.state.filterNotRelated}
              defaultLabel={
                "If Filter related issues, Please provide the following details below. If NOT Filter related, check the box"
              }
              getStateUpdate={(val: boolean) => {
                return { filterNotRelated: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TrippleCheck
              required
              value={this.state.filterTemperature}
              error={this.state.filterTemperatureError}
              show={!this.state.filterNotRelated}
              defaultLabel={"How were the filters processed?"}
              getStateUpdate={(val: TrippleCheckResponse) => {
                return { filterTemperature: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              required
              showNotApplicable
              value={this.state.filterCentrifuge}
              error={this.state.filterCentrifugeError}
              show={!this.state.filterNotRelated}
              defaultLabel={"Which centrifuge cup/liner was used?"}
              getStateUpdate={(val: string) => {
                return { filterCentrifuge: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              required
              showNotApplicable
              value={this.state.filtrationTime}
              error={this.state.filtrationTimeError}
              show={!this.state.filterNotRelated}
              defaultLabel={"How long was the filtration time?"}
              getStateUpdate={(val: string) => {
                return { filtrationTime: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              required
              showNotApplicable
              value={this.state.bloodAmount}
              error={this.state.bloodAmountError}
              show={!this.state.filterNotRelated}
              defaultLabel={
                "How much blood, relatively speaking, was left in the primary bag at the time of incident?"
              }
              getStateUpdate={(val: string) => {
                return { bloodAmount: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TrippleCheck
              required
              value={this.state.filterFlatFilled}
              error={this.state.filterFlatFilledError}
              show={!this.state.filterNotRelated}
              defaultLabel={
                "Was the filter inlet side flat or filled at the time of the incident?"
              }
              getStateUpdate={(val: TrippleCheckResponse) => {
                return { filterFlatFilled: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              required
              showNotApplicable
              value={this.state.wbcValue}
              error={this.state.wbcValueError}
              show={!this.state.filterNotRelated}
              defaultLabel={"If WBC failure, what was the actual value?"}
              getStateUpdate={(val: string) => {
                return { wbcValue: val };
              }}
              updateValue={this.updateState.bind(this)}
            />
          </div>
        </div>
      </NeedleForm>
    );
  }

  filterNotRelatedChanged = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean | undefined
  ) => {
    var val = checked ? true : false;
    this.setState({ filterNotRelated: val });
  };

  updateState(update: any) {
    this.setState(update);
  }

  private createReport(
    report: IReport,
    isValid: boolean,
    errors: string[],
    files?: FileContent[]
  ) {
    report.filterNotRelated = this.state.filterNotRelated;

    if (!this.state.filterNotRelated) {
      var filterTemp =
        this.state.filterTemperature.values[
          this.state.filterTemperature.selectedValue
        ];
      var filterFlat =
        this.state.filterFlatFilled.values[
          this.state.filterFlatFilled.selectedValue
        ];

      report.filterTemperature = filterTemp;
      report.filterCentrifuge = this.state.filterCentrifuge;
      report.filtrationTime = this.state.filtrationTime;
      report.bloodAmount = this.state.bloodAmount;
      report.filterFlatFilled = filterFlat;
      report.wbcValue = this.state.wbcValue;
    }

    isValid = this.validate(report, errors) && isValid;
    this.props.createReport(report, isValid, errors, files);
  }

  validate(report: IReport, errors: string[]): boolean {
    var validator = new FilterFormValidator(report, errors, this.props.line);

    var validateFilterTemperatureResult = validator.validateFilterTemperature(
      (err) => {
        return { filterTemperatureError: err };
      }
    );
    var validateFilterCentrifugeResult = validator.validateFilterCentrifuge(
      (err) => {
        return { filterCentrifugeError: err };
      }
    );
    var validateFiltrationTimeResult = validator.validateFiltrationTime(
      (err) => {
        return { filtrationTimeError: err };
      }
    );
    var validateBloodAmountResult = validator.validateBloodAmount((err) => {
      return { bloodAmountError: err };
    });
    var validateFilterFlatFilledResult = validator.validateFilterFlatFilled(
      (err) => {
        return { filterFlatFilledError: err };
      }
    );
    var validateWbcValueResult = validator.validateWbcValue((err) => {
      return { wbcValueError: err };
    });

    var isValid = this.setValidation(validateFilterTemperatureResult);
    isValid = this.setValidation(validateFilterCentrifugeResult) && isValid;
    isValid = this.setValidation(validateFiltrationTimeResult) && isValid;
    isValid = this.setValidation(validateBloodAmountResult) && isValid;
    isValid = this.setValidation(validateFilterFlatFilledResult) && isValid;
    isValid = this.setValidation(validateWbcValueResult) && isValid;

    return isValid;
  }

  setValidation(validateResult: IValidateResult) {
    this.setState(validateResult.state);
    return validateResult.isValid;
  }
}
