import { ProductLine } from "./ProductLine";

export interface IProblemActivity {
    id?: number;
    code: string;
    description: string;
    displayOrder: number;
    specify: boolean;
    specifyRequired: boolean;
    enabled: boolean;
    productLineId: number;
    
    productLine?: ProductLine;
}

export class ProblemActivity implements IProblemActivity {
    public id?: number;
    public code: string = '';
    public description: string = '';
    public displayOrder: number = 1;
    public specify: boolean = false;
    public specifyRequired: boolean = false;
    public enabled: boolean = false;
    public productLineId: number = 0;
    
    public productLine?: ProductLine;
}
