import { AxiosResponse } from "axios";
import { DataLayer } from "./DataLayer";
import { IUser } from "./models/User";

export class UserData {

    public async register(user: IUser): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/users', user);
        return result;
    }

    public async checkUsername(username: string): Promise<AxiosResponse<any>> {
        var result = await DataLayer.get(`/users/checkusername/${username}`);
        return result;
    }

    public static async userAccountProducts(userId: number): Promise<AxiosResponse<IUser>> {
        var result = await DataLayer.get<IUser>(`/users/accountproducts/${userId}`);
        return result;
    }

    public static async sendReset(username: string): Promise<AxiosResponse<any>> {
        var result = await DataLayer.get<IUser>(`/users/resetpassword/${username}`);
        return result;
    }

    public static async update(firstname: string, lastname: string, email: string, phone: string): Promise<AxiosResponse<any>> {
        var data = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone
        };
        
        var result = await DataLayer.post(`/users/update`, data);
        return result;
    }
}
