import { IAccount } from "../models/Account";
import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class CorrespondenceFormValidator {

    private report: IReport;
    private errors: string[];
    private account?: IAccount;
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine, account?: IAccount) {
        this.line = line;
        this.errors = errors;
        this.report = report;
        this.account = account;
    }

    validateRecipientEmail(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if ( this.line?.showCorrespondence &&
            !this.report.noResponse &&
            !this.account?.hideCorrespondence ) {

            const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            var isValidEmail = regexp.test(this.report.recipientEmail);
            isValidEmail = isValidEmail || this.report.recipientEmail === 'N/A';

            if(!isValidEmail) {
                this.errors.push('E-mail address of letter recipient');
                result = false;
            }
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
