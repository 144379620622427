import React, { MouseEventHandler } from "react";
import styles from "../../../scss/components/Form.module.scss";
import { IProblemActivity } from "../../../data/models/ProblemActivity";
import { IProblemDescription } from "../../../data/models/ProblemDescription";
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { TextInput } from "../../inputs/TextInput";
import { IProblemDetailsFormProps } from "./IProblemDetailsFormProps";
import { ImageContainer } from "../ImageContainer/ImageContainer";
import { ProductReturnForm } from "../productReturnForm/ProductReturnForm";

import {
  Label,
  Dropdown,
  IDropdownOption,
  TextField,
} from "@fluentui/react";

import {
  dropdownStyles,
  labelStyles,
  textBoxStyles,
} from "../../../scss/ControlStyles";
import { IProblemLocation } from "../../../data/models/ProblemLocation";
import { ProblemFormValidator } from "../../../data/validation/problemFormValidator";
import { IValidateResult } from "../../../data/validation/validateResult";
import { FileContent } from "use-file-picker/dist/interfaces";
import { LocationTypeIdEnum } from "../../../data/enums/LocationTypeEnum";

interface IState {
  problemActivitySpecifics: string;
  problemDescriptionSpecifics: string;
  holeTearCutCauseLeak: boolean;
  leakingWhileAddingPressure: boolean;
  leakLocation: string;
  leakType: string;
  anyAlarms: boolean;
  alarmCodes: string;
  locationUnknown: boolean;
  additionalIncidentDescription: string;
  selectedActivity?: IDropdownOption;
  selectedDescription?: IDropdownOption;
  selectedLocation?: IProblemLocation;
  problemLocationPackaging: boolean;
  problemLocationTubing: boolean;

  problemActivityError: boolean;
  problemActivitySpecificsError: boolean;
  problemDescriptionError: boolean;
  problemDescriptionSpecificsError: boolean;
  leakLocationError: boolean;
  leakTypeError: boolean;
  problemLocationError: boolean;
}
const PACKAGING = "Packaging";
const TUBING = "Tubing";
export class ProblemDetailsForm extends React.Component<
  IProblemDetailsFormProps,
  IState
> {
  constructor(props: IProblemDetailsFormProps) {
    super(props);

    this.state = {
      problemActivitySpecifics: "",
      problemDescriptionSpecifics: "",
      holeTearCutCauseLeak: false,
      leakingWhileAddingPressure: false,
      leakLocation: "",
      leakType: "",
      anyAlarms: false,
      alarmCodes: "",
      locationUnknown: false,
      additionalIncidentDescription: "",
      problemLocationPackaging: false,
      problemLocationTubing: false,
    
      problemActivityError: false,
      problemActivitySpecificsError: false,
      problemDescriptionError: false,
      problemDescriptionSpecificsError: false,
      leakLocationError: false,
      leakTypeError: false,
      problemLocationError: false,
    };
  }

  render() {
    return (
      <ProductReturnForm
        line={this.props.line}
        account={this.props.account}
        createReport={this.createReport.bind(this)}
      >
        {this.props.children}

        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <h3>Problem Details:</h3>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div
                  className={`${styles.labelBox} ${
                    this.state.problemActivityError ? styles.red : ""
                  }`}
                >
                  <Label styles={labelStyles}>
                    <span className={styles.red}>*</span> When was the problem
                    detected?
                  </Label>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                <Dropdown
                  options={this.getActivities(
                    this.props.line?.problemActivities.filter(x => x.enabled === true)
                  )}
                  styles={dropdownStyles}
                  selectedKey={this.state.selectedActivity?.key}
                  onChange={this.activityChanged.bind(this)}
                />
              </div>
            </div>

            <TextInput
              required={this.state.selectedActivity?.data.specifyRequired}
              value={this.state.problemActivitySpecifics}
              show={this.state.selectedActivity?.data.specify}
              defaultLabel={"Please specify value"}
              charLimit={38}
              charLimitMessage={"You have hit the character limit for this field. To provide additional information, please enter your details under the Additional Incident Description field."}
              getStateUpdate={(val: string) => {
                return { problemActivitySpecifics: val };
              }}
              updateValue={this.updateState.bind(this)}
              error={this.state.problemActivitySpecificsError}
            />

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div
                  className={`${styles.labelBox} ${
                    this.state.problemDescriptionError ? styles.red : ""
                  }`}
                >
                  <Label styles={labelStyles}>
                    <span className={styles.red}>*</span> Identify the problem
                    type:
                  </Label>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                <Dropdown
                  options={this.getDescriptions(
                    this.props.line?.problemDescriptions.filter(
                      (x) => x.enabled === true
                    )
                  )}
                  styles={dropdownStyles}
                  selectedKey={this.state.selectedDescription?.key}
                  onChange={this.descriptionChanged.bind(this)}
                />
              </div>
            </div>

            <TextInput
              required
              value={this.state.problemDescriptionSpecifics}
              show={this.state.selectedDescription?.data.specify}
              defaultLabel={"Please specify value"}
              charLimit={38}
              charLimitMessage={"You have hit the character limit for this field. To provide additional information, please enter your details under the Additional Incident Description field."}
              getStateUpdate={(val: string) => {
                return { problemDescriptionSpecifics: val };
              }}
              updateValue={this.updateState.bind(this)}
              error={this.state.problemDescriptionSpecificsError}
            />

            <CenterCheck
              value={this.state.holeTearCutCauseLeak}
              show={this.isHole()}
              defaultLabel={"Did the hole / cut / tear / slice cause a leak?"}
              getStateUpdate={(val: boolean) => {
                return { holeTearCutCauseLeak: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <CenterCheck
              value={this.state.leakingWhileAddingPressure}
              show={this.isHole() || this.isLeak()}
              defaultLabel={
                "Product leaking while adding pressure to the solution bag?"
              }
              getStateUpdate={(val: boolean) => {
                return { leakingWhileAddingPressure: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              required
              value={this.state.leakLocation}
              show={this.isHole() || this.isLeak()}
              defaultLabel={"Where is the location of the leak?"}
              getStateUpdate={(val: string) => {
                return { leakLocation: val };
              }}
              updateValue={this.updateState.bind(this)}
              error={this.state.leakLocationError}
            />

            <TextInput
              required
              value={this.state.leakType}
              show={this.isLeak()}
              defaultLabel={"Describe the type of leak"}
              getStateUpdate={(val: string) => {
                return { leakType: val };
              }}
              updateValue={this.updateState.bind(this)}
              error={this.state.leakTypeError}
            />

            <CenterCheck
              value={this.state.anyAlarms}
              defaultLabel={"Did you experience any alarms?"}
              show={
                this.props.line ? this.props.line.showAlarmsExperienced : false
              }
              alternativeLabel={
                this.props.line ? this.props.line.alarmsExperiencedAltText : ""
              }
              getStateUpdate={(val: boolean) => {
                return { anyAlarms: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              value={this.state.alarmCodes}
              show={this.state.anyAlarms}
              defaultLabel={"Please enter the alarm code(s) here"}
              getStateUpdate={(val: string) => {
                return { alarmCodes: val };
              }}
              updateValue={this.updateState.bind(this)}
              charLimit={38}
              charLimitMessage={"You have hit the character limit for this field. To provide additional information, please enter your details under the Additional Incident Description field."}
            />

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <div>
                  <span className={`${styles.aside}`}>
                    <span className={styles.red}>*</span> Please select the
                    location of the specific component on the diagram where the
                    issue occured. The below diagram allows only ONE component
                    to be selected. If you have multiple components to report,
                    please include the additional components within the
                    Additional Incident Description section.
                  </span>
                </div>
              </div>
            </div>
            <CenterCheck
              show={true}
              value={this.state.locationUnknown}
              defaultLabel={
                "If the location is not known, please check this box"
              }
              getStateUpdate={(val: boolean) => {
                return {
                  locationUnknown: val,
                  problemLocationPackaging: false,
                  problemLocationTubing: false,
                };
              }}
              updateValue={this.updateLocationUnknown.bind(this)}
            />
            <CenterCheck
              show={this.props.line?.problemLocationTubing ?? false}
              value={this.state.problemLocationTubing}
              defaultLabel={
                `If the location is the ${TUBING} itself, please check this box`
              }
              getStateUpdate={(val: boolean) => {
                return {
                  problemLocationTubing: val,
                  locationUnknown: false,
                  problemLocationPackaging: false,
                };
              }}
              updateValue={this.updateProblemLocationTubing.bind(this)}
            />
            <CenterCheck
              show={this.props.line?.problemLocationPackaging ?? false}
              value={this.state.problemLocationPackaging}
              defaultLabel={
                `If the location is the ${PACKAGING} itself, please check this box`
              }
              getStateUpdate={(val: boolean) => {
                return {
                  problemLocationPackaging: val,
                  locationUnknown: false,
                  problemLocationTubing: false,
                };
              }}
              updateValue={this.updateProblemLocationPackaging.bind(this)}
            />

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${
                  styles.lgOffset1
                } ${this.state.problemLocationError ? styles.red : ""}`}
              >
                <p>Selected Location: {this.selectedLocationText()}</p>
              </div>
            </div>

            <ImageContainer
              line={this.props.line}
              selectedLocation={this.state.selectedLocation}
              onLocationSelected={this.updateLocation.bind(this)}
            />

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <div className={styles.labelBox}>
                  <Label styles={labelStyles}>
                    Additional Incident Description:
                  </Label>
                </div>
              </div>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <TextField
                  multiline
                  styles={textBoxStyles}
                  value={this.state.additionalIncidentDescription}
                  onChange={this.additionalDescriptionChanged.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </ProductReturnForm>
    );
  }

  getActivities(activities?: IProblemActivity[]) {
    var options: IDropdownOption[] = [];
    var sortedActivities = activities?.sort((a, b) => {
      if (a.displayOrder > b.displayOrder) return 1;
      else if (a.displayOrder < b.displayOrder) return -1;
      else return 0;
    });

    if (activities) {
      for (var activity of sortedActivities ?? []) {
        options.push({
          key: activity.id ? activity.id : 0,
          index: activity.id,
          text: activity.description,
          data: activity,
        });
      }
    }
    return options;
  }

  getDescriptions(descriptions?: IProblemDescription[]) {
    var options: IDropdownOption[] = [];

    if (descriptions) {
      var sortedDescriptions = descriptions.sort((d1, d2) => {
        if (d1.description < d2.description) {
          return -1;
        } else if (d2.description < d1.description) {
          return 1;
        } else {
          return 0;
        }
      });

      for (var description of sortedDescriptions) {
        options.push({
          key: description.id ? description.id : 0,
          index: description.id,
          text: description.description,
          data: description,
        });
      }
    }

    return options;
  }

  activityChanged(
    ev: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ) {
    this.setState({ selectedActivity: option });
  }

  descriptionChanged(
    ev: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ) {
    this.setState({ selectedDescription: option });
  }

  selectedLocationText() {
    var loc: string | undefined;
    if (this.state.locationUnknown) loc = "Unknown";
    else if (this.state.problemLocationPackaging) loc = PACKAGING;
    else if (this.state.problemLocationTubing) loc = TUBING;
    else loc = this.state.selectedLocation?.description;

    return <span className={styles.red}>{loc}</span>;
  }

  additionalDescriptionChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    if (!val) val = "";
    this.setState({ additionalIncidentDescription: val });
  }

  isLeak(): boolean {
    return (
      (this.props.product ? this.props.product.solution : false) &&
      (this.state.selectedDescription
        ? this.state.selectedDescription?.data.leak
        : false)
    );
  }

  isHole(): boolean {
    return (
      (this.props.product ? this.props.product.solution : false) &&
      (this.state.selectedDescription
        ? this.state.selectedDescription?.data.hole
        : false)
    );
  }

  updateState(update: any) {
    this.setState(update);
  }

  updateLocation(
    location: IProblemLocation
  ): MouseEventHandler<HTMLAnchorElement> | undefined {
    if (!this.state.locationUnknown) {
      this.setState({
        selectedLocation: location,
        problemLocationPackaging: false,
        problemLocationTubing: false,
      });
    }

    return undefined;
  }

  updateLocationUnknown(update: any) {
    update.selectedLocation = undefined;
    this.setState(update);
    return undefined;
  }

  updateProblemLocationPackaging(update: any) {
    update.selectedLocation = undefined;
    this.setState(update);
  }
  updateProblemLocationTubing(update: any) {
    update.selectedLocation = undefined;
    this.setState(update);
    return undefined;
  }

  private createReport(
    report: IReport,
    isValid: boolean,
    errors: string[],
    files?: FileContent[]
  ) {
    report.leakType = this.state.leakType;
    report.anyAlarms = this.state.anyAlarms;
    report.alarmCodes = this.state.alarmCodes;
    report.leakLocation = this.state.leakLocation;
    report.problemDescriptionSpecifics = this.state.problemDescriptionSpecifics;
    report.holeTearCutCauseLeak = this.state.holeTearCutCauseLeak;
    report.problemActivitySpecifics = this.state.problemActivitySpecifics;
    report.leakingWhileAddingPressure = this.state.leakingWhileAddingPressure;
    report.additionalIncidentDescription =
      this.state.additionalIncidentDescription;

    var activity: IProblemActivity = this.state.selectedActivity?.data;

    if (activity && activity.id) {
      report.problemActivityId = activity.id;
    }

    var description: IProblemDescription = this.state.selectedDescription?.data;

    if (description && description.id) {
      report.problemDescriptionId = description.id;
    }

    var location = this.state.selectedLocation;

    if (location && location.id) {
      report.problemLocationId = location.id;
      report.locationType = LocationTypeIdEnum.Known;
    } else if (this.state.problemLocationPackaging) {
      report.locationType = LocationTypeIdEnum.Packaging;
    } else if (this.state.problemLocationTubing) {
      report.locationType = LocationTypeIdEnum.Tubing;
    } else {
      report.locationType = LocationTypeIdEnum.Unknown;
    }
    isValid = this.validate(report, errors) && isValid;
    this.props.createReport(report, isValid, errors, files);
  }

  validate(report: IReport, errors: string[]): boolean {
    var isHole = this.isHole();
    var isLeak = this.isLeak();
    var validator = new ProblemFormValidator(report, errors, this.props.line);

    var validateProblemActivityResult = validator.validateProblemActivity(
      (err) => {
        return { problemActivityError: err };
      },
      this.state.locationUnknown
    );
    var validateProblemActivitySpecificsResult =
      validator.validateProblemActivitySpecifics((err) => {
        return { problemActivitySpecificsError: err };
      }, this.state.selectedActivity?.data);
    var validateProblemDescriptionResult = validator.validateProblemDescription(
      (err) => {
        return { problemDescriptionError: err };
      }
    );
    var validateProblemDescriptionSpecificsResult =
      validator.validateProblemDescriptionSpecifics((err) => {
        return { problemDescriptionSpecificsError: err };
      }, this.state.selectedDescription?.data);
    var validateLeakLocationResult = validator.validateLeakLocation((err) => {
      return { leakLocationError: err };
    }, isHole || isLeak);
    var validateLeakTypeResult = validator.validateLeakType((err) => {
      return { leakTypeError: err };
    }, isLeak);
    var validateProblemLocationResult = validator.validateProblemLocation(
      (err) => {
        return { problemLocationError: err };
      },
      this.state.locationUnknown ||
        this.state.problemLocationPackaging ||
        this.state.problemLocationTubing
    );

    var isValid = this.setValidation(validateProblemActivityResult);
    isValid =
      this.setValidation(validateProblemActivitySpecificsResult) && isValid;
    isValid = this.setValidation(validateProblemDescriptionResult) && isValid;
    isValid =
      this.setValidation(validateProblemDescriptionSpecificsResult) && isValid;
    isValid = this.setValidation(validateLeakLocationResult) && isValid;
    isValid = this.setValidation(validateLeakTypeResult) && isValid;
    isValid = this.setValidation(validateProblemLocationResult) && isValid;

    return isValid;
  }

  setValidation(validateResult: IValidateResult) {
    this.setState(validateResult.state);
    return validateResult.isValid;
  }
}
