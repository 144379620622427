import React from "react";
import styles from "../../scss/Base.module.scss";

import { DEVICE_TYPE_DROPDOWNS } from "../../components/admin/editors/editProductLine/EditProductLine";
import { Authenticator } from "../../components/authenticator/Authenticator";
import { DefaultButton, IDropdownOption } from "@fluentui/react";
import { fullWidthButtonStyles } from "../../scss/ControlStyles";
import { LineData } from "../../data/LineData";
import { IProductLine } from "../../data/models/ProductLine";
import { AuthContext } from "../../components/authProvider/AuthContext";
import { IUser } from "../../data/models/User";
import { AccountData } from "../../data/AccountData";
import { Selector } from "../../components/selector/selector";
import { ReportSummary } from "../../components/reportSummary/ReportSummary";
import { SortHelper } from "../../data/helpers/sortHelper";

interface IState {
  lines: IProductLine[];
  account?: IDropdownOption;
  accountOptions: IDropdownOption[];
}

export class Home extends React.Component<{}, IState> {
  static contextType = AuthContext;

  constructor(props: {}) {
    super(props);

    this.state = {
      lines: [],
      accountOptions: [],
    };
  }

  loadData() {
    this.loadLines();
    this.loadAccounts();
  }

  render() {
    return (
      <Authenticator
        redirectIfLoggedOut={true}
        onAuthenticated={this.loadData.bind(this)}
      >
        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm10} ${styles.smOffset1}`}>
            <div className={`${styles.row}`}>
              <div className={`${styles.col} ${styles.sm12}`}>
                <h1>Product Performance for Disposables</h1>
                <h2>Submit a Disposable Performance Report</h2>
              </div>
            </div>
            <div className={`${styles.row}`}>
              <div className={`${styles.col} ${styles.sm12}  ${styles.xl6}`}>
                <p>
                  Select a Product Line to begin a Performance Report
                  Submission:
                </p>
                
                <Devices props={{lines: this.state.lines, renderLines: this.renderLines}} />

                <h2>Product Performance Search:</h2>

                <p>
                  Looking for a recently submitted Performance Report? Use the{" "}
                  <a className={`${styles.link}`} href="/search">
                    {" "}
                    Performance Report Search
                  </a>{" "}
                  to find it.
                </p>
              </div>

              <div className={`${styles.col} ${styles.sm12} ${styles.xl6}`}>
                <p>
                  Fresenius Kabi takes quality seriously. If you need to return
                  a product because of a quality issue, please complete the
                  appropriate product performance report form.
                </p>
                <p>
                  Important: If reaction or injury has occurred, or you need
                  additional assistance, please call Fresenius Kabi Product
                  Complaint and Support immediately.
                </p>
                <p>
                  <strong>In the US call</strong>
                  <br />
                  1.800.933.6925
                </p>
                <p>
                  <strong>In Europe call</strong>
                  <br />
                  +32.0.1048.2833 (English)
                  <br />
                  +32.0.1048.2825 (French)
                  <br />
                  +32.0.1048.2835 (German)
                </p>

                <div className={`${styles.row}`}>
                  <div
                    className={`${styles.col} ${styles.sm12} ${styles.bgdg}`}
                  >
                    <p>
                      If you need to update your location/account number, please
                      email us at:{" "}
                      <a
                        className={`${styles.inline}`}
                        href="mailto:mdcomplaintsupport@fresenius-kabi.com"
                      >
                        mdcomplaintsupport@fresenius-kabi.com
                      </a>
                      .
                    </p>
                    <p>
                      Please provide your username and the updates you require.
                    </p>
                  </div>
                </div>

                <h2>Your Account’s Performance Report Summary:</h2>

                <p>Account:</p>

                <Selector
                  placeholder={"Select An Account"}
                  selectedOption={this.state.account}
                  options={this.state.accountOptions}
                  onSelected={this.setAccount.bind(this)}
                />

                <ReportSummary account={this.state.account} />
              </div>
            </div>
          </div>
        </div>
      </Authenticator>
    );
  }

  private renderLines(lines: IProductLine[]) {
    if (lines.length === 0) {
      return <></>;
    }

    return lines.map((line) => {
      return (
        <div
          className={`${styles.col} ${styles.sm6} ${styles.center}`}
          key={line.id}
        >
          <DefaultButton
            text={line.name}
            styles={fullWidthButtonStyles}
            href={`/report/${line.id}`}
          />
        </div>
      );
    });
  }
  private loadLines() {
    LineData.retrieve().then((response) => {
      if (response.status === 200) {
        var lines = response.data.sort(
          (n1, n2) => n1.displayOrder - n2.displayOrder
        );
        this.setState({ ...this.state, lines: lines });
      }
    });
  }

  private loadAccounts() {
    var user: IUser = this.context.user;

    if (!user.id) return;

    AccountData.getAccountOptions(user.id).then((options) => {
      this.setState({
        accountOptions: options.sort((a1, a2) =>
          SortHelper.sortAccount(a1.data, a2.data)
        ),
      });
    });
  }

  private setAccount(account: IDropdownOption) {
    this.setState({ account: account });
  }
}

interface DevicesProps {
  lines: IProductLine[];
  renderLines(lines: IProductLine[]): JSX.Element | JSX.Element[];
}

const Devices = ({ props }: { props: DevicesProps }) => {
  const { lines, renderLines } = props;
  let headerSuffix = `Device Disposables:`;
  const res = DEVICE_TYPE_DROPDOWNS.map((device) => {
    const header = `${device.text} ${headerSuffix}`;
    return (
      <div className={`${styles.row}`}>
        <div className={`${styles.col} ${styles.sm11} ${styles.bgw}`}>
          <p>
            <strong>{header}</strong>
          </p>
          {renderLines(lines.filter((line) => line.deviceType === device.text))}
        </div>
      </div>
    );
  });
  return <>{res}</>;
};
