import { IProductLine } from "./ProductLine";

export interface IProblemDescription {
    id?: number;
    code: string;
    description: string;
    hole: boolean;
    leak: boolean;
    specify: boolean;
    enabled: boolean;
    productLineId: number;
    productLine?: IProductLine;
}

export class ProblemDescription implements IProblemDescription {
    public id?: number;
    public code: string = '';
    public description: string = '';
    public hole: boolean = false;
    public leak: boolean = false;
    public specify: boolean = false;
    public enabled: boolean = false;
    public productLineId: number = 0;
    public productLine?: IProductLine;
}
