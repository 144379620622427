import React from "react";
import styles from "../../../scss/components/Form.module.scss";
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { FileContent } from "use-file-picker/dist/interfaces";
import { dropdownStyles, labelStyles } from "../../../scss/ControlStyles";
import { Dropdown, IDropdownOption, Label } from "@fluentui/react";
import { IValidateResult } from "../../../data/validation/validateResult";
import { NeedleFormValidator } from "../../../data/validation/needleFormValidator";
import { INeedleFormProps } from "./INeedleFormProps";
import { INeedleDropdownOption } from "../../../data/models/NeedleDropdownOption";
import { ProblemDetailsForm } from "../problemDetailsForm/ProblemDetailsForm";

interface IState {
  needleNotRelated: boolean;
  selectedNeedleDropdownOption?: IDropdownOption;
  needleDropdownOptionSpecifics: string;

  needleDropdownOptionError: boolean;
  needleDropdownOptionSpecificsError: boolean;
}

export class NeedleForm extends React.Component<INeedleFormProps, IState> {
  constructor(props: INeedleFormProps) {
    super(props);

    this.state = {
      needleNotRelated: false,
      needleDropdownOptionSpecifics: "",

      needleDropdownOptionError: false,
      needleDropdownOptionSpecificsError: false,
    };
  }

  render() {
    if (!this.props.line?.showNeedleIssueDetails) {
      return (
        <ProblemDetailsForm
          line={this.props.line}
          product={this.props.product}
          account={this.props.account}
          createReport={this.createReport.bind(this)}
        >
          {this.props.children}
        </ProblemDetailsForm>
      );
    }
    console.log(this.props.line.needleDropdownOptions);
    return (
      <ProblemDetailsForm
        line={this.props.line}
        product={this.props.product}
        account={this.props.account}
        createReport={this.createReport.bind(this)}
      >
        {this.props.children}
        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <h3>Needle Issue Details:</h3>
              </div>
            </div>
            <CenterCheck
              show
              value={this.state.needleNotRelated}
              defaultLabel={
                "If Needle related issues, please provide the following details below. If NOT Needle related, check the box"
              }
              getStateUpdate={(val: boolean) => {
                return { needleNotRelated: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            {/** Dropdown Start */}

            <div
              hidden={this.state.needleNotRelated}
              className={`${styles.row}`}
            >
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}
              >
                <div
                  className={`${styles.labelBox} ${
                    this.state.needleDropdownOptionError ? styles.red : ""
                  }`}
                >
                  <Label styles={labelStyles}>
                    <span className={styles.red}>*</span> When was the condition
                    detected?
                  </Label>
                </div>
              </div>
              <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                <Dropdown
                  options={this.getNeedleDropdownOptions(
                    this.props.line?.needleDropdownOptions
                  )}
                  styles={dropdownStyles}
                  selectedKey={this.state.selectedNeedleDropdownOption?.key}
                  onChange={this.dropdownOptionChanged.bind(this)}
                />
              </div>
            </div>

            {/** Dropdown End */}
          </div>
        </div>
      </ProblemDetailsForm>
    );
  }

  getNeedleDropdownOptions(
    dropdownOptions?: INeedleDropdownOption[]
  ): IDropdownOption[] {
    let options: IDropdownOption[] = [];
    let sortedOptions = dropdownOptions?.sort((a, b) => {
      if (a.displayOrder > b.displayOrder) return 1;
      else if (a.displayOrder < b.displayOrder) return -1;
      else return 0;
    });
    if (dropdownOptions) {
      for (var option of sortedOptions?.filter((a) => a.enabled) ?? []) {
        console.log(options);
        options.push({
          key: option.id ? option.id : 0,
          index: option.displayOrder,
          text: option.description,
          data: option,
        });
      }
    }
    console.log(options);
    return options;
  }

  dropdownOptionChanged(
    ev: React.FormEvent<HTMLDivElement>,
    option: IDropdownOption | undefined
  ) {
    this.setState({ selectedNeedleDropdownOption: option });
  }

  needleNotRelatedChanged = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean | undefined
  ) => {
    var val = checked ? true : false;
    this.setState({ needleNotRelated: val });
  };

  updateState(update: any) {
    this.setState(update);
  }

  private createReport(
    report: IReport,
    isValid: boolean,
    errors: string[],
    files?: FileContent[]
  ) {
    report.needleNotRelated = this.state.needleNotRelated;
    // @ts-ignore
    report.needleDropdownOption = this.state.selectedNeedleDropdownOption?.text;
    let needleIssue: INeedleDropdownOption =
      this.state.selectedNeedleDropdownOption?.data;

    if (needleIssue && needleIssue.id) {
      report.needleIssueId = needleIssue.id;
    }
    isValid = this.validate(report, errors) && isValid;
    this.props.createReport(report, isValid, errors, files);
  }

  validate(report: IReport, errors: string[]): boolean {
    const validator = new NeedleFormValidator(report, errors, this.props.line);

    const validateNeedleDropdownOption = validator.validateNeedleDropdownOption(
      (err) => {
        return { needleDropdownOptionError: err };
      }
    );
    let isValid = this.setValidation(validateNeedleDropdownOption);

    return isValid;
  }

  setValidation(validateResult: IValidateResult) {
    this.setState(validateResult.state);
    return validateResult.isValid;
  }
}
