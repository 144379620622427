import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { DefaultButton, Dropdown, IDropdownOption, Label, Modal, TextField } from '@fluentui/react';
import { dropdownStyles, fullWidthButtonStyles, labelStyles, modalStyles, textBoxStyles } from '../../../../scss/ControlStyles';

import { IUser, User } from '../../../../data/models/User';
import { UserRoleEnum } from '../../../../data/enums/UserRoleEnum';
import { UserStatusEnum } from '../../../../data/enums/UserStatusEnum';
import { AdminData } from '../../../../data/AdminData';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditState } from '../IEditState';
import { EditUserUserAccount } from './EditUserUserAccount';
import { IEditUserProps } from './IEditUserProps';
import { DateHelper } from '../../../../data/helpers/dateHelper';

export class EditUser extends React.Component<IEditUserProps, IEditState<IUser>> {

    constructor(props: IEditUserProps) {
        super(props);

        var user = this.cloneItem();

        this.state = {
            item: user,
            show: this.props.show,
            queryModalProps: new QueryModalProps(),
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();
            this.setState({
                item: newItem,
                show: this.props.show
            });
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>{!this.state.item.id ? 'New' : 'Edit'}  User</h2>
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.state.item.id}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>ID:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            defaultValue={this.state.item.id?.toString()}
                            disabled
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>User Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.username}
                            disabled={!!this.state.item.id}
                            onChange={this.usernameChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.state.item.id}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Created:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={DateHelper.formatDate(this.state.item.createdAt)}
                            disabled
                        />
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.state.item.id}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Last Login:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={DateHelper.formatDate(this.state.item.lastLogin)}
                            disabled
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>First Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.firstname}
                            onChange={this.firstnameChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Last Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.lastname}
                            onChange={this.lastnameChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Email:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.email}
                            onChange={this.emailChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Phone:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.phone}
                            onChange={this.phoneChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Work Phone:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.workPhone}
                            onChange={this.workPhoneChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Fax:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.fax}
                            onChange={this.faxChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Address:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.address}
                            onChange={this.addressChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account #:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.accountNumber}
                            onChange={this.accountNumberChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Status:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Dropdown
                            selectedKey={this.state.item.status}
                            options={this.getOptions(UserStatusEnum)}
                            styles={dropdownStyles}
                            onChange={this.onStatusChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Role:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Dropdown
                            selectedKey={this.state.item.role}
                            options={this.getOptions(UserRoleEnum)}
                            styles={dropdownStyles}
                            onChange={this.onRoleChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h4 style={{color: "red"}}>A value of zero or less months means that the user is either exempted from locks or disables due to inactivity, respectively.</h4>
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Inactivity Lock Account (months)</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.ThresholdTime1Months.toString()}
                            onChange={this.userMonthsInactiveWarning.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Inactivity Disable Account (months)</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.ThresholdTime2Months.toString()}
                            onChange={this.userMonthsInactiveLock.bind(this)}
                        />
                    </div>
                </div>

                <div hidden={!this.state.item.id}>
                    <EditUserUserAccount
                        id={this.props.id}
                        users={this.props.items}
                        accounts={this.props.accounts}
                        refresh={this.props.refresh} />
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg3}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.saveUser.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3}`} hidden={!this.state.item.id}>
                        <DefaultButton
                            text='CHANGE PASSWORD'
                            styles={fullWidthButtonStyles}
                            onClick={this.showChangePassword.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3}`} hidden={!this.state.item.id}>
                        <DefaultButton
                            text='DELETE'
                            styles={fullWidthButtonStyles}
                            onClick={this.showConfirmDelete.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg3}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

                <QueryModal {...this.state.queryModalProps} />

            </Modal >
        );
    }

    private cloneItem(): IUser {
        var clone = new User();

        if (this.props.items && this.props.id > 0 && this.props.items.length > 0) {
            var item = this.props.items.filter((item) => item.id === this.props.id);
            Object.assign(clone, item[0]);
        }

        return clone;
    }

    private getOptions(e: any): IDropdownOption[] {
        return Object.keys(e).map((key) => {
            return { key: key, text: key, data: key };
        });
    }

    private usernameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.username = val ? val : '';
        this.setState({ item: user });
    }

    private firstnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.firstname = val ? val : '';
        this.setState({ item: user });
    }

    private lastnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.lastname = val ? val : '';
        this.setState({ item: user });
    }

    private emailChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.email = val ? val : '';
        this.setState({ item: user });
    }

    private phoneChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.phone = val ? val : '';
        this.setState({ item: user });
    }

    private workPhoneChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.workPhone = val ? val : '';
        this.setState({ item: user });
    }

    private faxChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.fax = val ? val : '';
        this.setState({ item: user });
    }

    private addressChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.address = val ? val : '';
        this.setState({ item: user });
    }

    private accountNumberChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.accountNumber = val ? val : '';
        this.setState({ item: user });
    }

    private onStatusChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
        if (!option) return;
        var user = this.state.item;
        user.status = option.data;
        this.setState({ item: user });
    }

    private onRoleChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
        if (!option) return;
        var user = this.state.item;
        user.role = option.data;
        this.setState({ item: user });
    }

    private userMonthsInactiveWarning(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        const user = this.state.item;
        user.ThresholdTime1Months = val ? parseInt(val ?? "0") : 0;
        this.setState({ item: user });
    }

    private userMonthsInactiveLock(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var user = this.state.item;
        user.ThresholdTime2Months = val ? parseInt(val ?? "0") : 0;
        this.setState({ item: user });
    }

    closeQueryModal() {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });
    }

    private showChangePassword() {
        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Change Password';
        queryModalProps.message = 'Enter password and click OK to change.';
        queryModalProps.showTextbox = true;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.changePassword(val);

        this.setState({ queryModalProps: queryModalProps });
    }

    showConfirmDelete() {
        if (!this.state.item.id) return;
        var id = this.state.item.id;

        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete User?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.deleteUser(id);

        this.setState({ queryModalProps: queryModalProps });
    }

    showAlert(title: string, message: string) {
        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = title;
        queryModalProps.message = message;
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = false;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.closeQueryModal();

        this.setState({ queryModalProps: queryModalProps });
    }

    private saveUser() {
        if (this.state.item.id) this.updateUser();
        else this.createUser();
    }

    private changePassword(password: string) {
        this.closeQueryModal();
        if (!this.state.item.id) return;
        var id = this.state.item.id;

        AdminData.changePassword(id, password)
            .then(() => {
                this.showAlert('Change Password', 'Password successfully changed');
            });
    }

    private createUser() {
        AdminData.saveUser(this.state.item)
            .then(() => this.close());
    }

    private updateUser() {
        AdminData.updateUser(this.state.item)
            .then(() => this.close());
    }

    private deleteUser(id: number) {
        this.closeQueryModal();

        AdminData.deleteUser(id)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
