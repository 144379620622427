import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditNeedle } from "./EditNeedle";

export class EditFilter extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditNeedle {...this.props}>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Filter Issue Details</h3>
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Filter Temperature'
                    value={this.props.item.filterTemperature}
                    update={(report, val) => report.filterTemperature = val} />

                <InputField
                    {...this.props}
                    label='Filter Centrifuge'
                    value={this.props.item.filterCentrifuge}
                    update={(report, val) => report.filterCentrifuge = val} />

                <InputField
                    {...this.props}
                    label='Filtration Time'
                    value={this.props.item.filtrationTime}
                    update={(report, val) => report.filtrationTime = val} />

                <InputField
                    {...this.props}
                    label='Blood Amount'
                    value={this.props.item.bloodAmount} 
                    update={(report, val) => report.bloodAmount = val}/>

                <InputField
                    {...this.props}
                    label='Filter Flat Filled'
                    value={this.props.item.filterFlatFilled}
                    update={(report, val) => report.filterFlatFilled = val} />

                <InputField
                    {...this.props}
                    label='WBC Value'
                    value={this.props.item.wbcValue}
                    update={(report, val) => report.wbcValue = val} />

            </EditNeedle>
        );
    }
}
