import styles from "./Base.module.scss";

import {
    IButtonStyles,
    ICheckboxStyles,
    IChoiceGroupOptionStyles,
    IChoiceGroupStyles,
    ICommandBarStyles,
    IDatePickerStyles,
    IDropdownStyles,
    IIconStyles,
    ILabelStyles,
    ILinkStyles,
    IModalStyles,
    ITextFieldStyles
} from "@fluentui/react";

export const navStyles: Partial<ICommandBarStyles> = {
    root: {
        fontFamily: 'Verdana, sans-serif',
        backgroundColor: styles.fkGrey,
        height: '65px',
    },
    primarySet: {
        justifyContent: 'flex-end',
        backgroundColor: styles.fkGrey,
    },
    secondarySet: {
        backgroundColor: styles.fkGrey,
    },
    
};

export const navItemStyles: Partial<IButtonStyles> = {
    root: {
        fontFamily: 'Verdana, sans-serif',
        backgroundColor: styles.fkGrey,
        color: styles.fkBlue,
    },
    label: {
        fontWeight: 800,
    },
    rootHovered: {
        backgroundColor: styles.fkGrey,
        color: styles.fkBlue,
    },
};

export const overflowButtonStyles: IButtonStyles = {

    root: {
        width: '48px',
        height: '65px',
        color: styles.fkWhite,
        backgroundColor: styles.fkGrey,
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
    },
    label: {
        display: 'none',
    },
    menuIcon: {
        display: 'none',
    },
    icon: {
        fontSize: '2rem',
        fontWeight: '800'
    }
};

export const labelStyles: Partial<ILabelStyles> = {
    root: {
        padding: 0,
        fontWeight: 400,
        color: 'inherit',
        fontSize: '1rem',
        display: 'table-cell',
        verticalAlign: 'middle',
        fontFamily: 'Verdana, sans-serif',
    }
};

export const editFormDeleteIconStyles: Partial<IIconStyles> = {
    root: {
        fontSize: '1rem',
        display: 'table-cell',
        verticalAlign: 'middle',
    }
};

export const editFormAddIconStyles: Partial<IIconStyles> = {
    root: {
        fontSize: '2rem',
        display: 'table-cell',
        verticalAlign: 'middle',
    }
};

export const textBoxStyles: Partial<ITextFieldStyles> = {
    field: {
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
    },
    fieldGroup: {
        height: '48px',
        fontFamily: 'Verdana, sans-serif',
    },
    errorMessage: {
        fontFamily: 'Verdana, sans-serif',
        color: styles.fkRed,
        fontSize: '0.7rem',
    }
};

export const datePickerStyles: Partial<IDatePickerStyles> = {
    icon: {
        fontSize: '1.1rem',
        padding: '15px 5px 5px'
    },
};

export const adminTextBoxStyles: Partial<ITextFieldStyles> = {
    field: {
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
    },
    fieldGroup: {
        height: '32px',
    },
    errorMessage: {
        fontFamily: 'Verdana, sans-serif',
        color: styles.fkRed,
        fontSize: '0.7rem',
    }
};

export const adminButtonStyles: IButtonStyles = {
    rootDisabled: {
        color: styles.fkWhite,
        backgroundColor: styles.fkDarkGrey,
    },
    root: {
        width: '100%',
        height: '32px',
        color: styles.fkWhite,
        backgroundColor: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
        marginBottom: '0px',
    },
    label: {
        fontWeight: 400,
    }
};

export const fullWidthButtonStyles: IButtonStyles = {
    rootDisabled: {
        color: styles.fkWhite,
        backgroundColor: styles.fkDarkGrey,
    },
    root: {
        width: '100%',
        height: '48px',
        color: styles.fkWhite,
        backgroundColor: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
        marginBottom: '10px',
    },
    label: {
        fontWeight: 400,
    }
};

export const buttonStyles: IButtonStyles = {
    rootDisabled: {
        color: styles.fkWhite,
        backgroundColor: styles.fkDarkGrey,
    },
    root: {
        width: '90px',
        height: '48px',
        color: styles.fkWhite,
        backgroundColor: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
    },
    label: {
        fontWeight: 400,
    }
};

export const lineButtonStyles: IButtonStyles = {
    rootDisabled: {
        color: styles.fkWhite,
        backgroundColor: styles.fkDarkGrey,
    },
    root: {
        width: '90px',
        height: '48px',
        color: styles.fkWhite,
        backgroundColor: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
        fontSize: '1rem',
        marginBottom: '10px',
    },
    label: {
        fontWeight: 400,
    }
};

export const headerRowButtonStyles: IButtonStyles = {
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        fontFamily: 'Verdana, sans-serif',
        color: styles.fkWhite,
        border: 'none',
    },
    rootHovered: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: styles.fkWhite,
    },
    rootPressed: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: styles.fkWhite,
    },
    icon: {
        fontSize: '10px'
    }
};

export const blueLinkStyles: ILinkStyles = {
    root: {
        color: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
    },
};

export const inlineLinkStyles: ILinkStyles = {
    root: {
        display: 'inline-block !important',
        marginTop: '0 !important',
        color: styles.fkBlue,
        fontFamily: 'Verdana, sans-serif',
    },
};

export const modalStyles: Partial<IModalStyles> = {
    main: {
        width: '100%',
        maxWidth: '900px',
        padding: '0 16px',
        backgroundColor: styles.fkGrey,
    },
    scrollableContent: {
        width: '100%',
        overflowY: 'unset',
    }
};

export const queryModalStyles: Partial<IModalStyles> = {
    main: {
        width: '100%',
        maxWidth: '400px',
        padding: '0 8px',
        backgroundColor: styles.fkGrey,
    },
    scrollableContent: {
        width: '100%',
        overflowY: 'unset',
    }
};

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        height: '48px',
        maxHeight: '48px',
    },
    title: {
        height: '48px',
        maxHeight: '48px',
        lineHeight: '46px',
        fontSize: '1rem',
        fontFamily: 'Verdana, sans-serif',
    },
    caretDownWrapper: {
        height: '46px',
        lineHeight: '46px',
    },
    errorMessage: {
        fontFamily: 'Verdana, sans-serif',
        color: styles.fkRed,
        fontSize: '0.7rem',
    }
};

export const checkboxStyles: Partial<ICheckboxStyles> = {
    root: {
        justifyContent: 'space-around',
        fontSize: '1rem',
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    checkbox: {
        height: '22px',
        width: '22px',
    }
}

export const choiceGroupStyles: Partial<IChoiceGroupStyles> = {
    root: {
        paddingTop: '2px',
        paddingBottom: '2px',
    }
}

export const choiceGroupOptionStyles: Partial<IChoiceGroupOptionStyles> = {
    root: {
        paddingTop: '14px',
        paddingBottom: '14px',
    }
}