import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { EditCorrespondence } from "./EditCorrespondence";
import { InputCheck } from "../InputCheck";

export class EditGeneral extends React.Component<IEditorProps, {}> {
    constructor(props: IEditorProps) {
        super(props);
    }
    render() {
        return (
            <EditCorrespondence {...this.props}>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>General Incident Details</h3>
                    </div>
                </div>

                <InputCheck
                    {...this.props}
                    label='Adverse Event'
                    value={this.props.item.adverseEvent} 
                    update={(report, val) => report.adverseEvent = val} />

                <InputField
                    {...this.props}
                    label='Adverse Event Description'
                    value={this.props.item.adverseEventDescription}
                    update={(report, val) => report.adverseEventDescription = val} />

                <InputField
                    {...this.props}
                    label='Completed'
                    value={this.props.item.completed}
                    update={(report, val) => report.completed = val} />

                <InputCheck
                    {...this.props}
                    label='During Donation'
                    value={this.props.item.duringDonation}
                    update={(report, val) => report.duringDonation = val} />

                <InputField
                    {...this.props}
                    label='Drug Administered'
                    value={this.props.item.drugAdministered}
                    update={(report, val) => report.drugAdministered = val} />

                <InputField
                    {...this.props}
                    label='Drug Used'
                    value={this.props.item.drugUsed}
                    update={(report, val) => report.drugUsed = val} />

                <InputField
                    {...this.props}
                    label='Is Cytotoxic'
                    value={this.props.item.isCytotoxic}
                    update={(report, val) => report.isCytotoxic = val} />

                <InputField
                    {...this.props}
                    label='Lost'
                    value={this.props.item.lost} 
                    update={(report, val) => report.lost = val} />

                <InputField
                    {...this.props}
                    label='Soft Goods Issue'
                    value={this.props.item.softGoodsIssue}
                    update={(report, val) => report.softGoodsIssue = val} />

                <InputField
                    {...this.props}
                    label='Stopped'
                    value={this.props.item.stopped} 
                    update={(report, val) => report.stopped = val} />

                <InputField
                    {...this.props}
                    label='Container'
                    value={this.props.item.container}
                    update={(report, val) => report.container = val} />

                <InputField
                    {...this.props}
                    label='Manufacturer'
                    value={this.props.item.manufacturer}
                    update={(report, val) => report.manufacturer = val} />

            </EditCorrespondence>
        );
    }
}
