import { IAccount } from "./Account";
import { IProduct } from "./Product";

export interface IAccountProduct {
    accountId: number;
    account?: IAccount;
    productId: number;
    product?: IProduct;
}

export class AccountProduct implements IAccountProduct {
    public accountId: number = 0;
    public account?: IAccount;
    public productId: number = 0;
    public product?: IProduct;
}