import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IReport } from "./models/Report";
import { IReportSummary } from "./models/ReportSummary";

export class ReportData {

    public static async create(item: IReport): Promise<AxiosResponse<any>> {
        console.log(item);
        var result = await DataLayer.post('/reports', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IReport[]>> {
        var result = await DataLayer.get<IReport[]>(`/admin/reports`);
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[], reportId?: number): Promise<AxiosResponse<IReport[]>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        } as any;

        if (reportId && reportId > 0) {
            options.where = {
                id: reportId
            }
        }
        
        var result = await DataLayer.post(`/admin/reports/filter`, options);
        return result;
    }

    public static async retrieveByAccount(accountId: number): Promise<AxiosResponse<IReport[]>> {
        var result = await DataLayer.get<IReport[]>(`/reports/account/${accountId}`);
        return result;
    }

    public static async retrieveById(reportId: number): Promise<AxiosResponse<IReport>> {
        var result = await DataLayer.get<IReport>(`/reports/${reportId}`);
        return result;
    }

    public static async update(item: IReport): Promise<AxiosResponse<any>> {
        console.log(item);
        var result = await DataLayer.put('/admin/reports', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/reports/${id}`);
        return result;
    }

    public static async search(searchString: string): Promise<AxiosResponse<IReportSummary[]>> {
        var urlEncoded = encodeURIComponent(searchString);
        var result = await DataLayer.get<IReportSummary[]>(`/reportsearch/${urlEncoded}`);
        return result;
    }

    public static async export(searchString: string): Promise<AxiosResponse<IReportSummary[]>> {
        var urlEncoded = encodeURIComponent(searchString);
        var result = await DataLayer.get<IReportSummary[]>(`/reportexport/${urlEncoded}`);
        return result;
    }
}
