import { DefaultButton } from '@fluentui/react';
import { headerRowButtonStyles } from '../../../../scss/ControlStyles';
import styles from '../../../../scss/Table.module.scss';
import { IHeaderRowProps } from './IHeaderRowProps';

export function HeaderRow(props: IHeaderRowProps) {
    const getIconProps = (index: number) => {
        if (index !== props.pageInfo.sortCol) return;

        var iconName = props.pageInfo.sortDir === 'ASC' ? 'CaretSolidDown' : 'CaretSolidUp'
        return {iconName: iconName};
    }

    const colClicked = (index: number) => {
        var pageInfo = props.pageInfo;
        pageInfo.sortDir = pageInfo.sortDir === 'ASC' && pageInfo.sortCol === index ? 'DESC' : 'ASC';
        pageInfo.sortCol = index;
        props.onPageChanged(pageInfo);
    }

    return (
        <div className={`${styles.tableRow}`}>
            {
                props.columns.map((col, i) => {
                    return (
                        <div className={`${styles.tableCell} ${styles.headerCell}`} key={i}>
                            <DefaultButton 
                                text={col.title} 
                                styles={headerRowButtonStyles}
                                iconProps={getIconProps(i)}
                                onClick={() => {colClicked(i)}} />
                        </div>
                    );
                })
            }

            { props.hideEdit ? <></> : <div className={`${styles.tableCell} ${styles.editCell}`} /> }
            
        </div> 
    )
}