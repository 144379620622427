import styles from '../../../../scss/Table.module.scss';

import { IRowProps } from '../IRowProps';
import { Icon } from '@fluentui/react/lib/Icon';
import { IAccountProduct } from '../../../../data/models/AccountProduct';

export function AccountProductRow(props: IRowProps<IAccountProduct>) {

    var deleteClicked = () => {
        props.onEdit(props.item);
    }

    return (
        <div className={`${styles.tableRow}`} key={props.item.account?.id}>
            <div className={`${styles.tableCell}`}>
                {props.item.account?.account}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.account?.name}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.product?.code}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.product?.description}
            </div>
            <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                <span className={`${styles.link}`} onClick={deleteClicked}>
                    <Icon iconName="Delete" className={styles.red} />
                </span>
            </div>
        </div>
    );
}
