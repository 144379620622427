import React from "react";
import styles from '../../scss/pages/ReportSearch.module.scss';
import { Authenticator } from "../../components/authenticator/Authenticator";
import { AuthContext } from "../../components/authProvider/AuthContext";
import { Checkbox, DefaultButton, IChoiceGroupOption, IDropdownOption, Label, TextField } from "@fluentui/react";
import { IUser } from "../../data/models/User";
import { AccountData } from "../../data/AccountData";
import { buttonStyles, checkboxStyles, choiceGroupOptionStyles, labelStyles, textBoxStyles } from "../../scss/ControlStyles";
import { Selector } from "../../components/selector/selector";
import { TextInput } from "../../components/inputs/TextInput";
import { LineData } from "../../data/LineData";
import { IAccount } from "../../data/models/Account";
import { DateSelect } from "../../components/form/dateSelect/DateSelect";
import { SearchItem } from "./SearchItem";
import { SortHelper } from "../../data/helpers/sortHelper";

export interface IReportSearchState {
    toDate?: Date;
    fromDate?: Date;
    lotNumber: string;
    identifier: string;
    line?: IDropdownOption;
    account?: IDropdownOption;
    product?: IDropdownOption;
    lineOptions: IDropdownOption[];
    accountOptions: IDropdownOption[];
    productOptions: IDropdownOption[];
    selectedChoice: number;
    identifierOptions: IChoiceGroupOption[];

    identifierError: string;
    fromDateError: string;
    toDateError: string;

}

export class ReportSearch extends React.Component<{}, IReportSearchState> {
    static contextType = AuthContext;

    constructor(props: {}) {
        super(props);

        var options = [
            { key: '1', text: 'Submission Number', styles: choiceGroupOptionStyles },
            { key: '2', text: 'Customer Reference Number', styles: choiceGroupOptionStyles },
            { key: '3', text: 'Notification Number', styles: choiceGroupOptionStyles },
            { key: '4', text: 'Donor Bleed Number', styles: choiceGroupOptionStyles },
        ];

        this.state = {
            lotNumber: '',
            identifier: '',
            lineOptions: [],
            accountOptions: [],
            productOptions: [],
            identifierOptions: options,
            selectedChoice: 0,

            identifierError: '',
            fromDateError: '',
            toDateError: '',
        };
    }

    loadData() {
        this.loadLines();
        this.loadAccounts();
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut={true} onAuthenticated={this.loadData.bind(this)}>
                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm10} ${styles.smOffset1}`}>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <h1>Search Performance Report</h1>
                                <h3>Use the form below to search for submitted product performance reports:</h3>
                            </div>
                        </div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.bgw}`}>
                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                            <div className={`${styles.bgw}`}>
                                                <div className={`${styles.unpaddedRow}`}>
                                                    <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                                        <h2>Choose an account:</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgOffset1}`}>
                                            <div className={`${styles.labelBox}`}>
                                                <Label styles={labelStyles}>Account:</Label>
                                            </div>
                                        </div>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg7}`}>
                                            <Selector
                                                placeholder={'All Accounts'}
                                                selectedOption={this.state.account}
                                                options={this.state.accountOptions}
                                                onSelected={this.accountSelected.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                <div className={`${styles.bgw}`}>
                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                            <h2>Search By Date:</h2>
                                        </div>
                                    </div>
                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                            <div className={`${styles.labelBox}`}>
                                                <Label styles={labelStyles}><span className={styles.red} hidden={this.state.selectedChoice > 0}>* </span>From Incident Date:</Label>
                                            </div>
                                        </div>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                            <DateSelect
                                                value={this.state.fromDate}
                                                onSelectDate={this.fromDateChanged.bind(this)}
                                            />

                                            <span className={`${styles.error}`}>
                                                {this.state.fromDateError}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                            <div className={`${styles.labelBox}`}>
                                                <Label styles={labelStyles}><span className={styles.red} hidden={this.state.selectedChoice > 0}>* </span>To Incident Date:</Label>
                                            </div>
                                        </div>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                            <DateSelect
                                                value={this.state.toDate}
                                                onSelectDate={this.toDateChanged.bind(this)}
                                            />

                                            <span className={`${styles.error}`}>
                                                {this.state.toDateError}
                                            </span>
                                        </div>
                                    </div>

                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                            <div className={`${styles.labelBox}`}>
                                                <Label styles={labelStyles}>Product Line:</Label>
                                            </div>
                                        </div>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                            <Selector
                                                selectedOption={this.state.line}
                                                options={this.state.lineOptions}
                                                onSelected={this.setLine.bind(this)} />
                                        </div>
                                    </div>

                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg4} ${styles.lgOffset1}`}>
                                            <div className={`${styles.labelBox}`}>
                                                <Label styles={labelStyles}>Product Code:</Label>
                                            </div>
                                        </div>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                            <Selector
                                                selectedOption={this.state.product}
                                                options={this.state.productOptions}
                                                onSelected={this.setProduct.bind(this)} />
                                        </div>
                                    </div>



                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12}`}>
                                            <TextInput
                                                show
                                                value={this.state.lotNumber}
                                                defaultLabel={'Lot Number'}
                                                getStateUpdate={(val) => { return { lotNumber: val } }}
                                                updateValue={this.updateState.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                                <div className={`${styles.bgw}`}>
                                    <div className={`${styles.row}`}>
                                        <div className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}>
                                            <h2>Search By Identifier:</h2>
                                            <p>
                                                Choose one of the options below:
                                            </p>

                                            <div className={`${styles.paddedRow}`}>
                                                <div className={`${styles.col} ${styles.sm12}`}>
                                                    <Checkbox
                                                        styles={checkboxStyles}
                                                        label={'Submission Number'}
                                                        checked={this.state.selectedChoice === 1}
                                                        onChange={this.submissionNumberClicked.bind(this)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`${styles.paddedRow}`}>
                                                <div className={`${styles.col} ${styles.sm12}`}>
                                                    <Checkbox
                                                        styles={checkboxStyles}
                                                        label={'Customer Reference Number'}
                                                        checked={this.state.selectedChoice === 2}
                                                        onChange={this.custRefNumberClicked.bind(this)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`${styles.paddedRow}`}>
                                                <div className={`${styles.col} ${styles.sm12}`}>
                                                    <Checkbox
                                                        styles={checkboxStyles}
                                                        label={'Notification Number'}
                                                        checked={this.state.selectedChoice === 3}
                                                        onChange={this.notificationNumberClicked.bind(this)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`${styles.paddedRow}`}>
                                                <div className={`${styles.col} ${styles.sm12}`}>
                                                    <Checkbox
                                                        styles={checkboxStyles}
                                                        label={'Donor Bleed Number'}
                                                        checked={this.state.selectedChoice === 4}
                                                        onChange={this.donorBleedNumberClicked.bind(this)}
                                                    />
                                                </div>
                                            </div>

                                            <div className={`${styles.row}`} hidden={!this.state.selectedChoice}>
                                                <div className={`${styles.col} ${styles.sm12}`}>
                                                    <TextField
                                                        styles={textBoxStyles}
                                                        value={this.state.identifier}
                                                        onChange={this.identifierChanged.bind(this)}
                                                        errorMessage={this.state.identifierError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.right}`}>
                                <DefaultButton
                                    text='Search'
                                    styles={buttonStyles}
                                    onClick={this.searchClicked.bind(this)} />
                            </div>
                        </div>
                    </div>
                </div>
            </Authenticator >
        );
    }

    private loadLines() {
        LineData.lineOptions()
            .then((lines) => {
                this.setState({ lineOptions: lines });
            });
    }

    private loadAccounts() {
        var user: IUser = this.context.user;
        if (!user.id) return;

        AccountData.getAccountOptions(user.id)
            .then((options) => {
                options.sort((a1,a2) => SortHelper.sortAccount(a1.data, a2.data));
                options.unshift({ key: 0, index: Number.MAX_VALUE, text:'All accounts' });
                this.setState({ accountOptions:  options});

                if (options && options.length === 1) {
                    this.setAccount(options[0]);
                }
                else {
                    var accounts = options.map((o) => o.data)
                    this.loadProductOptions(accounts);
                }
            });
    }

    private setLine(account: IDropdownOption) {
        this.setState({ line: account });
    }

    private accountSelected(option: IDropdownOption) {
        if (option.data) {
            this.setAccount(option);
        }
        else {
            this.unsetAccount();
        }
    }

    private setAccount(option: IDropdownOption) {
        this.setState({ account: option });
        this.loadProductOptions([option.data]);
    }

    private unsetAccount() {
        this.setState({
            account: { key: -1, index: -1, text: ''},
        });

        var accounts = this.state.accountOptions.map((o) => o.data);
        this.loadProductOptions(accounts);
    }

    setProduct(option: IDropdownOption) {
        if (!option.data) {
            option = { key: -1, index: -1, text: ''};
        }

        this.setState({ product: option });
    }

    loadProductOptions(accounts: IAccount[]): void {
        var products: IDropdownOption[] = [];
        products.push({ key: 0, index: Number.MAX_VALUE, text: 'All Products', });

        for (let i = 0; i < accounts.length; i++) {
            let account = accounts[i];


            if (account && account.products) {
                for (let j = 0; j < account.products.length; j++) {
                    let p = account.products[j];
                    if (products.some((x) => x.index === p.id)) continue;

                    var id = p.id ? p.id : 0;

                    if (products[0])

                    products.push({
                        data: p,
                        key: id,
                        index: id,
                        text: `${p.code} - ${p.description}`,
                    });
                }
            }
        }

        var product = products && products.length === 1 ? products[0] : undefined;

        this.setState({
            product: product,
            productOptions: products
        });
    }

    fromDateChanged(date: Date | null | undefined) {
        console.log(date);
        if (date) this.setState({ fromDate: date });
    }

    toDateChanged(date: Date | null | undefined) {
        console.log(date);
        if (date) this.setState({ toDate: date });
    }

    identifierClear() {
        this.setState({
            selectedChoice: 0,
            identifier: ''
        });
    }

    submissionNumberClicked(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        if (checked) {
            this.setState({ selectedChoice: 1, identifier: '' });
        }
        else {
            this.identifierClear();
        }
    }

    custRefNumberClicked(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        if (checked) {
            this.setState({ selectedChoice: 2, identifier: '' });
        }
        else {
            this.identifierClear();
        }
    }

    notificationNumberClicked(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        if (checked) {
            this.setState({ selectedChoice: 3, identifier: '' });
        }
        else {
            this.identifierClear();
        }
    }

    donorBleedNumberClicked(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        if (checked) {
            this.setState({ selectedChoice: 4, identifier: '' });
        }
        else {
            this.identifierClear();
        }
    }

    identifierChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = val ? val : '';
        if (this.state.selectedChoice === 1) val = val.replace(/\D/g, '');

        if (val.length > 255) {
            val = val.substr(0, 255);
        }

        this.setState({ identifier: val });
    }

    updateState(update: any) {
        this.setState(update);
    }

    searchClicked() {
        console.log('search clicked');
        console.log(this.state.fromDate);
        console.log(this.state.toDate);

        var isValid = this.validateDates();
        isValid = this.validateIdentifier() && isValid;
        if (isValid) this.goToSearchResults();
    }

    goToSearchResults() {
        var searchItem = new SearchItem(this.state);
        var searchString = searchItem.toString();
        window.location.href = `/search/${searchString}`;
    }

    validateDates(): boolean {
        var isValid = true;
        if (this.state.selectedChoice > 0) return isValid;

        isValid = this.isDate((val: string) => { return { fromDateError: val } }, this.state.fromDate) && isValid;
        isValid = this.isDate((val: string) => { return { toDateError: val } }, this.state.toDate) && isValid;

        isValid = isValid && this.isFutureDate((val: string) => { return { fromDateError: val } }, this.state.fromDate);
        isValid = isValid && this.isFutureDate((val: string) => { return { toDateError: val } }, this.state.toDate);

        return isValid && this.isLater(this.state.fromDate, this.state.toDate);
    }

    validateIdentifier(): boolean {
        var isValid = true;

        if (this.state.selectedChoice > 0 && this.state.identifier.length === 0) {
            this.setState({ identifierError: 'Please enter a value' });
            isValid = false;
        }

        return isValid
    }

    isDate(state: (val: string) => any, date?: Date) {
        var isDate = date !== undefined;
        var err = isDate ? '' : 'Select dates or search by indentifier';

        this.setState(state(err));
        return isDate;
    }

    isFutureDate(state: (val: string) => any, date?: Date): boolean {
        var isFuture = !!date && date > new Date();
        var err = isFuture ? 'Date cannot be in the future' : '';
        this.setState(state(err));
        return !isFuture;
    }

    isLater(from?: Date, to?: Date) {
        if (!from || !to) return false;
        var isLater = from > to;

        var err = isLater ? 'from date must be before to date' : '';
        this.setState({ fromDateError: err });

        return !isLater;
    }
}