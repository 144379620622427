import { IProductLine } from "./ProductLine";

export interface IProblemLocation {
    id?: number;
    code: string;
    description: string;
    productLineId: number;  
    xOrigin: number;
    yOrigin: number;
    xLength: number;
    yLength: number;
    enabled: boolean;

    productLine?: IProductLine;
  }

export class ProblemLocation implements IProblemLocation {
    public id?: number;
    public code: string = '';
    public description: string = '';
    public productLineId: number = 0;
    public xOrigin: number = 0;
    public yOrigin: number = 0;
    public xLength: number = 0;
    public yLength: number = 0;
    public enabled: boolean = false;
}