import { IDropdownOption } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IProductLine } from "./models/ProductLine";

export class LineData {

    public static async create(line: IProductLine): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/productlines', line);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IProductLine[]>> {
        var result = await DataLayer.get<IProductLine[]>('/productlines');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post('/admin/productlines/filter', options);
        return result;
    }

    public static async retrieveById(id: number): Promise<AxiosResponse<IProductLine>> {
        var result = await DataLayer.get<IProductLine>(`/productlines/${id}`);
        return result;
    }

    public static async update(line: IProductLine): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/productlines', line);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/productlines/${id}`);
        return result;
    }

    public static async lineOptions(): Promise<IDropdownOption[]> {
        var lines = await LineData.retrieve();
        var sortedLines = lines.data.sort((n1, n2) => n1.displayOrder - n2.displayOrder);
        
        return sortedLines.map((line) => {
            var id: number = line.id ? line.id : 0;
            
            return {
                    key: id,
                    index: id,
                    data: line,
                    text: line.reportHeader,
            }
        })
    }
}
