import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import styles from '../../../scss/components/Paginator.module.scss';
import { IPageProps } from "./IPageProps";

export function ExportSummary(props: IPageProps) {

    var from = ((props.pageInfo.page - 1) * props.pageInfo.count) + 1;
    from = Math.min(from, props.pageInfo.total);
    const to = Math.min(from + props.pageInfo.count - 1, props.pageInfo.total);

    const dropdownStyles: Partial<IDropdownStyles> = {
        root: {
            marginLeft: '20px',
            display: 'inline-flex'
        }
    };

    const pageCountChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        var pageInfo = props.pageInfo;
        if(option?.index) {
            pageInfo.page = 1;
            pageInfo.count = option.index;
            props.onPageChanged(pageInfo);
        }
    }

    return (
        <div className={`${styles.row}`}>
            <div className={`${styles.col} ${styles.sm6}`}>
                <span className={styles.aside}>{`${from} - ${to} of ${props.pageInfo.total}`}</span>
            </div>
            <div className={`${styles.col} ${styles.sm6} ${styles.right}`}>
                <span>items per page:</span>
                <Dropdown
                    options={[
                        { key: 5, index: 5, text: '5' },
                        { key: 10, index: 10, text: '10' },
                        { key: 25, index: 25, text: '25' },
                        { key: 50, index: 50, text: '50' },
                        { key: 100, index: 100, text: '100' },
                    ]} 
                    styles={dropdownStyles} 
                    selectedKey={props.pageInfo.count} 
                    onChange={pageCountChanged} />
            </div>
        </div>
    )
}