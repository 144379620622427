import React, { FormEvent } from 'react';
import styles from '../../../scss/Base.module.scss';

import { UserData } from '../../../data/UserData';
import { IUser } from '../../../data/models/User';
import { Copyright } from '../../../components/copyRight/CopyRight';

import {
    labelStyles,
    fullWidthButtonStyles,
    textBoxStyles,
} from '../../../scss/ControlStyles';

import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    Label,
    MaskedTextField,
    TextField
} from '@fluentui/react';
import { AxiosResponse } from 'axios';
import { Redirect } from 'react-router';


interface IState {
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    accountNumber: string;

    hideDialog: boolean;
    closeDialog: () => void;
    dialogProps?: IDialogContentProps;

    usernameError: string;
    firstnameError: string;
    lastnameError: string;
    emailError: string;
    phoneError: string;
    addressError: string;

    redirect: boolean;
}

export class UserRegister extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            username: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            accountNumber: '',

            hideDialog: true,
            closeDialog: () => this.closeDialog(false),

            usernameError: '',
            firstnameError: '',
            lastnameError: '',
            emailError: '',
            phoneError: '',
            addressError: '',

            redirect: false,
        };
    }

    render() {
        if(this.state.redirect) {
            return (
                <Redirect to='/login' />
            )
        }

        return (
            <div>
                <div className={`${styles.paddedRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.xl8} ${styles.xlOffset2}`}>
                        <p>
                            If you would like to set up an account on Fresenius Kabi’s Product Performance
                            site, please fill out the form below. When you submit, your account will go
                            through a review process, where it will be matched and assigned against your
                            companies’ accounts. You will receive an email when your account is approved.
                        </p>
                        <p>
                            Be sure to use a valid email address format.
                        </p>
                    </div>
                </div>

                <form onSubmit={this.onRegister.bind(this)}>
                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_username'>*User Name:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_username'
                                styles={textBoxStyles}
                                value={this.state.username}
                                onChange={this.usernameChanged.bind(this)}
                                errorMessage={this.state.usernameError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_firstname'>*First Name:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_firstname'
                                styles={textBoxStyles}
                                value={this.state.firstname}
                                onChange={this.firstnameChanged.bind(this)}
                                errorMessage={this.state.firstnameError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_lastname'>*Last Name:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_lastname'
                                styles={textBoxStyles}
                                value={this.state.lastname}
                                onChange={this.lastnameChanged.bind(this)}
                                errorMessage={this.state.lastnameError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_email'>*Email:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_email'
                                styles={textBoxStyles}
                                value={this.state.email}
                                onChange={this.emailChanged.bind(this)}
                                errorMessage={this.state.emailError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_phone'>*Center/Site Phone:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <MaskedTextField
                                id='txt_phone'
                                styles={textBoxStyles}
                                mask='(999) 999 9999'
                                value={this.state.phone}
                                onChange={this.phoneChanged.bind(this)}
                                errorMessage={this.state.phoneError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_address'>*Center/Site Address:</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_address'
                                styles={textBoxStyles}
                                value={this.state.address}
                                onChange={this.addressChanged.bind(this)}
                                errorMessage={this.state.addressError}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl3} ${styles.xlRight}`}>
                            <div className={styles.labelBox}>
                                <Label styles={labelStyles} htmlFor='txt_accnum'>Account # (if known):</Label>
                            </div>
                        </div>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7}`}>
                            <TextField
                                id='txt_accnum'
                                styles={textBoxStyles}
                                value={this.state.accountNumber}
                                onChange={this.accountNumberChanged.bind(this)}
                            />
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12} ${styles.xl7} ${styles.xlOffset3}`}>
                            <DefaultButton
                                type="submit"
                                text="Create User"
                                styles={fullWidthButtonStyles}
                            />
                        </div>
                    </div>
                </form>

                <Dialog
                    hidden={this.state.hideDialog}
                    dialogContentProps={this.state.dialogProps}>
                    <DialogFooter>
                        <DefaultButton onClick={this.state.closeDialog.bind(this)} text="OK" />
                    </DialogFooter>
                </Dialog>

                <Copyright cssName={'register'} />
            </div>
        );
    }

    private usernameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ username: val });
    }

    private firstnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ firstname: val });
    }

    private lastnameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ lastname: val });
    }

    private emailChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ email: val });
    }

    private phoneChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ phone: val });
    }

    private addressChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ address: val });
    }

    private accountNumberChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ accountNumber: val });
    }

    private onRegister(event?: FormEvent) {
        event?.preventDefault();
        if (!this.validateForm()) return;

        var user = {
            username: this.state.username,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            accountNumber: this.state.accountNumber,
        } as IUser;

        var userData = new UserData();
        userData.register(user)
            .then((response) => {
                this.handleRegisterResponse(response);
            });
    }

    private validateForm(): boolean {
        var isValid = true;
        const phoneRx = /^\(\d{3}\)\s\d{3}\s\d{4}$/;
        const emailRx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        isValid = this.validateField(this.state.username, (v) => { return { usernameError: v ? '' : 'Please enter a username' } }) && isValid;
        isValid = this.validateField(this.state.firstname, (v) => { return { firstnameError: v ? '' : 'Please enter a first name' } }) && isValid;
        isValid = this.validateField(this.state.lastname, (v) => { return { lastnameError: v ? '' : 'Please enter a last name' } }) && isValid;
        isValid = this.validateField(this.state.email, (v) => { return { emailError: v ? '' : 'Please enter a valid email' } }, emailRx) && isValid;
        isValid = this.validateField(this.state.phone, (v) => { return { phoneError: v ? '' : 'Please enter a phone number' } }, phoneRx) && isValid;
        isValid = this.validateField(this.state.address, (v) => { return { addressError: v ? '' : 'Please enter an address' } }) && isValid;


        return isValid;
    }

    private validateField(val: string, update: (v: boolean) => any, rx: RegExp = /^(.+)$/): boolean {
        var isValid = !!val && rx.test(val);
        var state = update(isValid);
        this.setState(state);
        return isValid;
    }

    private handleRegisterResponse(response: AxiosResponse<any>) {
        var success = (response.status === 200);
        var dialogProps: IDialogContentProps;

        if (success) {
            dialogProps = {
                type: DialogType.normal,
                title: 'Membership Request Sent',
                subText: '',
            };
        }
        else {
            dialogProps = {
                type: DialogType.normal,
                title: 'An error occurred',
                subText: response.data,
            };
        }

        this.setState({
            hideDialog: false,
            dialogProps: dialogProps,
            closeDialog: () => this.closeDialog(success),
        });
    }

    private closeDialog(redirect: boolean) {
        this.setState({ 
            hideDialog: true,
            redirect: redirect,
         });
    }
}