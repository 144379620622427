import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';
import { IEditorProps } from "./IEditorProps";
import { EditFilterDetails } from "./EditFilterDetails";

import { 
    Checkbox, 
    TextField 
} from "@fluentui/react";

import { 
    checkboxStyles, 
    adminTextBoxStyles 
} from "../../../../scss/ControlStyles";

export class EditProblemDetails extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <EditFilterDetails
                item={this.props.item}
                onChange={this.props.onChange} >

                {this.props.children}

                <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm11} ${styles.smOffset1} ${styles.lg9} ${styles.lgOffset3}`}>
                        <div className={styles.labelBox}>
                            <h3>Optional Problem Details Fields</h3>
                        </div>
                    </div>
                </div>

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm1} ${styles.lg1} ${styles.lgOffset2}`}>
                        <div className={styles.compactLabelBox}>
                            <Checkbox
                                styles={checkboxStyles}
                                checked={this.props.item.showAlarmsExperienced}
                                onChange={this.showAlarmsExperiencedChanged.bind(this)}
                            />
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm11} ${styles.lg9}`}>
                        <TextField
                            styles={adminTextBoxStyles}
                            disabled={!this.props.item.showAlarmsExperienced}
                            value={this.props.item.alarmsExperiencedAltText}
                            placeholder={'Did you experience any alarms?'}
                            onChange={this.alarmsExperiencedAltTextChanged.bind(this)}
                        />
                    </div>
                </div>
            </EditFilterDetails>
        );
    }

    private showAlarmsExperiencedChanged(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        var item = this.props.item;
        item.showAlarmsExperienced = checked ? true : false;
        this.props.onChange(item);
    }

    private alarmsExperiencedAltTextChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.props.item;
        item.alarmsExperiencedAltText = val ? val : '';
        this.props.onChange(item);
    }
}
