import { UserRoleEnum } from "../enums/UserRoleEnum";
import { UserStatusEnum } from "../enums/UserStatusEnum";
import { IAccount } from "./Account";

export interface IUser {
    id?: number;
    createdAt?: Date;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    workPhone: string;
    fax: string;
    address: string;
    password: string;
    accountNumber: string;
    role: UserRoleEnum;
    status: UserStatusEnum;
    lastLogin?: Date;
    accounts: IAccount[];
    ThresholdTime1Months: number;
    ThresholdTime1EmailAttempts: number;
    ThresholdTime2Months: number;
    ThresholdTime2EmailAttempts: number;
}

export class User implements IUser {
    public id?: number;
    public createdAt?: Date;
    public username: string = '';
    public firstname: string = '';
    public lastname: string = '';
    public email: string = '';
    public phone: string = '';
    public workPhone: string = '';
    public fax: string = '';
    public address: string = '';
    public password: string = '';
    public accountNumber: string = '';
    public role: UserRoleEnum = UserRoleEnum.User;
    public status: UserStatusEnum = UserStatusEnum.Pending;
    public lastLogin?: Date;
    public accounts = [];
    public ThresholdTime2Months = parseInt(process.env.REACT_APP_DEFAULT_USER_LOCK_TIMEOUT_MONTHS ?? "12");
    public ThresholdTime1Months = parseInt(process.env.REACT_APP_DEFAULT_USER_LOCK_TIMEOUT_NOTIFICATION_MONTHS ?? "6");
    public ThresholdTime1EmailAttempts = 0;
    public ThresholdTime2EmailAttempts = 0;
}
