import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, Label, Modal } from '@fluentui/react';
import { fullWidthButtonStyles, labelStyles, modalStyles } from '../../../../scss/ControlStyles';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditState } from '../IEditState';
import { IEditUserAccountProps } from './IEditUserAccountProps';
import { Account, IAccount } from '../../../../data/models/Account';
import { IUserAccount, UserAccount } from '../../../../data/models/UserAccount';
import { UserAccountData } from '../../../../data/UserAccountData';
import { IUser, User } from '../../../../data/models/User';
import { SortHelper } from '../../../../data/helpers/sortHelper';

export class EditUserAccount extends React.Component<IEditUserAccountProps, IEditState<IUserAccount>> {

    constructor(props: IEditUserAccountProps) {
        super(props);

        var item = this.cloneItem();

        this.state = {
            item: item,
            show: this.props.show,
            queryModalProps: new QueryModalProps(),
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();

            this.setState({
                item: newItem,
                show: this.props.show
            });
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>New User Account Association</h2>
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Username:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.userId}
                            options={this.getUsernameOptions(this.props.users)}
                            onChange={this.userChanged.bind(this)}
                            allowFreeform
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.userId}
                            options={this.getNameOptions(this.props.users)}
                            onChange={this.userChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}
                            autoComplete="on"
                            selectedKey={this.state.item.accountId}
                            options={this.getAccountOptions(this.props.accounts)}
                            onChange={this.accountChanged.bind(this)}
                            allowFreeform
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Account Name:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <ComboBox
                            //styles={textBoxStyles}        
                            autoComplete="on"
                            selectedKey={this.state.item.accountId}
                            options={this.getAccountNameOptions(this.props.accounts)}
                            onChange={this.accountChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.createItem.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg6}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

            </Modal >
        );
    }

    private cloneItem(): IUserAccount {
        var item = new UserAccount();

        if (this.props.users && this.props.users.length === 1) {
            var user = this.props.users[0];
            item.userId = user.id ? user.id : 0;
            item.user = user;
        }

        return item;
    }

    private getUsernameOptions(users: IUser[]): IComboBoxOption[] {
        return users.map((u) => {
            var userId = u.id ? u.id : 0;
            return { key: userId, text: u.username, data: u };
        });
    }

    private getNameOptions(users: IUser[]): IComboBoxOption[] {
        return users.map((u) => {
            var userId = u.id ? u.id : 0;
            return { key: userId, text: `${u.firstname} ${u.lastname}`, data: u };
        });
    }

    private getAccountOptions(accounts: IAccount[]): IComboBoxOption[] {
        return accounts.sort((a1 ,a2) => SortHelper.sortAccount(a1, a2)).map((a) => {
            var id = a.id ? a.id : 0;
            return { key: id, text: `${a.account} - ${a.name} - ${a.address}`, data: a };
        });
    }

    private getAccountNameOptions(accounts: IAccount[]): IComboBoxOption[] {
        return accounts.map((a) => {
            var id = a.id ? a.id : 0;
            return { key: id, text: a.name, data: a };
        });
    }

    private userChanged(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        var user: IUser = option?.data;
        if (!user) user = new User();
        var item = this.state.item;

        item.userId = user.id ? user.id : 0;
        item.user = user;

        this.setState({ item: item });
    }

    private accountChanged(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        var account: IAccount = option?.data;
        if (!account) account = new Account();
        var item = this.state.item;

        item.accountId = account.id ? account.id : 0;
        item.account = account;

        this.setState({ item: item });
    }

    private createItem() {
        UserAccountData.create(this.state.item)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
