export interface IProduct {
    id?: number;
    code: string;
    description: string;
    solution: boolean;
    enabled: boolean;
}

export class Product implements IProduct {
    public id?: number;
    public code: string = '';
    public description: string = '';
    public solution: boolean = false;
    public enabled: boolean = true;
}