import './browserUpdate';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { AuthProvider } from './components/authProvider/AuthProvider';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
initializeIcons();




ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
