import { IReportSearchState } from "./ReportSearch";

export class SearchItem {
    // To String Map
    // FFF - account
    // FF - productLine
    // FFF - product
    // FFFFFFFFFF - from
    // FFFFFFFFFF - to
    // F - id Type
    // id length
    // lot

    public account?: number;
    public line?: number;
    public product?: number;
    public from?: Date;
    public to?: Date;
    public idType: number;
    public id: string;
    public lot: string;


    constructor(searchState: IReportSearchState) {
        this.account = searchState.account?.index;
        this.line = searchState.line?.index;
        this.product = searchState.product?.index;
        this.from = searchState.fromDate;
        this.to = searchState.toDate;
        this.idType = searchState.selectedChoice;
        this.id = searchState.identifier;
        this.lot = searchState.lotNumber;
    }

    toString() {
        var account = this.numToHex(3, this.account);
        var line = this.numToHex(2, this.line);
        var prod = this.numToHex(3, this.product);
        var from = this.dateToHex(this.from);
        var to = this.dateToHex(this.to);
        var idType = this.idType;
        var id = encodeURIComponent(this.id);
        var idLen = this.numToHex(2, this.id.length);
        var lot = encodeURIComponent(this.lot);

        return `${account}${line}${prod}${from}${to}${idType}${idLen}${id}${lot}`;
    }

    stringToHex(str: string) {
        var hex = '';
        var buffer = Buffer.from(str, 'binary');
        buffer.map((n) => {
            hex = n.toString(16) + hex;
            return n;
        });

        return hex;
    }

    dateToHex(date?: Date) {
        if(!date) return '0000000000';

        var epoch = date.getTime();
        epoch = epoch / 1000; // no need to preserve milliseconds!

        return this.numToHex(10, epoch);
    }

    numToHex(len: number, val: number = 0) {
        var hex = val.toString(16);
        var padded = this.padHex(hex, len);
        return padded;
    }

    padHex(hex: string, len: number) {
        var padded = '';

        for (var i = 0; i < len; i++) {
            padded = '0' + padded;
        }

        padded = `${padded}${hex}`;
        return padded.substr(padded.length - len, len);
    }
}
