import { IProblemActivity } from "../models/ProblemActivity";
import { IProblemDescription } from "../models/ProblemDescription";
import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

const CHAR_LIMIT = 38;
const CHAR_LIMIT_ERROR_MESSAGE = "You have hit the character limit for this field. To provide additional information, please enter your details under the Additional Incident Description field.";

export class ProblemFormValidator {

    private report: IReport;
    private errors: string[];
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine) {
        this.line = line;
        this.errors = errors;
        this.report = report;
    }

    validateProblemActivity(state: (err: boolean) => any, unknown: boolean): IValidateResult {
        var result: boolean = true;

        if (!this.report.problemActivityId ||
            this.report.problemActivityId === 0) {

            this.errors.push('Please select a problem detected');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateProblemActivitySpecifics(state: (err: boolean) => any, activity?: IProblemActivity): IValidateResult {
        var result: boolean = true;

        if (activity && activity.specify && activity.specifyRequired) {
            if (this.report.problemActivitySpecifics.length === 0) {

                this.errors.push('Please specify the problem detected');
                result = false;
            } else if (this.report.problemActivitySpecifics.length > CHAR_LIMIT) {
                this.errors.push(CHAR_LIMIT_ERROR_MESSAGE);
                result = false;
            }
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateProblemDescription(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (!this.report.problemDescriptionId ||
            this.report.problemDescriptionId === 0) {

            this.errors.push('Please select a problem description');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateProblemDescriptionSpecifics(state: (err: boolean) => any, description?: IProblemDescription): IValidateResult {
        var result: boolean = true;

        if (description && description.specify) {
            if (this.report.problemDescriptionSpecifics.length === 0) {

                this.errors.push('Please specify a problem description');
                result = false;
            } else if (this.report.problemDescriptionSpecifics.length > CHAR_LIMIT) {
                this.errors.push(CHAR_LIMIT_ERROR_MESSAGE);
                result = false;
            }
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateLeakLocation(state: (err: boolean) => any, isLeakOrHole: boolean): IValidateResult {
        var result: boolean = true;

        if (isLeakOrHole &&
            this.report.leakLocation.length === 0) {

            this.errors.push('Please enter a leak location');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateLeakType(state: (err: boolean) => any, isLeak: boolean): IValidateResult {
        var result: boolean = true;

        if (isLeak &&
            this.report.leakType.length === 0) {

            this.errors.push('Please enter a leak type');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        }
    }

    validateProblemLocation(state: (err: boolean) => any, unknown: boolean): IValidateResult {
        var result: boolean = true;

        if (!unknown && (
            !this.report.problemLocationId ||
            this.report.problemLocationId === 0)) {

            this.errors.push('Please select a problem location');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
