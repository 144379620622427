import { IProduct } from "./Product";

export interface IAccount {
    id?: number;
    account: string;
    name: string;
    description: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    accountType: string;
    companyCode: string;
    hideCorrespondence: boolean;
    
    products: IProduct[];
}

export class Account implements IAccount {
    public id?: number;
    public account: string = '';
    public name: string = '';
    public description: string = '';
    public address: string = '';
    public city: string = '';
    public state: string = '';
    public zip: string = '';
    public country: string = '';
    public accountType: string = '';
    public companyCode: string = '';
    public hideCorrespondence: boolean = false;

    public products: IProduct[] = [];
}