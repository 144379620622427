import styles from '../../../../scss/Table.module.scss';

import { Icon } from '@fluentui/react/lib/Icon';
import { IRowProps } from '../IRowProps';
import { IProductLine } from '../../../../data/models/ProductLine';

export function ProductLineRow(props: IRowProps<IProductLine>) {

    var editClicked = () => {
        props.onEdit(props.item);
    }

    return (
        <div className={`${styles.tableRow}`} key={props.index}>
            <div className={`${styles.tableCell}`}>
                {props.item.displayOrder}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.name}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.coordinator}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.reportHeader}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.enabled ? 'Yes' : 'No'}
            </div>
            <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                <span className={`${styles.link}`} onClick={editClicked}>
                    <Icon iconName="Edit" />
                </span>
            </div>
        </div>
    );
}
