import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer, IFilterOptions } from "./DataLayer";
import { IProblemLocation } from "./models/ProblemLocation";

export class ProblemLocationData {

    public static async create(item: IProblemLocation): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/problemlocations', item);
        return result;
    }

    public static async retrieveById(lineId: number): Promise<AxiosResponse<IProblemLocation[]>> {
        var result = await DataLayer.get<IProblemLocation[]>(`/problemlocations/${lineId}`);
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        } as IFilterOptions;
        
        var result = await DataLayer.post('/admin/problemlocations/filter', options);
        return result;
    }

    public static async update(item: IProblemLocation): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/problemlocations', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/problemlocations/${id}`);
        return result;
    }
}
