import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IUser } from "./models/User";

export class AdminData {

    public static async getReviewList(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post(`/admin/users/reviewlist`, options);
        return result;
    }

    public static async getUsers(): Promise<AxiosResponse<IUser[]>> {
        var result = await DataLayer.get<IUser[]>(`/admin/users`);
        return result;
    }

    public static async getUsersPage(pageInfo: IPageInfo, columns: IColumnData[], searchString: string): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ],
            searchString: searchString
        };
        
        var result = await DataLayer.post(`/admin/users/filter`, options);
        return result;
    }

    public static async saveUser(user: IUser): Promise<AxiosResponse<IUser[]>> {
        var result = await DataLayer.post(`/admin/users`, user);
        return result;
    }

    public static async updateUser(user: IUser): Promise<AxiosResponse<IUser[]>> {
        var result = await DataLayer.put(`/admin/users`, user);
        return result;
    }

    public static async changePassword(id: number, password: string): Promise<AxiosResponse<IUser[]>> {
        var data = { id: id, password: password };
        var result = await DataLayer.post(`/admin/users/changepassword`, data);
        return result;
    }

    public static async deleteUser(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/users/${id}`);
        return result;
    }
}
