import { IAccount } from "./Account";
import { IUser } from "./User";

export interface IUserAccount {
    accountId: number;
    account?: IAccount;
    userId: number;
    user?: IUser;
}

export class UserAccount implements IUserAccount {
    public accountId: number = 0;
    public userId: number = 0;
    public account?: IAccount;
    public user?: IUser;
}