import React from "react";
import styles from '../../../../scss/components/Editor.module.scss';

import { IEditorProps } from "./IEditorProps";
import { InputField } from "../InputField";
import { InputCheck } from "../InputCheck";
import { IReport } from "../../../../data/models/Report";

export class EditReturn extends React.Component<IEditorProps, {}> {
    render() {
        return (
            <>

                {this.props.children}

                <div className={`${styles.compactRow}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <h3>Return Details</h3>
                    </div>
                </div>

                <InputField
                    {...this.props}
                    label='Sample Available'
                    value={this.props.item.sampleAvailable}
                    update={(report, val) => report.sampleAvailable = val} />

                <InputCheck
                    {...this.props}
                    label="Return Box Requested"
                    value={this.props.item.returnBoxRequested}
                    update={(report: IReport, val: boolean) =>
                        (report.returnBoxRequested = val)
                    } />

                <InputField
                    {...this.props}
                    disabled={!this.props.item.returnBoxRequested}
                    label='Ship To Address'
                    value={this.props.item.shipToAddress}
                    update={(report, val) => report.shipToAddress = val} />

            </>
        );
    }
}
