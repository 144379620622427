import styles from '../../../../scss/Table.module.scss';

import { Icon } from '@fluentui/react/lib/Icon';
import { IRowProps } from '../IRowProps';
import { IProblemDescription } from '../../../../data/models/ProblemDescription';

export function ProblemDescriptionRow(props: IRowProps<IProblemDescription>) {

    var editClicked = () => {
        props.onEdit(props.item);
    }

    return (
        <div className={`${styles.tableRow}`} key={props.index}>
            <div className={`${styles.tableCell}`}>
                {props.item.productLine?.name}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.code}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.description}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.hole ? 'Yes' : 'No'}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.leak ? 'Yes' : 'No'}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.specify ? 'Yes' : 'No'}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.enabled ? 'Yes' : 'No'}
            </div>
            <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                <span className={`${styles.link}`} onClick={editClicked}>
                    <Icon iconName="Edit" />
                </span>
            </div>
        </div>
    );
}
