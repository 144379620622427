import React from "react";
import styles from '../../scss/components/Form.module.scss';

import { Checkbox, Label } from "@fluentui/react";
import { ICenterCheckProps } from "./IFormFunctionProps";
import { checkboxStyles, labelStyles } from '../../scss/ControlStyles';

export function CenterCheck(props: ICenterCheckProps) {

    const getLabel = () => {
        var label: JSX.Element;

        if (props.alternativeLabel && props.alternativeLabel.length > 0) {
            label = <span>{props.alternativeLabel}</span>;
        }
        else {
            label = <span>{props.defaultLabel}</span>;
        }

        if (props.required) {
            label = (
                <span>
                    <span className={styles.red}>*</span> {label}
                </span>
            )
        }

        return label;
    }

    const showColon = () => {
        var label = props.defaultLabel;

        if (props.alternativeLabel && props.alternativeLabel.length > 0) {
            label = props.alternativeLabel;
        }

        return !label.match(/.*[\?:]$/);
    }

    const getAside = () => {
        var aside: JSX.Element;

        if (props.showCheckIfYes) {
            aside =  <span className={`${styles.aside}`}><br />(check if yes)</span>;
        }
        else {
            aside = <></>;
        }

        return aside;
    }

    const checkboxClicked = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        var val = checked ? true : false;
        var stateUpdate = props.getStateUpdate(val);
        props.updateValue(stateUpdate);
    }

    if (props.show) {
        return (
            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg5} ${styles.lgOffset1}`}>
                    <div>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles} >
                                {getLabel()}{showColon() ? ':' : ''}
                                {getAside()}
                            </Label>
                        </div>

                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm1} ${styles.lg1} ${styles.center}`}>
                    <div className={styles.labelBox}>
                        <Checkbox
                            checked={props.value}
                            styles={checkboxStyles}
                            onChange={checkboxClicked} />
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <></>
        )
    }
}