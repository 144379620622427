import { DefaultButton, Label, TextField } from "@fluentui/react";
import React, { FormEvent } from "react";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { UserData } from "../../../data/UserData";
import styles from '../../../scss/pages/Login.module.scss';
import { buttonStyles, fullWidthButtonStyles, labelStyles, textBoxStyles } from "../../../scss/ControlStyles";
import { AxiosResponse } from "axios";

interface IState {
    username: string;
    usernameError: string;
    passwordSent: boolean;
}

export class UserResetPassword extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            username: '',
            usernameError: '',
            passwordSent: false,
        };
    }

    render() {
        return (
            <Authenticator redirectIfLoggedIn={true}>
                <div className={`${styles.login}`}>

                    <div className={`${styles.paddedRow}`}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>Use the form below to get a new temporary password emailed to you. You can then use it to log in, and then change to a password of your own choosing.</p>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`} hidden={!this.state.passwordSent}>
                        <div className={`${styles.col} ${styles.sm12}`}>
                            <p>Your password has been sent to you.</p>
                        </div>
                    </div>

                    <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12}`} hidden={!this.state.passwordSent}>
                                <DefaultButton
                                    text="Login"
                                    styles={buttonStyles}
                                    onClick={() => window.location.href='/login'}
                                />
                            </div>
                        </div>

                    <form id={'reset_form'} onSubmit={this.onReset.bind(this)} hidden={this.state.passwordSent}>
                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl2} ${styles.xlRight}`}>
                                <div className={styles.labelBox}>
                                    <Label styles={labelStyles}>Username:</Label>
                                </div>
                            </div>

                            <div className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.loginUsername}`}>
                                <TextField
                                    id={'txt_username'}
                                    styles={textBoxStyles}
                                    value={this.state.username}
                                    errorMessage={this.state.usernameError}
                                    onChange={this.usernameChanged.bind(this)}
                                />
                            </div>
                        </div>

                        <div className={`${styles.paddedRow}`}>
                            <div className={`${styles.col} ${styles.sm12} ${styles.xl9} ${styles.xlOffset2}`}>
                                <DefaultButton
                                    type="submit"
                                    text="Reset"
                                    styles={fullWidthButtonStyles}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </Authenticator>
        )
    }

    private usernameChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        if (!val) val = '';
        this.setState({ username: val });
    }

    private onReset(event?: FormEvent) {
        event?.preventDefault();
        var username = this.getUsername(event);
        var isValid = this.validateUsername(username);
        if (isValid) this.sendReset(username);
    }

    private getUsername(event?: FormEvent): string {
        var username = this.state.username;

        if (event && username.length === 0) {
            var target: any = event.target;
            username = target[0].value;
        }

        return username;
    }

    private validateUsername(username: string): boolean {
        var isValid = !!username && username.length > 0;
        if (!isValid) this.setState({ usernameError: 'A username is required' });
        return isValid;
    }

    private sendReset(username: string): void {
        UserData.sendReset(username)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) this.setState({passwordSent: true});
                else this.handleError(response);    
            });
    }

    private handleError(response: AxiosResponse<any>) {
        if (response.status === 404) {
            this.setState({ usernameError: 'We were unable to access your information. Please try again.' })
        }
        else {
            var err = 'An error occurred' + response.data;
            alert(err);
        }
    }
}
