import styles from '../../../scss/components/Paginator.module.scss';
import { IPageProps } from "./IPageProps";
import { DefaultButton } from '@fluentui/react';

export function Paginator(props: IPageProps) {
    const pageCountFloat = props.pageInfo.total / props.pageInfo.count;
    var pageCount = Math.floor(pageCountFloat);
    if (pageCountFloat > pageCount) pageCount++;

    if (pageCount < 2) {
        return (
            <></>
        );
    }

    const pageButtonStyles = {
        root: {
            maxHeight: '36px',
            maxWidth: '36px',
            minHeight: '36px',
            minWidth: '36px',
            margin: '0 4px',
            background: 'none',
            border: '2px solid black',
            borderRadius: '50%',
        }
    }

    const iconStyles = {
        root: {
            fontSize: '18px',
            fontWeight: '800'
        }
    }

    const prevIcon = {
        iconName: 'ChevronLeft',
        styles: iconStyles
    };

    const nextIcon = {
        iconName: 'ChevronRight',
        styles: iconStyles
    };

    const onPageChange = (i: number) => {
        var info = props.pageInfo;
        info.page = i;
        props.onPageChanged(info);
    }

    const renderPrev = () => {
        return (
            <DefaultButton
                key={0}
                disabled={props.pageInfo.page === 1}
                iconProps={prevIcon}
                onClick={(e: any) => onPageChange(props.pageInfo.page - 1)}
                styles={pageButtonStyles} />
        )
    }

    const renderNext = () => {
        return (
            <DefaultButton
                key={pageCount + 1}
                disabled={props.pageInfo.page === pageCount}
                iconProps={nextIcon}
                onClick={(e: any) => onPageChange(props.pageInfo.page + 1)}
                styles={pageButtonStyles} />
        )
    }

    const renderPage = (pageNum: number) => {
        return (
            <DefaultButton
                key={pageNum}
                disabled={pageNum === props.pageInfo.page}
                text={pageNum.toString()}
                onClick={(e: any) => onPageChange(pageNum)}
                styles={pageButtonStyles} />
        )
    }

    const renderPages = () => {
        var links: JSX.Element[] = [];
        var minPageNum = props.pageInfo.page - 2;
        var maxPageNum = props.pageInfo.page + 2;

        if (minPageNum > pageCount - 4) minPageNum = pageCount - 4;
        if (minPageNum < 1) minPageNum = 1;
        if (maxPageNum < 5) maxPageNum = 5;
        if (maxPageNum > pageCount) maxPageNum = pageCount;

        for (var i = minPageNum; i <= maxPageNum; i++) {
            links.push(renderPage(i))
        }

        return links.map((l) => l);
    }

    return (
        <div className={`${styles.paginator}`}>
            {renderPrev()}
            {renderPages()}
            {renderNext()}
        </div>
    )
}