import styles from '../../scss/components/Header.module.scss';

import { useContext } from 'react';
import { AuthData } from '../../data/AuthData';
import { AuthContext } from '../authProvider/AuthContext';
import { UserRoleEnum } from '../../data/enums/UserRoleEnum';
import { AuthenticationStatusEnum } from '../../data/enums/AuthenticationStatusEnum';

import {
  CommandBar,
  ICommandBarItemProps,
  IIconProps,
  Link
} from '@fluentui/react';
import { navItemStyles, navStyles, overflowButtonStyles } from '../../scss/ControlStyles';

export function Header() {
  const { authenticate, authenticationStatus, user } = useContext(AuthContext);

  const menuitems: ICommandBarItemProps[] = [];

  if (authenticationStatus === AuthenticationStatusEnum.LoggedIn) {
    menuitems.push(
      {
        key: "1",
        href: "/",
        name: "Product Performance",
        title: "Product Performance",
        buttonStyles: navItemStyles,
      },
      {
        key: "2",
        href: "/search",
        name: "Search",
        title: "Search",
        buttonStyles: navItemStyles,
      },
      {
        key: "3",
        href: "/account",
        name: "Account",
        title: "Account",
        buttonStyles: navItemStyles,
      },
      {
        key: "4",
        onClick: () => { AuthData.logout().then(() => { authenticate(); }); },
        name: "Log Out",
        title: "Log Out",
        buttonStyles: navItemStyles,
      }
    );
  }

  if (authenticationStatus === AuthenticationStatusEnum.LoggedOut) {
    menuitems.push(
      {
        key: "1",
        href: "/login",
        name: "Login",
        title: "Login",
        buttonStyles: navItemStyles,
      }
    );
  }

  if (user.role === UserRoleEnum.Administrator) {
    menuitems.push(
      {
        key: "6",
        href: "/admin",
        name: "Admin",
        title: "Administration",
        buttonStyles: navItemStyles,
      }
    );
  }

  const iconProps: IIconProps = {
    iconName: 'GlobalNavButton',
  };

  return (
    <header>
      <div className={`${styles.row}`}>
        <div className={`${styles.col} ${styles.sm3}`}>
          <Link href={"/"}>
            <img src='/img/logo.png' alt='Fresenius Kabi' className={`${styles.logo}`} />
          </Link>
        </div>

        <div className={`${styles.col} ${styles.sm3} ${styles.smOffset6} ${styles.xl9} ${styles.xlOffset0}`}>
          <CommandBar
            overflowButtonProps={{ styles: overflowButtonStyles, iconProps: iconProps }}
            farItems={[]}
            items={menuitems}
            styles={navStyles}
          />
        </div>
      </div>
    </header>
  );
}


