import { IDropdownOption } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IAccount } from "./models/Account";
import { UserData } from "./UserData";

export class AccountData {

    public static async create(item: IAccount): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/accounts', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IAccount[]>> {
        var result = await DataLayer.get<IAccount[]>('/admin/accounts');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };

        var result = await DataLayer.post('/admin/accounts/filter', options);
        return result;
    }

    public static async update(item: IAccount): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/accounts', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/accounts/${id}`);
        return result;
    }
    
    public static async getAccountOptions(userId: number): Promise<IDropdownOption[]> {
        var response = await UserData.userAccountProducts(userId);
        var user = response.data;

        return user.accounts.map((a) => {
            var id = a.id ? a.id : 0;
            return {
                data: a,
                key: id,
                index: id,
                text: `${a.account}: ${a.name} - ${a.city} - ${a.address}`,
            }
        })
    }
}
