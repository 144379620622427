import React from "react";
import styles from "../../../scss/components/Form.module.scss";
import { BaseForm } from "../baseForm.tsx/BaseForm";
import { IReport } from "../../../data/models/Report";
import { CenterCheck } from "../../inputs/CenterCheck";
import { TextInput } from "../../inputs/TextInput";
import { FileUpload } from "./FileUpload";
import { FileContent } from "use-file-picker/dist/interfaces";
import { IProductReturnProps } from "./IProductReturnFormProps";
import { TrippleCheck, TrippleCheckResponse } from "../../inputs/TrippleCheck";
import { IDropdownOption } from "@fluentui/react";

interface IState {
  shipToAddress: string;
  sampleAvailable: TrippleCheckResponse;
  sampleAvailableError: boolean;
  returnBoxRequested: boolean;
}

export class ProductReturnForm extends React.Component<
  IProductReturnProps,
  IState
> {
  constructor(props: IProductReturnProps) {
    super(props);

    this.state = {
      shipToAddress: "",
      sampleAvailable: new TrippleCheckResponse("Yes", "No", "Not Required"),
      sampleAvailableError: false,
      returnBoxRequested: false,
    };
  }

  getFiles = (): FileContent[] | undefined => {
    return undefined;
  };

  render() {
    return (
      <BaseForm
        line={this.props.line}
        createReport={this.createReport.bind(this)}
      >
        {this.props.children}

        <div className={`${styles.row}`}>
          <div className={`${styles.col} ${styles.sm12} ${styles.bgw}`}>
            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm12} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <h3>Product Return Details:</h3>
              </div>
            </div>

            <div className={`${styles.row}`}>
              <div
                className={`${styles.col} ${styles.sm10} ${styles.lg10} ${styles.lgOffset1}`}
              >
                <span className={`${styles.aside} ${styles.red}`}>
                  Not Required should ONLY be selected if you have been advised
                  from Fresenius Kabi, based on the type of failure mode and the
                  Fresenius Kabi investigation of prior samples, that this
                  sample is not required to be returned for evaluation. If the
                  sample is unavailable, please select No.
                </span>
              </div>
            </div>

            <TrippleCheck
              show={true}
              required={true}
              value={this.state.sampleAvailable}
              error={this.state.sampleAvailableError}
              defaultLabel={"Is a sample available for evaluation?"}
              getStateUpdate={(val: TrippleCheckResponse) => {
                return { sampleAvailable: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <CenterCheck
              showCheckIfYes
              show={this.state.sampleAvailable.selectedValue === 0}
              value={this.state.returnBoxRequested}
              defaultLabel={"Is a sample return box needed?"}
              getStateUpdate={(val: boolean) => {
                return { returnBoxRequested: val };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <TextInput
              multiline
              value={this.state.shipToAddress}
              show={this.state.returnBoxRequested}
              defaultLabel={`If the address to ship the box to is different than the account address${this.renderAccount(
                this.props.account
              )} please provide the ship to address`}
              getStateUpdate={(val: string) => {
                return { shipToAddress: val.substring(0,255) };
              }}
              updateValue={this.updateState.bind(this)}
            />

            <div
              className={`${styles.row}`}
              hidden={this.getSampleAvailable() != "Yes"}
            >
              <div
                className={`${styles.col} ${styles.sm10} ${styles.lg8} ${styles.lgOffset1}`}
              >
                <span className={`${styles.aside}`}>
                  If no box is required, a new return label will be emailed to
                  you separately once the complaint is processed. If a sample
                  box is required, a return label will be included in the sample
                  return box.
                </span>
              </div>
            </div>

            <FileUpload
              label="If you have pictures of the defective product, please attach them:"
              getFiles={(fileUpload) => {
                this.getFiles = fileUpload;
              }}
            />
          </div>
        </div>
      </BaseForm>
    );
  }

  updateState(update: any) {
    this.setState(update);
  }

  private createReport(report: IReport, isValid: boolean, errors: string[]) {
    var files = this.getFiles();

    isValid =
      this.validateSampleAvailable(this.state.sampleAvailable, errors) &&
      isValid;

    report.shipToAddress = this.state.shipToAddress;
    report.sampleAvailable = this.getSampleAvailable();
    report.returnBoxRequested = this.state.returnBoxRequested;

    this.props.createReport(report, isValid, errors, files);
  }

  validateSampleAvailable(
    trippleCheck: TrippleCheckResponse,
    errors: string[]
  ) {
    var err = trippleCheck.selectedValue < 0;

    if (err) {
      errors.push("Is a sample available for evaluation?");
    }

    this.setState({
      sampleAvailableError: err,
    });

    return !err;
  }

  getSampleAvailable() {
    var val =
      this.state.sampleAvailable.values[
        this.state.sampleAvailable.selectedValue
      ];
    return val;
  }

  private renderAccount(account?: IDropdownOption) {
    if (!account) return ", ";
    return ` : ${account.data.name} - ${account.data.city} - ${account.data.address}`;
  }
}
