
import React from 'react';
import styles from '../../../../scss/Base.module.scss';

import { IEditState } from '../IEditState';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditProblemLocationProps } from './IEditProblemLocationProps';
import { ProblemLocationData } from '../../../../data/ProblemLocationData';
import { IProblemLocation, ProblemLocation } from '../../../../data/models/ProblemLocation';

import {
    Checkbox,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Label,
    Modal,
    TextField
} from '@fluentui/react';

import {
    checkboxStyles,
    dropdownStyles,
    fullWidthButtonStyles,
    labelStyles,
    modalStyles,
    textBoxStyles
} from '../../../../scss/ControlStyles';

interface IState extends IEditState<IProblemLocation> {
    xOrigin: string;
    yOrigin: string;
    xLength: string;
    yLength: string;
}

export class EditProblemLocation extends React.Component<IEditProblemLocationProps, IState> {

    constructor(props: IEditProblemLocationProps) {
        super(props);

        var item = this.cloneItem();

        this.state = {
            item: item,
            xOrigin: '',
            yOrigin: '',
            xLength: '',
            yLength: '',
            show: this.props.show,
            queryModalProps: new QueryModalProps(),
        }
    }

    componentDidUpdate() {
        if (this.props.show && !this.state.show) {
            var newItem = this.cloneItem();
            this.setState({
                item: newItem,
                show: this.props.show,
                xOrigin: newItem.xOrigin.toString(),
                yOrigin: newItem.yOrigin.toString(),
                xLength: newItem.xLength.toString(),
                yLength: newItem.yLength.toString(),
            });
        }
    }

    render() {
        return (
            <Modal
                styles={modalStyles}
                isOpen={this.props.show}
                onDismiss={this.close.bind(this)}
            >
                <div className={styles.row}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
                        <h2>{!this.state.item.id ? 'New' : 'Edit'} Product</h2>
                    </div>
                </div>

                <div className={`${styles.row}`} hidden={!this.state.item.id}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>ID:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            defaultValue={this.state.item.id?.toString()}
                            disabled
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Code:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.code}
                            onChange={this.codeChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Description:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.item.description}
                            onChange={this.descriptionChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>X Origin (%):</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.xOrigin}
                            onChange={this.xOriginChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Y Origin (%):</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.yOrigin}
                            onChange={this.yOriginChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Width (%):</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.xLength}
                            onChange={this.xLengthChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Height (%):</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <TextField
                            styles={textBoxStyles}
                            value={this.state.yLength}
                            onChange={this.yLengthChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Product Line:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <Dropdown
                            styles={dropdownStyles}
                            selectedKey={this.state.item.productLineId}
                            options={this.props.lines}
                            onChange={this.productLineChanged.bind(this)}
                        />
                    </div>
                </div>

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                        <div className={styles.labelBox}>
                            <Label styles={labelStyles}>Enabled:</Label>
                        </div>
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
                        <div className={styles.labelBox}>
                            <Checkbox
                                styles={checkboxStyles}
                                checked={this.state.item.enabled}
                                onChange={this.enabledChanged.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div className={`${styles.row} ${styles.center}`}>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg4}`}>
                        <DefaultButton
                            text='SAVE'
                            styles={fullWidthButtonStyles}
                            onClick={this.saveItem.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${styles.lg4}`} hidden={!this.state.item.id}>
                        <DefaultButton
                            text='DELETE'
                            styles={fullWidthButtonStyles}
                            onClick={this.showConfirmDelete.bind(this)} />
                    </div>
                    <div className={`${styles.col} ${styles.sm12} ${!this.state.item.id ? styles.lg6 : styles.lg4}`}>
                        <DefaultButton
                            text='CANCEL'
                            styles={fullWidthButtonStyles}
                            onClick={this.close.bind(this)} />
                    </div>
                </div>

                <QueryModal {...this.state.queryModalProps} />

            </Modal >
        );
    }

    private cloneItem(): IProblemLocation {
        var clone = new ProblemLocation();

        if (this.props.items && this.props.id > 0 && this.props.items.length > 0) {
            var item = this.props.items.filter((item) => item.id === this.props.id);
            Object.assign(clone, item[0]);
        }

        return clone;
    }

    private codeChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.code = val ? val : '';
        this.setState({ item: item });
    }

    private descriptionChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        var item = this.state.item;
        item.description = val ? val : '';
        this.setState({ item: item });
    }

    private xOriginChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = this.getNumber(val);
        var item = this.state.item;
        item.xOrigin = val.length > 0 ? parseFloat(val) : 0;

        this.setState({
            item: item,
            xOrigin: val,
        });
    }

    private yOriginChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = this.getNumber(val);
        var item = this.state.item;
        item.yOrigin = val.length > 0 ? parseFloat(val) : 0;

        this.setState({
            item: item,
            yOrigin: val,
        });
    }

    private xLengthChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = this.getNumber(val);
        var item = this.state.item;
        item.xLength = val.length > 0 ? parseFloat(val) : 0;

        this.setState({
            item: item,
            xLength: val,
        });
    }

    private yLengthChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = this.getNumber(val);
        var item = this.state.item;
        item.yLength = val.length > 0 ? parseFloat(val) : 0;

        this.setState({
            item: item,
            yLength: val,
        });
    }

    private enabledChanged(ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) {
        var item = this.state.item;
        item.enabled = checked ? true : false;
        this.setState({ item: item });
    }

    private productLineChanged(ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) {
        if (!option || !option.index) return;
        var item = this.state.item;
        item.productLineId = option.index;
        this.setState({ item: item });
    }

    private getNumber(val: string | undefined): string {
        if (!val) return '';

        var r = /([0-9]*[.])?[0-9]*/;

        var matches = val.match(r);

        if (!matches || matches.length === 0) {
            return ''
        }

        return matches[0];
    }

    closeQueryModal() {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });
    }

    showConfirmDelete() {
        if (!this.state.item.id) return;
        var id = this.state.item.id;

        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete This Product?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.delete(id);

        this.setState({ queryModalProps: queryModalProps });
    }

    private delete(id: number) {
        this.closeQueryModal();

        ProblemLocationData.delete(id)
            .then(() => this.close());
    }

    private saveItem() {
        if (this.state.item.id) this.update();
        else this.create();
    }

    private create() {
        ProblemLocationData.create(this.state.item)
            .then(() => this.close());
    }

    private update() {
        ProblemLocationData.update(this.state.item)
            .then(() => this.close());
    }

    private close() {
        this.props.close();
        this.props.refresh();
        this.setState({ show: false });
    }
}
