import React from 'react';
import styles from '../../../scss/components/Editor.module.scss';
import { IOptionalFieldProps } from './IOptionalFieldProps';

import { 
    Checkbox, 
    TextField 
} from '@fluentui/react';

import { 
    adminTextBoxStyles, 
    checkboxStyles
} from '../../../scss/ControlStyles';

export function OptionalField(props: IOptionalFieldProps) {

    const onEnableChanged = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        props.onUpdate({
            show: checked ? true : false, 
            required: props.required, 
            altText: props.altText
        });
    }

    const onRequiredChanged = (ev?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
        props.onUpdate({
            show: props.show, 
            required: checked ? true : false, 
            altText: props.altText
        });
    }

    const onAlternativeTextChanged = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
        props.onUpdate({
            show: props.show, 
            required: props.required, 
            altText: val ? val : ''
        });
    }


    return (
        <div className={`${styles.compactRow}`} >
            <div className={`${styles.col} ${styles.sm2} ${styles.lg1} ${styles.lgOffset1}`}>
                <div className={styles.compactLabelBox}>
                    <Checkbox
                        styles={checkboxStyles}
                        checked={props.show}
                        onChange={onEnableChanged}
                    />
                </div>
            </div>

            <div className={`${styles.col} ${styles.sm2} ${styles.lg1}`}>
                <div className={styles.compactLabelBox}>
                    <Checkbox
                        checked={props.required}
                        disabled={!props.show}
                        styles={checkboxStyles}
                        onChange={onRequiredChanged}
                    />
                </div>
            </div>
            
            <div className={`${styles.col} ${styles.sm11} ${styles.lg9}`}>
                <TextField
                    styles={adminTextBoxStyles}
                    disabled={!props.show}
                    value={props.altText}
                    placeholder={props.defaultText}
                    onChange={onAlternativeTextChanged}
                />
            </div>
        </div >
    )
}