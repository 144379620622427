import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { INeedleDropdownOption } from "./models/NeedleDropdownOption";

const UrlEndpoint = "needledropdownoptions";

export class NeedleDropdownOptionsData {

    public static async create(item: INeedleDropdownOption): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post(`/admin/${UrlEndpoint}`, item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<INeedleDropdownOption[]>> {
        var result = await DataLayer.get<INeedleDropdownOption[]>(`/admin/${UrlEndpoint}`);
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post(`/admin/${UrlEndpoint}/filter`, options);
        return result;
    }

    public static async update(item: INeedleDropdownOption): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put(`/admin/${UrlEndpoint}`, item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/${UrlEndpoint}/${id}`);
        return result;
    }
}
