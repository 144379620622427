import styles from './ImageContainer.module.scss';
import { IProductLine } from "../../../data/models/ProductLine";
import { IImageContainerProps } from './IImageContainerProps';
import { IProblemLocation } from '../../../data/models/ProblemLocation';

export function ImageContainer(props: IImageContainerProps) {

    const locationStyles = (loc: IProblemLocation) => {
        return {
            left: `${loc.xOrigin}%`,
            bottom: `${loc.yOrigin}%`,
            width: `${loc.xLength}%`,
            height: `${loc.yLength}%`,
        };
    }

    const locationClass = (loc: IProblemLocation) => {
        if (props.selectedLocation && props.selectedLocation.id === loc.id) {
            return `${styles.location} ${styles.selected}`;
        }

        return styles.location;
    }

    const renderLocations = (line: IProductLine | undefined) => {
        var locations = line?.problemLocations ? line.problemLocations : [];

        return locations.map((loc, i) => {
            return (
                <span
                    id={`location_${i}`}
                    key={i}
                    style={locationStyles(loc)}
                    className={`${styles.link} ${locationClass(loc)}`}
                    onClick={props.onLocationSelected.bind(null, loc)}
                />
            );
        });
    }

    return (
        <div className={`${styles.row}`}>
            <div className={`${styles.col} ${styles.sm12} ${styles.lg10}  ${styles.lgOffset1}`}>
                <div className={`${styles.imageContainer}`}>
                    <img src={props.line?.image} alt='Product' />

                    {renderLocations(props.line)}
                </div>
            </div>
        </div>
    )
}
