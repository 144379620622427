import styles from '../../../scss/components/Form.module.scss';

import { useFilePicker } from 'use-file-picker';
import { FileContent } from "use-file-picker/dist/interfaces";
import { DefaultButton, Label } from "@fluentui/react";
import { adminButtonStyles, labelStyles } from "../../../scss/ControlStyles";

export interface IFileUploadProps {
    label: string;
    getFiles: (func: () => FileContent[]) => void;
}

export function DataFileUpload(props: IFileUploadProps) {

    const [openFileSelector, { loading, filesContent, clear }] = useFilePicker({
        readAs: 'ArrayBuffer'
    });

    var browseClick = () => {
        openFileSelector();
    };

    props.getFiles((): FileContent[] => {
        return filesContent;
    });

    if (loading) {
        return <div>Loading...</div>;
    }

    var renderFiles = () => {
        return filesContent.map((file) => {
            return (
                <div >
                    <Label styles={labelStyles}>{file.name}</Label>
                </div>
            )
        });
    }

    var renderNoFiles = () => {
        return (
            <div >
                <Label styles={labelStyles}>No Files Chosen</Label>
            </div>
        )
    }

    return (
        <div>
            <div className={`${styles.row}`}>
                <div className={`${styles.col} ${styles.sm6} ${styles.lg4}`}>
                    <div>
                        <div>
                            <Label styles={labelStyles} htmlFor='custRef'>{props.label}</Label>
                        </div>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm6} ${styles.lg4}`}>
                    <div>
                        {filesContent && filesContent.length ? renderFiles() : renderNoFiles()}
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm10} ${styles.lg2}`}>
                    <div>
                        <DefaultButton
                            text='Browse'
                            onClick={browseClick}
                            styles={adminButtonStyles} />
                    </div>
                </div>
            </div>
        </div>

    );
}
