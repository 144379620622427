import { NeedleDropdownOption } from "./NeedleDropdownOption";
import { ProblemActivity } from "./ProblemActivity";
import { ProblemDescription } from "./ProblemDescription";
import { ProblemLocation } from "./ProblemLocation";

export interface IProductLine {
    id?: number;
    name: string;
    image: string;
    mobileImage: string;
    coordinator: string;
    reportHeader: string;
    displayOrder: number;
    enabled: boolean;
    excludeFromSap: boolean;

    // General Incident Information

    showDuringDonation: boolean;
    duringDonationRequired: boolean;
    duringDonationAltText: string;

    showAdverseEvent: boolean;
    adverseEventRequired: boolean;
    adverseEventAltText: string;

    showCompleted: boolean;
    completedRequired: boolean;
    completedAltText: string;

    showStopped: boolean;
    stoppedRequired: boolean;
    stoppedAltText: string;

    showSoftGoods: boolean;
    softGoodsRequired: boolean;
    softGoodsAltText: string;

    showLost: boolean;
    lostRequired: boolean;
    lostAltText: string;

    showDrugAdministered: boolean;
    drugAdministeredRequired: boolean;
    drugAdministeredAltText: string;

    showDrugUsed: boolean;
    drugUsedRequired: boolean;
    drugUsedAltText: string;

    showIsCytotoxic: boolean;
    isCytotoxicRequired: boolean;
    isCytotoxicAltText: string;

    showManufacturer: boolean;
    manufacturerRequired: boolean;
    manufacturerAltText: string;

    showContainer: boolean;
    containerRequired: boolean;
    containerAltText: string;

    showClinicalOrPatientMaterial: boolean;
    clinicalOrPatientMaterialRequired: boolean;
    clinicalOrPatientMaterialAltText: string;

    // Correspondence

    showCorrespondence: boolean;

    // Product Details

    showProductCode: boolean;
    productCodeRequired: boolean;
    productCodeAltText: string;

    showSerialNumber: boolean;
    serialNumberRequired: boolean;
    serialNumberAltText: string;

    showRbcLabelId: boolean;
    rbcLabelIdRequired: boolean;
    rbcLabelIdAltText: string;

    showSoftwareVersion: boolean;
    softwareVersionRequired: boolean;
    softwareVersionAltText: string;

    showProductDescription: boolean;
    productDescriptionRequired: boolean;
    productDescriptionAltText: string;

    showWbProcessed: boolean;
    wbProcessedRequired: boolean;
    wbProcessedAltText: string;

    showVolPlasma: boolean;
    volPlasmaRequired: boolean;
    volPlasmaAltText: string;

    showDonorBleedNumber: boolean;
    donorBleedNumberRequired: boolean;
    donorBleedNumberAltText: string;

    showPasLotNumber: boolean;
    pasLotNumberRequired: boolean;
    pasLotNumberAltText: string;

    showBatchTagTime: boolean;
    batchTagTimeRequired: boolean;
    batchTagTimeAltText: string;

    showBatchTagNumber: boolean;
    batchTagNumberRequired: boolean;
    batchTagNumberAltText: string;

    showLineNumber: boolean;
    lineNumberRequired: boolean;
    lineNumberAltText: string;

    showVideoJetNumber: boolean;
    videoJetNumberRequired: boolean;
    videoJetNumberAltText: string;

    showFilterNumber: boolean;
    filterNumberRequired: boolean;
    filterNumberAltText: string;

    // Filter Issue Details

    showFilterIssueDetails: boolean;

    // Needle Issue Details

    showNeedleIssueDetails: boolean;

    // Problem Details

    showAlarmsExperienced: boolean;
    alarmsExperiencedRequired: boolean;
    alarmsExperiencedAltText: string;

    returnDetailsNotes: string;

    problemLocations: ProblemLocation[];
    problemActivities: ProblemActivity[];
    problemDescriptions: ProblemDescription[];

    needleDropdownOptions: NeedleDropdownOption[];

    // ProblemLocations
    problemLocationPackaging: boolean;
    problemLocationPackagingCode: string;
    problemLocationTubing: boolean;
    problemLocationTubingCode: string;

    deviceType: string;

}

export class ProductLine implements IProductLine {
    id?: number;
    name: string = '';
    image: string = '';
    mobileImage: string = '';
    solution: boolean = false;
    coordinator: string = '';
    reportHeader: string = '';
    displayOrder: number = 1;
    enabled: boolean = true;
    excludeFromSap: boolean = false;
    
    duringDonationRequired: boolean = false;
    adverseEventRequired: boolean = false;
    completedRequired: boolean = false;
    stoppedRequired: boolean = false;
    softGoodsRequired: boolean = false;
    lostRequired: boolean = false;
    drugAdministeredRequired: boolean = false;
    drugUsedRequired: boolean = false;
    isCytotoxicRequired: boolean = false;
    manufacturerRequired: boolean = false;
    containerRequired: boolean = false;
    productCodeRequired: boolean = false;
    rbcLabelIdRequired: boolean = false;
    serialNumberRequired: boolean = false;
    softwareVersionRequired: boolean = false;
    productDescriptionRequired: boolean = false;
    wbProcessedRequired: boolean = false;
    volPlasmaRequired: boolean = false;
    donorBleedNumberRequired: boolean = false;
    pasLotNumberRequired: boolean = false;
    batchTagTimeRequired: boolean = false;
    batchTagNumberRequired: boolean = false;
    lineNumberRequired: boolean = false;
    videoJetNumberRequired: boolean = false;
    filterNumberRequired: boolean = false;
    alarmsExperiencedRequired: boolean = false;
    clinicalOrPatientMaterialRequired = false;

    showDuringDonation: boolean = false;
    duringDonationAltText: string = '';
    showAdverseEvent: boolean = false;
    adverseEventAltText: string = '';
    showCompleted: boolean = false;
    completedAltText: string = '';
    showStopped: boolean = false;
    stoppedAltText: string = '';
    showSoftGoods: boolean = false;
    softGoodsAltText: string = '';
    showLost: boolean = false;
    lostAltText: string = '';
    showDrugAdministered: boolean = false;
    drugAdministeredAltText: string = '';
    showDrugUsed: boolean = false;
    drugUsedAltText: string = '';
    showIsCytotoxic: boolean = false;
    isCytotoxicAltText: string = '';
    manufacturerAltText: string = '';
    showManufacturer: boolean = false;
    showContainer: boolean = false;
    containerAltText: string = '';
    showClinicalOrPatientMaterial = false;
    clinicalOrPatientMaterialAltText = '';
    showCorrespondence: boolean = false;

    showProductCode: boolean = false;
    productCodeAltText: string = '';
    showRbcLabelId: boolean = false;
    rbcLabelIdAltText: string = '';
    showSerialNumber: boolean = false;
    serialNumberAltText: string = '';
    showSoftwareVersion: boolean = false;
    softwareVersionAltText: string = '';
    showProductDescription: boolean = false;
    productDescriptionAltText: string = '';
    showWbProcessed: boolean = false;
    wbProcessedAltText: string = '';
    showVolPlasma: boolean = false;
    volPlasmaAltText: string = '';
    showDonorBleedNumber: boolean = false;
    donorBleedNumberAltText: string = '';
    showPasLotNumber: boolean = false;
    pasLotNumberAltText: string = '';
    showBatchTagTime: boolean = false;
    batchTagTimeAltText: string = '';
    showBatchTagNumber: boolean = false;
    batchTagNumberAltText: string = '';
    showLineNumber: boolean = false;
    lineNumberAltText: string = '';
    showVideoJetNumber: boolean = false;
    videoJetNumberAltText: string = '';
    showFilterNumber: boolean = false;
    filterNumberAltText: string = '';

    showFilterIssueDetails: boolean = false;

    showNeedleIssueDetails = false;
    
    showAlarmsExperienced: boolean = false;
    alarmsExperiencedAltText: string = '';
    returnDetailsNotes: string = '';
    
    problemLocations: ProblemLocation[] = [];
    problemActivities: ProblemActivity[] = [];
    problemDescriptions: ProblemDescription[] = [];

    needleDropdownOptions: NeedleDropdownOption[] = [];

    problemLocationPackaging = false;
    problemLocationPackagingCode = '';
    problemLocationTubing = false;
    problemLocationTubingCode = '';
    
    deviceType = '';

}
