import React from 'react';
import styles from '../../../../scss/Base.module.scss';
import { Icon, Label, TextField } from '@fluentui/react';
import { QueryModal } from '../../../queryModal/QueryModal';
import { QueryModalProps } from '../../../queryModal/QueryModalProps';
import { IEditUserUserAccountProps } from './IEditUserUserAccountProps';
import { IUserAccount, UserAccount } from '../../../../data/models/UserAccount';
import { IUser, User } from '../../../../data/models/User';
import { EditUserAccount } from '../editUserAccount/EditUserAccount';
import { UserAccountData } from '../../../../data/UserAccountData';

import {
    editFormDeleteIconStyles,
    editFormAddIconStyles,
    labelStyles,
    textBoxStyles
} from '../../../../scss/ControlStyles';

interface IState {
    showEditUserAccount: boolean;
    queryModalProps: QueryModalProps;
    newUserAccount: IUserAccount
}

export class EditUserUserAccount extends React.Component<IEditUserUserAccountProps, IState> {

    constructor(props: IEditUserUserAccountProps) {
        super(props);

        this.state = {
            showEditUserAccount: false,
            newUserAccount: new UserAccount(),
            queryModalProps: new QueryModalProps(),
        }
    }

    render() {
        var item = this.getItem();

        return (
            <div className={`${styles.row}`} hidden={item.id === 0}>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}>
                    <div className={styles.labelBox}>
                        <Label styles={labelStyles}>Accounts:</Label>
                    </div>
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg8} ${styles.right}`}>
                    {item.accounts.map((a) => {
                        return (
                            <div className={`${styles.unpaddedRow}`} key={a.id}>
                                <div className={`${styles.col} ${styles.sm12} ${styles.lg1} ${styles.lgRight}`}>
                                    <span
                                        onClick={this.showConfirmDelete.bind(this, a.id ? a.id : 0)}
                                        className={`${styles.link} ${styles.labelBox}`}>
                                        <Icon
                                            styles={editFormDeleteIconStyles}
                                            iconName="Delete"
                                            className={styles.red} />
                                    </span>
                                </div>
                                <div className={`${styles.col} ${styles.sm12} ${styles.lg11}`}>
                                    <TextField
                                        styles={textBoxStyles}
                                        defaultValue={`${a.account}: ${a.name}`}
                                        disabled
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={`${styles.col} ${styles.sm12} ${styles.lg1} ${styles.right}`}>
                    <span className={`${styles.link} ${styles.labelBox}`} onClick={this.openEditUserAccount.bind(this)}>
                        <Icon
                            styles={editFormAddIconStyles}
                            iconName="CircleAddition" />
                    </span>
                </div>

                <QueryModal {...this.state.queryModalProps} />

                <EditUserAccount
                    show={this.state.showEditUserAccount}
                    users={[this.getItem()]}
                    accounts={this.props.accounts}
                    close={this.closeEditUserAccount.bind(this)}
                    refresh={this.props.refresh} />

            </div>
        );
    }

    getItem(): IUser {
        var items = this.props.users.filter((u) => u.id === this.props.id);

        if (items.length > 0) {
            return items[0];
        }
        else {
            return new User();
        }
    }

    showConfirmDelete(id: number) {
        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete this UserAccount?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal();
        queryModalProps.onOkClicked = (val: string) => this.deleteUserAccount(id);

        this.setState({ queryModalProps: queryModalProps });
    }

    closeQueryModal() {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });
    }

    private deleteUserAccount(id: number) {
        var item = this.getItem();
        var userId = item.id ? item.id: 0;

        UserAccountData.delete(userId, id)
            .then(() => {
                this.closeQueryModal();
                this.props.refresh();
            });
    }

    private openEditUserAccount() {
        var item = this.getItem();
        var userId = item.id ? item.id: 0;
        var newUserAccount = new UserAccount();
        newUserAccount.userId = userId;
        newUserAccount.user = item;

        this.setState({
            showEditUserAccount: true,
            newUserAccount: newUserAccount,
        });
    }

    closeEditUserAccount() {
        this.setState({
            showEditUserAccount: false
        });
    }
}
