import React from "react";
import styles from '../../../scss/Table.module.scss';

import { Authenticator } from "../../../components/authenticator/Authenticator";
import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { IUser, User } from "../../../data/models/User";
import { AxiosResponse } from "axios";
import { AdminData } from "../../../data/AdminData";
import { EditUser } from "../../../components/admin/editors/editUser/EditUser";
import { DefaultButton, Icon, TextField } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { IAdminPageState } from "../IAdminPageState";
import { IAccount } from "../../../data/models/Account";
import { AccountData } from "../../../data/AccountData";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { UserRow } from "../../../components/admin/rows/userRow/UserRow";
import { Exporter } from "../../../components/exporter/Exporter";
import { adminButtonStyles } from "../../../scss/ControlStyles";

interface IState extends IAdminPageState<IUser> {
    accounts: IAccount[];
    searchString: string;
}

export class Users extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            accounts: [],
            editId: 0,
            showEdit: false,
            searchString: '',
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            columns: [
                { title: 'Username', column: 'username' },
                { title: 'First Name', column: 'firstname' },
                { title: 'Last Name', column: 'lastname' },
                { title: 'Email', column: 'email' },
                { title: 'Phone', column: 'phone' },
                { title: 'Status', column: 'status' },
                { title: 'Role', column: 'role' },
                { title: 'Last Login', column: 'lastLogin' },
            ],
        };
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm10}`}>
                                <h1>
                                    Site Users
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.right}`}>
                                <span className={`${styles.link} ${styles.createNew}`} onClick={this.createUser.bind(this)}>
                                    <Icon iconName="CircleAddition" />
                                </span>
                            </div>
                        </div>

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm7} ${styles.md8}  ${styles.lg3} ${styles.lgOffset7}`}>
                                <TextField
                                    value={this.state.searchString} 
                                    onChange={this.searchStringChanged.bind(this)} />
                                    
                            </div>
                            <div className={`${styles.col} ${styles.sm5} ${styles.md4}  ${styles.lg2}`}>
                                <DefaultButton 
                                    styles={adminButtonStyles}
                                    text={'Search:'} 
                                    onClick={() => this.loadData()} />
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderRows(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <Exporter type='users' />
                    </div>

                    <EditUser
                        id={this.state.editId}
                        items={this.state.items}
                        accounts={this.state.accounts}
                        show={this.state.showEdit}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadUsers.bind(this, this.state.pageInfo)} />

                </AdminNav>
            </Authenticator>
        );
    }

    loadData() {
        this.loadUsers(this.state.pageInfo);
    }

    loadUsers(pageInfo: IPageInfo) {
        AdminData.getUsersPage(pageInfo, this.state.columns, this.state.searchString)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });
                    this.loadAccounts();
                }
                else {
                    console.log('error getting users');
                    console.log(response.data);
                }
            });
    }

    loadAccounts() {
        AccountData.retrieve()
            .then((response: AxiosResponse<IAccount[]>) => {
                this.setState({
                    accounts: response.data,
                })
            })
    }

    renderRows(users: IUser[]) {
        return users.map((user, i) => {
            return (
                <UserRow
                    key={i}
                    index={i}
                    item={user}
                    onEdit={this.openEdit.bind(this)} />
            );
        })
    }

    openEdit(item: IUser) {
        var userId = item.id ? item.id : 0;

        this.setState({
            showEdit: true,
            editId: userId,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    createUser() {
        var newUser = new User();
        this.openEdit(newUser);
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadUsers(info);
    }

    private searchStringChanged(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) {
        val = val ? val : '';
        this.setState({searchString: val});
    }
}