import styles from '../../../../scss/Table.module.scss';

import { Icon } from '@fluentui/react/lib/Icon';
import { IRowProps } from '../IRowProps';
import { IUser } from '../../../../data/models/User';
import { DateHelper } from '../../../../data/helpers/dateHelper';

export function UserReviewRow(props: IRowProps<IUser>) {

    var editClicked = () => {
        props.onEdit(props.item);
    }

    return (
        <div className={`${styles.tableRow}`} key={props.index}>
            <div className={`${styles.tableCell}`}>
                {props.item.username}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.firstname}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.lastname}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.email}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.phone}
            </div>
            <div className={`${styles.tableCell}`}>
                {DateHelper.formatDate(props.item.createdAt)}
            </div>
            <div className={`${styles.tableCell}`}>
                {props.item.status}
            </div>
            <div className={`${styles.tableCell} ${styles.editCell} ${styles.right}`}>
                <span className={`${styles.link}`} onClick={editClicked}>
                    <Icon iconName="Edit" />
                </span>
            </div>
        </div>
    );
}
