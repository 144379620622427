import React from "react";
import styles from "../../../../scss/components/Editor.module.scss";
import {
  Checkbox,
  DefaultButton,
  IDropdownOption,
  Label,
  Modal,
  TextField,
} from "@fluentui/react";
import {
  checkboxStyles,
  fullWidthButtonStyles,
  labelStyles,
  modalStyles,
  textBoxStyles,
} from "../../../../scss/ControlStyles";
import { QueryModal } from "../../../queryModal/QueryModal";
import { QueryModalProps } from "../../../queryModal/QueryModalProps";
import { IProductLine, ProductLine } from "../../../../data/models/ProductLine";
import { LineData } from "../../../../data/LineData";
import { IEditProps } from "../IEditProps";
import { IEditState } from "../IEditState";
import { EditGeneralInformation } from "./EditGeneralInformation";
import { Selector } from "../../../selector/selector";
import { nanoid } from "@reduxjs/toolkit";

const MEDICAL = "Medical";
const LABORATORY = "Laboratory";
const medical: IDropdownOption = {
  key: MEDICAL,
  index: 1,
  text: MEDICAL,
};
const laboratory: IDropdownOption = {
  key: LABORATORY,
  index: 2,
  text: LABORATORY,
};
export const DEVICE_TYPE_DROPDOWNS: IDropdownOption[] = [medical, laboratory];

export class EditProductLine extends React.Component<
  IEditProps<IProductLine>,
  IEditState<IProductLine>
> {
  constructor(props: IEditProps<IProductLine>) {
    super(props);

    var item = this.cloneItem();

    this.state = {
      item: item,
      show: this.props.show,
      queryModalProps: new QueryModalProps(),
    };
  }

  componentDidUpdate() {
    if (this.props.show && !this.state.show) {
      var newItem = this.cloneItem();
      this.setState({
        item: newItem,
        show: this.props.show,
      });
    }
  }

  render() {
    return (
      <Modal
        styles={modalStyles}
        isOpen={this.props.show}
        onDismiss={this.close.bind(this)}
      >
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.center}`}>
            <h2>{!this.state.item.id ? "New" : "Edit"} Product Line</h2>
          </div>
        </div>

        <div className={`${styles.row}`} hidden={!this.state.item.id}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>ID:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              defaultValue={this.state.item.id?.toString()}
              disabled
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Name:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              value={this.state.item.name}
              onChange={this.nameChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Coordinator:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              value={this.state.item.coordinator}
              onChange={this.coordinatorChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Report Header:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              value={this.state.item.reportHeader}
              onChange={this.reportHeaderChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Desktop Image Path:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              value={this.state.item.image}
              onChange={this.imageChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Display Order:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <TextField
              styles={textBoxStyles}
              value={
                this.state.item.displayOrder
                  ? this.state.item.displayOrder.toString()
                  : ""
              }
              onChange={this.displayOrderChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.compactRow}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Device Type</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <Selector
              options={DEVICE_TYPE_DROPDOWNS}
              selectedOption={{
                key: this.state.item.deviceType ?? nanoid(),
                text: "",
              }}
              onSelected={this.deviceTypeChanged.bind(this)}
            />
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Enabled:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <div className={styles.labelBox}>
              <Checkbox
                styles={checkboxStyles}
                checked={this.state.item.enabled}
                onChange={this.enabledChanged.bind(this)}
              />
            </div>
          </div>
        </div>

        <div className={`${styles.row}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg3} ${styles.lgRight}`}
          >
            <div className={styles.labelBox}>
              <Label styles={labelStyles}>Exclude From SAP:</Label>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.lg9}`}>
            <div className={styles.labelBox}>
              <Checkbox
                styles={checkboxStyles}
                checked={this.state.item.excludeFromSap}
                onChange={this.excludeFromSapChanged.bind(this)}
              />
            </div>
          </div>
        </div>
        {/** EditGeneralInformation */}
        <EditGeneralInformation
          item={this.state.item}
          onChange={this.itemChanged.bind(this)}
        />

        <div className={`${styles.row} ${styles.center}`}>
          <div
            className={`${styles.col} ${styles.sm12} ${
              !this.state.item.id ? styles.lg6 : styles.lg4
            }`}
          >
            <DefaultButton
              text="SAVE"
              styles={fullWidthButtonStyles}
              onClick={this.saveItem.bind(this)}
            />
          </div>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.lg4}`}
            hidden={!this.state.item.id}
          >
            <DefaultButton
              text="DELETE"
              styles={fullWidthButtonStyles}
              onClick={this.showConfirmDelete.bind(this)}
            />
          </div>
          <div
            className={`${styles.col} ${styles.sm12} ${
              !this.state.item.id ? styles.lg6 : styles.lg4
            }`}
          >
            <DefaultButton
              text="CANCEL"
              styles={fullWidthButtonStyles}
              onClick={this.close.bind(this)}
            />
          </div>
        </div>

        <QueryModal {...this.state.queryModalProps} />
      </Modal>
    );
  }

  private deviceTypeChanged(option: IDropdownOption) {
    var item = this.state.item;
    item.deviceType = option.text ? option.text : "";
    this.itemChanged(item);
  }

  private cloneItem(): IProductLine {
    var clone = new ProductLine();

    if (this.props.items && this.props.id > 0 && this.props.items.length > 0) {
      var item = this.props.items.filter((item) => item.id === this.props.id);
      Object.assign(clone, item[0]);
    }

    return clone;
  }

  private nameChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.state.item;
    item.name = val ? val : "";
    this.itemChanged(item);
  }

  private coordinatorChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.state.item;
    item.coordinator = val ? val : "";
    this.itemChanged(item);
  }

  private reportHeaderChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.state.item;
    item.reportHeader = val ? val : "";
    this.itemChanged(item);
  }

  private imageChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.state.item;
    item.image = val ? val : "";
    this.itemChanged(item);
  }

  // private mobileImageChanged(
  //   ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   val: string | undefined
  // ) {
  //   var item = this.state.item;
  //   item.mobileImage = val ? val : "";
  //   this.itemChanged(item);
  // }

  private displayOrderChanged(
    ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    val: string | undefined
  ) {
    var item = this.state.item;
    val = val ? val : "1";
    var dispayOrder = parseInt(val);
    item.displayOrder = dispayOrder ? dispayOrder : 1;
    this.itemChanged(item);
  }

  private enabledChanged(
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean | undefined
  ) {
    var item = this.state.item;
    item.enabled = checked ? true : false;
    this.setState({ item: item });
  }

  private excludeFromSapChanged(
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    checked?: boolean | undefined
  ) {
    var item = this.state.item;
    item.excludeFromSap = checked ? true : false;
    this.setState({ item: item });
  }

  itemChanged(item: IProductLine) {
    this.setState({ item: item });
  }

  closeQueryModal() {
    var queryModalProps = new QueryModalProps();
    this.setState({ queryModalProps: queryModalProps });
  }

  showConfirmDelete() {
    var queryModalProps = new QueryModalProps();
    if (!this.state.item.id) return;
    var id: number = this.state.item.id;

    queryModalProps.show = true;
    queryModalProps.title =
      "Really Delete This Product Line and all its children?";
    queryModalProps.message = "Are you sure? This action cannot be undone!";
    queryModalProps.showTextbox = false;
    queryModalProps.showCancelButton = true;
    queryModalProps.onClose = () => this.closeQueryModal();
    queryModalProps.onOkClicked = (val: string) => this.deleteItem(id);

    this.setState({ queryModalProps: queryModalProps });
  }

  private saveItem() {
    if (this.state.item.id) this.updateItem();
    else this.createItem();
  }

  private createItem() {
    LineData.create(this.state.item).then(() => this.close());
  }

  private updateItem() {
    LineData.update(this.state.item).then(() => this.close());
  }

  private deleteItem(id: number) {
    this.closeQueryModal();

    LineData.delete(id).then(() => this.close());
  }

  private close() {
    this.props.close();
    this.props.refresh();
    this.setState({ show: false });
  }
}
