import { IAccount } from "../models/Account";
import { IProduct } from "../models/Product";

export class SortHelper {

    public static sortAccount(a1: IAccount, a2: IAccount) : number {
        if (a1.account > a2.account) return 1;
        if (a1.account < a2.account) return -1;
        return 0;
    }

    public static sortProduct(p1: IProduct, p2: IProduct) : number {
        if (p1.code > p2.code) return 1;
        if (p1.code < p2.code) return -1;
        return 0;
    }

}
