import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IFilterOptions {
    limit? :number;
    offset?: number;
    order?: [string, 'ASC' | 'DESC'][];
}

export class DataLayer {
    private static instance: DataLayer;
    private axiosInstance: AxiosInstance;

    private constructor() {
        var apiUri = process.env.REACT_APP_API_URI || 'http://localhost:8080';

        const options: AxiosRequestConfig = {
            baseURL: apiUri,
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        }

        this.axiosInstance = Axios.create(options);
    }

    public static axiosInstance(): AxiosInstance {
        if (!DataLayer.instance) {
            DataLayer.instance = new DataLayer();
        }

        return DataLayer.instance.axiosInstance;
    }

    public static get<T>(url: string, body?: any): Promise<AxiosResponse<T>> {
        return DataLayer.axiosInstance()
            .get(url, { withCredentials: true })
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static async post(url: string, data?: any, options: AxiosRequestConfig = { withCredentials: true }): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .post(url, data, options)
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static put(url: string, data?: any): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .put(url, data, {})
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }

    public static delete(url: string): Promise<AxiosResponse<any>> {
        return DataLayer.axiosInstance()
            .delete(url)
            .catch((error) => {
                return error.response as AxiosResponse;
            });
    }
}