import { DefaultButton } from "@fluentui/react";
import React, { FormEvent } from "react";
import { Report } from "../../../data/models/Report";
import styles from '../../../scss/components/Form.module.scss';
import { buttonStyles } from "../../../scss/ControlStyles";
import { IFormProps } from "../IFormProps";

export class BaseForm extends React.Component<IFormProps, {}> {

    render() {
        return (
            <form>
                {this.props.children}

                <div className={`${styles.row}`}>
                    <div className={`${styles.col} ${styles.sm12} ${styles.right}`}>
                        <DefaultButton
                            text="Submit"
                            styles={buttonStyles}
                            onClick={this.createReport.bind(this)}
                        />
                    </div>
                </div>
            </form>
        )
    }

    private createReport() {

        var errors: string[] = [];
        var report = new Report();
        var isValid = !!this.props.line;

        this.props.createReport(report, isValid, errors);
    }
}
