import React from "react";
import styles from '../../../scss/Table.module.scss';

import { AdminNav } from "../../../components/admin/adminNav/AdminNav";
import { Authenticator } from "../../../components/authenticator/Authenticator";
import { AxiosResponse } from "axios";
import { Icon } from "@fluentui/react";
import { HeaderRow } from "../../../components/admin/rows/headerRow/HeaderRow";
import { QueryModal } from "../../../components/queryModal/QueryModal";
import { QueryModalProps } from "../../../components/queryModal/QueryModalProps";
import { IAccount } from "../../../data/models/Account";
import { AccountData } from "../../../data/AccountData";
import { IUserAccount, UserAccount } from "../../../data/models/UserAccount";
import { IUser } from "../../../data/models/User";
import { AdminData } from "../../../data/AdminData";
import { UserAccountRow } from "../../../components/admin/rows/userAccountRow/UserAccountRow";
import { UserAccountData } from "../../../data/UserAccountData";
import { EditUserAccount } from "../../../components/admin/editors/editUserAccount/EditUserAccount";
import { IPageInfo } from "../../../components/admin/paginator/IPageProps";
import { ExportSummary } from "../../../components/admin/paginator/TableSummary";
import { Paginator } from "../../../components/admin/paginator/Paginator";
import { IColumnData } from "../IAdminPageState";
import { Exporter } from "../../../components/exporter/Exporter";

interface IState {
    items: IUserAccount[];
    edititem: IUserAccount;
    showEdit: boolean;
    columns: IColumnData[];
    queryModalProps: QueryModalProps;
    accounts: IAccount[];
    users: IUser[];
    pageInfo: IPageInfo;
}

export class UserAccounts extends React.Component<{}, IState> {

    constructor(props: {}) {
        super(props);

        this.state = {
            items: [],
            edititem: new UserAccount(),
            showEdit: false,
            queryModalProps: new QueryModalProps(),
            accounts: [],
            users: [],
            pageInfo: {
                page: 1,
                count: 25,
                total: 0,
                sortCol: 0,
                sortDir: 'ASC',
            },
            columns: [
                { title: 'Username', column: 'User.username' },
                { title: 'Name', column: 'User.firstname' },
                { title: 'Account', column: 'Account.account' },
                { title: 'Account Name', column: 'Account.name' },
            ],
        }
    }

    render() {
        return (
            <Authenticator redirectIfLoggedOut adminOnly onAuthenticated={this.loadData.bind(this)}>
                <AdminNav>
                    <div>
                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm10}`}>
                                <h1>
                                    User Accounts
                                </h1>
                            </div>
                            <div className={`${styles.col} ${styles.sm2} ${styles.right}`}>
                                <span className={`${styles.link} ${styles.createNew}`} onClick={this.createItem.bind(this)}>
                                    <Icon iconName="CircleAddition" />
                                </span>
                            </div>
                        </div>

                        <ExportSummary
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <div className={`${styles.row}`}>
                            <div className={`${styles.col} ${styles.sm12}`}>
                                <div className={`${styles.table}`}>
                                    <HeaderRow
                                        columns={this.state.columns}
                                        pageInfo={this.state.pageInfo}
                                        onPageChanged={this.onPageChanged.bind(this)} />

                                    {this.renderItems(this.state.items)}
                                </div>
                            </div>
                        </div>

                        <Paginator
                            pageInfo={this.state.pageInfo}
                            onPageChanged={this.onPageChanged.bind(this)} />

                        <Exporter type='useraccounts' />
                    </div>

                    <EditUserAccount
                        show={this.state.showEdit}
                        users={this.state.users}
                        accounts={this.state.accounts}
                        close={this.closeEdit.bind(this)}
                        refresh={this.loadItems.bind(this, this.state.pageInfo)} />

                    <QueryModal {...this.state.queryModalProps} />

                </AdminNav>
            </Authenticator>
        );
    }

    loadData() {
        this.loadItems(this.state.pageInfo);
    }

    loadItems(pageInfo: IPageInfo) {
        UserAccountData.retrievePage(pageInfo, this.state.columns)
            .then((response: AxiosResponse<any>) => {
                if (response.status === 200) {

                    var pageInfo = this.state.pageInfo;
                    pageInfo.total = response.data.count;

                    this.setState({
                        pageInfo: pageInfo,
                        items: response.data.rows,
                    });

                    this.loadAccounts();
                }
                else {
                    console.log('error getting account products');
                    console.log(response.data);
                }
            });
    }

    loadAccounts() {
        AccountData.retrieve()
            .then((response: AxiosResponse<IAccount[]>) => {
                if (response.status === 200) {
                    this.setState({
                        accounts: response.data,
                    });

                    this.loadUsers();
                }
                else {
                    console.log('error getting accounts');
                    console.log(response.data);
                }
            });
    }

    loadUsers() {
        AdminData.getUsers()
            .then((response: AxiosResponse<IUser[]>) => {
                if (response.status === 200) {
                    this.setState({
                        users: response.data,
                    });
                }
                else {
                    console.log('error getting products');
                    console.log(response.data);
                }
            });
    }

    renderItems(items: IUserAccount[]) {
        return items.map((item, i) => {
            return (
                <UserAccountRow
                    key={i}
                    index={i}
                    item={item}
                    onEdit={this.showConfirmDelete.bind(this)} />
            );
        })
    }

    createItem() {
        var newItem = new UserAccount();
        this.openEdit(newItem);
    }

    openEdit(item: IUserAccount) {
        this.setState({
            edititem: item,
            showEdit: true,
        });
    }

    closeEdit() {
        this.setState({
            showEdit: false,
        });
    }

    showConfirmDelete(item: IUserAccount) {
        var queryModalProps = new QueryModalProps();

        queryModalProps.show = true;
        queryModalProps.title = 'Really Delete this User Account?';
        queryModalProps.message = 'Are you sure? This action cannot be undone!';
        queryModalProps.showTextbox = false;
        queryModalProps.showCancelButton = true;
        queryModalProps.onClose = () => this.closeQueryModal(false);
        queryModalProps.onOkClicked = (val: string) => this.deleteItem(item);

        this.setState({ queryModalProps: queryModalProps });
    }

    private deleteItem(item: IUserAccount) {
        UserAccountData.delete(item.userId, item.accountId)
            .then(() => {
                this.closeQueryModal(true);
            });
    }

    closeQueryModal(refresh: boolean) {
        var queryModalProps = new QueryModalProps();
        this.setState({ queryModalProps: queryModalProps });

        if (refresh) {
            this.loadItems(this.state.pageInfo);
        }
    }

    private onPageChanged(info: IPageInfo) {
        this.setState({ pageInfo: info });
        this.loadItems(info);
    }
}