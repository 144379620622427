import { AxiosResponse } from "axios";
import { IPageInfo } from "../components/admin/paginator/IPageProps";
import { IColumnData } from "../pages/admin/IAdminPageState";
import { DataLayer } from "./DataLayer";
import { IProblemActivity } from "./models/ProblemActivity";

export class ProblemActivityData {

    public static async create(item: IProblemActivity): Promise<AxiosResponse<any>> {
        var result = await DataLayer.post('/admin/problemactivities', item);
        return result;
    }

    public static async retrieve(): Promise<AxiosResponse<IProblemActivity[]>> {
        var result = await DataLayer.get<IProblemActivity[]>('/admin/problemactivities');
        return result;
    }

    public static async retrievePage(pageInfo: IPageInfo, columns: IColumnData[]): Promise<AxiosResponse<any>> {
        var options = {
            limit: pageInfo.count,
            offset: (pageInfo.page -1) * pageInfo.count,
            order: [
                [columns[pageInfo.sortCol].column, pageInfo.sortDir]
            ]
        };
        
        var result = await DataLayer.post('/admin/problemactivities/filter', options);
        return result;
    }

    public static async update(item: IProblemActivity): Promise<AxiosResponse<any>> {
        var result = await DataLayer.put('/admin/problemactivities', item);
        return result;
    }

    public static async delete(id: number): Promise<AxiosResponse<any>> {
        var result = await DataLayer.delete(`/admin/problemactivities/${id}`);
        return result;
    }
}
