import { Dropdown, IDropdownOption, TextField } from "@fluentui/react";
import { dropdownStyles, textBoxStyles } from "../../scss/ControlStyles";
import { ISelectorProps } from "./ISelectorProps";

export function Selector(props: ISelectorProps) {

    const optionChanged = (ev: React.FormEvent<HTMLDivElement>, option: IDropdownOption | undefined) => {
        if (!option || !option.index) return;
        props.onSelected(option);
    }

    const renderSingleOption = (option: IDropdownOption) => {
        return (
            <TextField
                styles={textBoxStyles}
                value={option.text}
                disabled />
        );
    }

    const renderMultipleOptions = (options: IDropdownOption[]) => {
        return (
            <Dropdown
                options={options}
                placeholder={props.placeholder}
                styles={dropdownStyles}
                selectedKey={props.selectedOption?.key}
                onChange={optionChanged}
                errorMessage={props.errorMessage}
            />
        );
    }

    const renderNoOptionsFound = () => {
        return (
            <TextField
                styles={textBoxStyles}
                value={'No options found'}
                disabled />
        );
    }

    if (props.options && props.options.length === 1) {
        return renderSingleOption(props.options[0]);
    }

    else if (props.options && props.options.length > 1) {
        return renderMultipleOptions(props.options);
    }

    else {
        return renderNoOptionsFound();
    }
}
