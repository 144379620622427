import { IProductLine } from "../models/ProductLine";
import { IReport } from "../models/Report";
import { IValidateResult } from "./validateResult";

export class FilterFormValidator {

    private report: IReport;
    private errors: string[];
    private line?: IProductLine;

    constructor(report: IReport, errors: string[], line?: IProductLine) {
        this.line = line;
        this.errors = errors;
        this.report = report;
    }

    validateFilterTemperature(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.filterTemperature) {

            this.errors.push('How were the filters processed?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateFilterCentrifuge(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.filterCentrifuge) {

            this.errors.push('Which centrifuge cup/liner was used?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateFiltrationTime(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.filtrationTime) {

            this.errors.push('How long was the filtration time?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateBloodAmount(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.bloodAmount) {

            this.errors.push('How much blood, relatively speaking, was left in the primary bag at the time of incident?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateFilterFlatFilled(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.filterFlatFilled) {

            this.errors.push('Was the filter inlet side flat or filled at the time of the incident?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }

    validateWbcValue(state: (err: boolean) => any): IValidateResult {
        var result: boolean = true;

        if (this.line?.showFilterIssueDetails &&
            !this.report.filterNotRelated &&
            !this.report.wbcValue) {

            this.errors.push('If WBC failure, what was the actual value?');
            result = false;
        }

        return {
            state: state(!result),
            isValid: result,
        };
    }
}
